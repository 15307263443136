import ReactApexChart from "react-apexcharts";
import React, { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import { theme } from "../../theme/theme";
import { KPI_Progress } from "../../hooks/campaign/getCampaignKPIs";

export const ProgressCircleWithChildren: FC<
  PropsWithChildren<ProgressCircleParams>
> = (props) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ProgressCircle {...props} />
      <Box
        sx={{
          position: "absolute",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export interface ProgressCircleParams {
  progress: KPI_Progress;
  value?: string;
  half?: boolean;
}

const fontSize = 25;
export const ProgressCircle = (props: ProgressCircleParams) => {
  let height = 190;
  if (props.half) {
    height = 100;
    const valueLength = props.value?.length ?? 0;
    for (let i = 0; i < valueLength; i++) {
      height += fontSize;
    }
  }
  return (
    <Box
      height={props.half ? height / 2 : undefined}
      display={"flex"}
      justifyContent={"center"}
    >
      <ReactApexChart
        series={[props.progress.value * 100]}
        type={"radialBar"}
        height={height}
        options={{
          stroke: {
            lineCap: "round",
          },
          colors: [props.progress.color],
          chart: {
            type: "radialBar",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            radialBar: {
              track: {
                background:
                  props.progress.backgroundColor ?? theme.palette.grey.A200,
              },
              startAngle: props.half ? -90 : 0,
              endAngle: props.half ? 90 : 360,
              dataLabels: {
                show: props.value != null,
                total: {
                  show: true,
                  label: props.value ?? "-",
                  fontSize: `${fontSize}px`,
                },
                value: {
                  show: false,
                },
              },
              hollow: {
                margin: 0,
                size: "60%",
              },
            },
          },
        }}
      />
    </Box>
  );
};
