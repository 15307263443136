import { GsDetailContent } from "components/campaign_insights/answer_insights/GsDetailContent";
import { useUser } from "hooks/useUser";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Page from "../../components/Page";
import { useGetInsightHukGsDetailsQuery } from "../../generated/graphql";

export default function GsDetailPage() {
  const { id } = useParams<"id">();
  const { hasAdvancedAccessToCampaigns, canSeeInternalInsights, isAdmin } =
    useUser();
  const [isPageScrollable, setIsPageScrollable] = useState<boolean>(true);
  const { data, loading, error, refetch } = useGetInsightHukGsDetailsQuery({
    variables: {
      id: id!,
      hasAdvancedAccessToCampaigns: hasAdvancedAccessToCampaigns,
      canSeeInternalInsights: canSeeInternalInsights,
      hasAdvancedAccessToCampaignsOrCanSeeInternalInsights:
        hasAdvancedAccessToCampaigns || canSeeInternalInsights,
      isAdmin: isAdmin,
    },
  });
  const gs = data?.campaign_by_pk;

  return (
    <Page
      backButtonTo="/huk/gs"
      paddingBottom={1}
      scrollable={isPageScrollable}
      showBackButton
      title={gs?.name}
    >
      {loading ? (
        <Loading />
      ) : (
        <GsDetailContent
          error={error}
          gs={gs}
          onRefetch={() => {
            refetch();
          }}
          setIsPageScrollable={setIsPageScrollable}
        />
      )}
    </Page>
  );
}
