import type React from "react";
import { Drawer } from "@mui/material";
import { ContactPopupContent } from "./ContactPopupContent";
import type { SharedUser } from "../contactBoardUtils";
import type { ContactBoardEntryFragment } from "generated/graphql";
import type { CustomDragProps } from "../BoardColumn";

export interface ContactPopupProps {
  readonly contactEntry: ContactBoardEntryFragment | null | undefined;
  readonly isOpen: boolean;
  readonly isDeclined: boolean;
  readonly onClose: () => void;
  readonly boardUsers: SharedUser[] | null;
  readonly refetchBoardUsers: () => void;
  readonly customDragProps: CustomDragProps;
  readonly columnName: string;
  readonly showOriginCampaign?: boolean;
}

export function ContactPopup({
  isOpen,
  onClose,
  boardUsers,
  refetchBoardUsers,
  contactEntry,
  isDeclined,
  customDragProps,
  columnName,
  showOriginCampaign = false,
}: ContactPopupProps) {
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            sm: "55%",
            md: "45%",
            lg: "40%",
            xl: "35%",
          },
          maxWidth: "40rem",
          alignItems: "center",
        },
      }}
      anchor="right"
      onClose={onClose}
      open={isOpen}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
    >
      <ContactPopupContent
        boardUsers={boardUsers}
        columnName={columnName}
        contactEntry={contactEntry}
        customDragProps={customDragProps}
        isDeclined={isDeclined}
        isOpen={isOpen}
        onClose={onClose}
        refetchBoardUsers={refetchBoardUsers}
        showOriginCampaign={showOriginCampaign}
      />
    </Drawer>
  );
}
