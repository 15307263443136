import { InputAdornment, TextField } from "@mui/material";
import Iconify from "components/Iconify";
import { useSearch } from "./SearchContext";

export function CampaignSearchField() {
  const { search, updateSearchQuery } = useSearch();
  const handleClearSearch = () => {
    updateSearchQuery("");
  };

  return (
    <TextField
      InputProps={{
        endAdornment: search != null && search != "" && (
          <InputAdornment position="end">
            <Iconify
              color="black"
              icon="heroicons-solid:x"
              onClick={handleClearSearch}
              style={{ cursor: "pointer" }}
            />
          </InputAdornment>
        ),
      }}
      id="outlined-basic"
      label="Suchen"
      onChange={(e) => {
        updateSearchQuery(e.target.value);
      }}
      size="small"
      sx={{
        maxWidth: "35ch",
        flexGrow: 1,
      }}
      value={search ?? ""}
      variant="outlined"
    />
  );
}
