import { Stack } from "@mui/material";
import { CampaignFilterAndSortMenu } from "./CampaignFilterAndSortMenu";
import { CampaignSearchField } from "./CampaignSearchField";

export function CampaignFilterTopBar({
  showFilter = true,
}: {
  readonly showFilter?: boolean;
}) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={1}
      justifyContent="space-between"
      width="100%"
    >
      <CampaignSearchField />
      {showFilter ? <CampaignFilterAndSortMenu /> : null}
    </Stack>
  );
}
