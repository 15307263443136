import { LightTooltip } from "../../LigthTooltip";
import { Box } from "@mui/material";
import ExternalToolLink from "../campaign_card/ExternalToolLink";
import PerspectiveLogo from "assets/logos/perspective.png";

export function EditPerspectiveFunnel({
  perspectiveCampaignId,
}: {
  readonly perspectiveCampaignId: string;
}) {
  return (
    <LightTooltip title="Funnel bearbeiten">
      <Box>
        <ExternalToolLink
          url={`https://app.perspective.co/funnel/${perspectiveCampaignId}`}
        >
          <img alt="Zu Perspective" className="h-full" src={PerspectiveLogo} />
        </ExternalToolLink>
      </Box>
    </LightTooltip>
  );
}
