import React from "react";

const Toggle: React.FC<{
  showOn: boolean;
  on: React.ReactElement;
  off: React.ReactElement;
}> = ({ showOn, on, off }) => {
  if (showOn) {
    return on;
  }
  return off;
};

export default Toggle;
