import { LightTooltip } from "../../LigthTooltip";
import { Box, Typography } from "@mui/material";
import Label from "../../Label";
import Iconify from "../../Iconify";
import React from "react";
import { useSnackbar } from "notistack";

export function CopyPerspectiveIdAction({
  perspectiveCampaignId,
}: {
  readonly perspectiveCampaignId: string;
}) {
  const { enqueueSnackbar } = useSnackbar();

  function handlePerspectiveIdCopyClicked() {
    navigator.clipboard
      .writeText(perspectiveCampaignId ?? "Perspective CampaignId not found")
      .then((response) =>
        enqueueSnackbar("Perspective ID in Zwischenablage kopiert!", {
          variant: "success",
        }),
      )
      .catch((error) =>
        enqueueSnackbar(
          `Perspective ID konnte nicht in Zwischenablage kopiert werden: ${
            error?.message ?? "Unbekannter Fehler"
          }`,
          {
            variant: "error",
          },
        ),
      );
  }

  return (
    <LightTooltip title="Perspective ID in Zwischenablage kopieren">
      <Box>
        <Label
          onClick={handlePerspectiveIdCopyClicked}
          size="small"
          sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
        >
          <Iconify icon="heroicons:clipboard-solid" sx={{ mr: 1 }} />
          <Typography variant="caption">Perspective ID</Typography>
        </Label>
      </Box>
    </LightTooltip>
  );
}
