import React, { CSSProperties, PropsWithChildren } from "react";
import { Stack, SxProps } from "@mui/material";
import Box from "@mui/material/Box";

export const iconStyle: CSSProperties = {
  height: "2rem",
};

export const smallIconStyle: CSSProperties = {
  height: "1rem",
};

export const LeadingIcon: React.FC<
  PropsWithChildren<{
    icon: React.ReactNode;
    sx?: SxProps;
  }>
> = ({ children, icon, sx }) => {
  return (
    <Stack direction={"row"} sx={sx}>
      {icon}
      <Box sx={{ ml: 1 }} />
      {children}
    </Stack>
  );
};
