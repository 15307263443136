import { createTheme } from "@mui/material";
import shadows, { customShadows } from "./shadows";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import ComponentsOverrides from "./overrides";
import { deDE as coreDeDE } from "@mui/material/locale";
import { deDE } from "@mui/x-date-pickers/locales";
export const theme = createTheme(
  {
    palette: palette.light,
    typography,
    breakpoints,
    shape: { borderRadius: 8 },
    shadows: shadows.light,
    customShadows: customShadows.light,
  },
  deDE,
  coreDeDE,
);

theme.components = ComponentsOverrides(theme);
