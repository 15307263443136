import { AnswerFragment } from "../../../../../../generated/graphql";
import { Card, Stack, styled, Typography } from "@mui/material";
import { CB_BLUE_10, CB_GREEN, CB_ORANGE, CB_RED } from "../../../../colors";
import Box from "@mui/material/Box";
import Iconify from "../../../../../Iconify";

const DialogCardIcon = styled(Iconify)(({ theme }) => ({
  width: "24px",
  height: "24px",
  padding: 0,
}));

const KNOCKOUT_QUESTION_BORDER = {
  borderLeft: "solid",
  borderWidth: 10,
  borderColor: CB_ORANGE,
};

export function QuestionAnswerDialogCard({
  answer,
}: {
  answer: AnswerFragment;
}) {
  const borderRadius = 1;
  const px = 2;
  const py = 1;
  const fontSize = "14px";

  const questionOption = findMatchingAnswerOption(answer);
  const answeredWithKnockoutAnswer = !!questionOption?.isKnockout;
  const isAnsweringKnockoutQuestion = isKnockoutQuestion(answer);

  return (
    <Card sx={isAnsweringKnockoutQuestion ? KNOCKOUT_QUESTION_BORDER : {}}>
      <Stack
        sx={{
          backgroundColor: CB_BLUE_10,
          borderRadius: borderRadius,
        }}
      >
        <Box
          sx={{
            px: px,
            py: py,
          }}
        >
          <Typography fontSize={fontSize}>{answer.question?.title}</Typography>
        </Box>

        <Box
          sx={{
            borderRadius: borderRadius,
            backgroundColor: CB_BLUE_10,
            px: px,
            py: py,
          }}
        >
          <Stack direction={"row"} sx={{ alignItems: "center" }}>
            <Typography
              fontSize={fontSize}
              fontWeight={"bolder"}
              sx={{ mr: 1 }}
            >
              {formatAnswer(answer.value ?? undefined)}
            </Typography>

            {isAnsweringKnockoutQuestion && (
              <DialogCardIcon
                icon={
                  answeredWithKnockoutAnswer
                    ? "heroicons:x-circle-20-solid"
                    : "heroicons:check-circle-20-solid"
                }
                color={answeredWithKnockoutAnswer ? CB_RED : CB_GREEN}
              />
            )}
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
}

/**
 * NOTE: this will fail for perspective questions if the answer option title is changed
 *
 * This is known and was discussed with @MatthiasWeirich on 2023-10-19
 */
function findMatchingAnswerOption(answer: AnswerFragment) {
  const questionOption = answer.question?.options?.find(
    (option) =>
      option.title.toLowerCase() === answer.value?.toLowerCase() ||
      option.title.toLowerCase() === answer.value?.toLowerCase(),
  );

  return questionOption;
}

function isKnockoutQuestion(answer: AnswerFragment): boolean {
  for (const option of answer.question?.options ?? []) {
    if (option.isKnockout) {
      return true;
    }
  }

  return false;
}

function formatAnswer(answer?: string) {
  if (answer == null) return "Keine Antwort";
  const split = answer.split(";");
  const trimmed = split.map((s) => s.trim());
  return trimmed.join(", ");
}
