import { Stack } from "@mui/material";
import React from "react";
import { FilterButton } from "./components/filter/FilterButton";
import { SortButton } from "./components/sort/SortButton";
import { SortDirectionButton } from "./components/sort/SortDirectionButton";
import { FilterDrawerContent } from "./components/filter/FilterDrawerContent";
import { campaignFilterSections } from "./components/filter/filter-configuration";

export function CampaignFilterAndSortMenu() {
  return (
    <Stack direction="row" gap={1}>
      <FilterButton
        content={(props) => (
          <FilterDrawerContent
            handleClose={props.toggleDrawer}
            sections={campaignFilterSections}
          />
        )}
      />

      <Stack direction="row" gap={0.5}>
        <SortButton />
        <SortDirectionButton />
      </Stack>
    </Stack>
  );
}
