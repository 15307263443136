import { useSearchParams } from "react-router-dom";

export default function useSearchParamsState<
  DefaultValue extends string | undefined,
>(
  searchParamName: string,
  defaultValue?: DefaultValue,
): readonly [
  searchParamsState: DefaultValue,
  setSearchParamsState: (newState: DefaultValue) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = (acquiredSearchParam ??
    defaultValue) as DefaultValue;

  const setSearchParamsState = (newState: DefaultValue) => {
    if (newState == null) {
      setSearchParams((searchParams) => {
        searchParams.delete(searchParamName);
        return searchParams;
      });
      return;
    }
    if (newState === searchParamsState) return;
    setSearchParams((searchParams) => {
      searchParams.set(searchParamName, newState);
      return searchParams;
    });
  };
  return [searchParamsState, setSearchParamsState];
}
