import { PropsWithChildren, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useLogto } from "@logto/react";
import { useNavigate } from "react-router-dom";

const LOGOUT_TIMEOUT_IN_MINUTES = 30;

const LOGOUT_TIMEOUT_IN_MILLISECONDS = 1000 * 60 * LOGOUT_TIMEOUT_IN_MINUTES;

export function LogoutIdleTimer(props: PropsWithChildren<any>) {
  const { signOut } = useLogto();

  const navigate = useNavigate();

  async function onIdle() {
    console.log("Signing out due to inactivity...");
    await signOut(`${window.location.origin}/`);
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 1000);
  }

  const { reset: resetTimer } = useIdleTimer({
    onIdle,
    timeout: LOGOUT_TIMEOUT_IN_MILLISECONDS,
  });

  useEffect(() => {
    resetTimer();
  }, [signOut]);

  return props.children;
}
