declare global {
  interface Window {
    hsConversationsSettings: {
      loadImmediately: boolean;
      enableWidgetCookieBanner: boolean;
      disableAttachment: boolean;
    };
    hsConversationsOnReady: (() => void)[];
    HubSpotConversations: {
      widget: {
        load: () => void;
        close: () => void;
      };
    };
  }
}

window.hsConversationsSettings = {
  loadImmediately: true,
  enableWidgetCookieBanner: false,
  disableAttachment: true,
};

window.hsConversationsOnReady = [
  () => {
    // if (process.env.REACT_APP_ENVIRONMENT === "production"){
    //   window.HubSpotConversations.widget.load();
    // }
  },
];

export {};
