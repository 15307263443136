import { Avatar } from "@mui/material";
import Iconify from "components/Iconify";
import type { Ad_Platform_Enum } from "generated/graphql";

const PLATFORM_ICONS: Record<
  Ad_Platform_Enum,
  { icon: string; background: string }
> = {
  LINKEDIN: { icon: "fa6-brands:linkedin-in", background: "#0077B5" },
  REDDIT: { icon: "fa6-brands:reddit-alien", background: "#FF5700" },
  SPOTIFY: { icon: "fa6-brands:spotify", background: "#1DB954" },
  FACEBOOK: { icon: "fa6-brands:facebook-f", background: "#0668E1" },
  INSTAGRAM: {
    icon: "fa6-brands:instagram",
    background:
      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);",
  },
  GOOGLE: { icon: "logos:google-ads", background: "" },
  GOOGLE_COLLECTION: { icon: "logos:google-icon", background: "" },
  YOUTUBE: { icon: "fa6-brands:youtube", background: "#FF0000" },
  TIKTOK: { icon: "fa6-brands:tiktok", background: "#000000" },
  SNAPCHAT: { icon: "fa-brands:snapchat-ghost", background: "#FFFC00" },
  META: { icon: "bi:meta", background: "#3480d9" },
  PINTEREST: { icon: "fa-brands:pinterest-p", background: "#e40319" },
};

export function AdPlatformAvatar({
  platform,
  size = 32,
}: {
  readonly platform: Ad_Platform_Enum | null;
  readonly size?: number;
}) {
  if (platform == null) {
    return <></>;
  }
  const icon = PLATFORM_ICONS[platform];

  return (
    <Avatar
      color="#fff"
      sx={{
        width: size,
        height: size,
        background: icon.background,
      }}
    >
      <Iconify color="#fff" icon={icon.icon} />
    </Avatar>
  );
}
