import { RegionFragment } from "../generated/graphql";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export function RegionDropdown(props: {
  options: RegionFragment[];
  onChange: (value: RegionFragment | null | RegionFragment[]) => void;
  value: RegionFragment | null | RegionFragment[];
  label?: string;
  multiple?: boolean;
}) {
  return (
    <Autocomplete
      id="region-select"
      sx={{ width: 300 }}
      options={props.options}
      autoHighlight
      multiple={props.multiple ?? false}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li key={option.slug} {...props}>
          <span>{option.name}</span>
        </li>
      )}
      onChange={(event, value) => {
        props.onChange(value);
      }}
      value={props.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label ?? "Auf eine Region beschränken"}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
