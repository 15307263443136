import { replace } from "lodash";
import numeral from "numeral";

export function fCurrency(number: number | undefined): string {
  if (number == null) {
    return "-";
  }

  const numberHasDecimalPlaces = number % 1 !== 0;

  const currencyNumberFormatter = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: numberHasDecimalPlaces ? 2 : 0,
  });
  return currencyNumberFormatter.format(number);
}

export function formatNumber(totalBudget: any) {
  if (totalBudget == null) {
    console.warn(`Cannot format Number: ${totalBudget}, returning 0`);
    return 0;
  }

  try {
    const budget = Number(totalBudget);

    const numberHasMoreThanTwoDecimalPlaces =
      budget.toString().split(".")[1].length > 2;
    if (numberHasMoreThanTwoDecimalPlaces) {
      return parseFloat(budget.toFixed(2));
    }
    return budget;
  } catch (e) {
    return totalBudget.toString();
  }
}

export function fPercent(number: number, twoTrailingDigits: boolean = false) {
  return numeral(number / 100).format(twoTrailingDigits ? "0.00 %" : "0%");
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number: string | number) {
  return numeral(number).format("0.0 b");
}

export function fDaysDuration(duration: number) {
  return `${duration} Tag${duration === 1 ? "" : "e"}`;
}
