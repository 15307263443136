import { PropsWithChildren } from "react";
import { Permission } from "../contexts/UserContext";
import { useUser } from "../hooks/useUser";
import Toggle from "./Toggle";

export function PermissionToggle(
  props: PropsWithChildren<{
    permission: Permission;
    allowed: React.ReactNode;
    else: React.ReactNode;
    load?: React.ReactNode;
  }>,
) {
  const { hasPermission, user } = useUser();
  if (user == null && props.load != null) {
    return <>{props.load}</>;
  }
  return (
    <Toggle
      showOn={hasPermission(props.permission)}
      on={<>{props.allowed}</>}
      off={<>{props.else}</>}
    />
  );
}
