export function calculateBudgetLeftPercentage(
  totalBudget?: number,
  budgetLeft?: number,
) {
  if (totalBudget != null && budgetLeft != null) {
    if (totalBudget === 0) return 0;
    return budgetLeft / totalBudget;
  }
  return undefined;
}
