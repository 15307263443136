import { Grid } from "@mui/material";
import type { FC } from "react";
import React from "react";
import type { AnswerInsightsProps } from "./answerInsightsHelpers";
import { QuestionInsightsCard } from "./QuestionInsightsCard";

const AnswerInsights: FC<AnswerInsightsProps> = ({ questions }) => {
  return (
    <Grid alignItems="stretch" container justifyItems="stretch" spacing={2}>
      {questions.map((question, index) => (
        <Grid display="flex" item key={question.id + index} md={6} xs={12}>
          <QuestionInsightsCard question={question} />
        </Grid>
      ))}
    </Grid>
  );
};

export default AnswerInsights;
