import React from "react";
import Box from "@mui/material/Box";
import { Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TRANSPARENT_INSIGHTS_BLUE } from "./colors";

export interface InsightsSectionProps {
  title: string;
}

const InsightsSection: React.FC<
  React.PropsWithChildren<InsightsSectionProps>
> = ({ title, children }) => {
  const theme = useTheme();
  const childrenSplitToGroupsOfFor = (children: React.ReactNode[]) => {
    const childrenGroups: React.ReactNode[][] = [];
    let currentGroup: React.ReactNode[] = [];
    for (let i = 0; i < children.length; i++) {
      currentGroup.push(children[i]);
      if (currentGroup.length === 4) {
        childrenGroups.push(currentGroup);
        currentGroup = [];
      }
    }
    if (currentGroup.length > 0) {
      childrenGroups.push(currentGroup);
    }
    return childrenGroups;
  };

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid",
        borderColor: TRANSPARENT_INSIGHTS_BLUE,
        position: "relative",
        padding: "1rem",
        borderRadius: "0.5rem",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "-1.15rem",
          left: "1rem",
          backgroundColor: theme.palette.background.default,
          padding: "0 0.5rem",
        }}
      >
        <Typography variant={"h6"}>{title}</Typography>
      </Box>
      <Grid container spacing={2}>
        {childrenSplitToGroupsOfFor(React.Children.toArray(children)).map(
          (childrenGroup, i) => (
            <Grid item xs={12} key={i}>
              <Stack direction={"row"} spacing={2}>
                {childrenGroup}
              </Stack>
            </Grid>
          ),
        )}
      </Grid>
    </Box>
  );
};

export default InsightsSection;
