import { Button, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";

function ErrorDisplay({
  message,
  onRefetch,
}: {
  readonly message?: string;
  readonly onRefetch?: () => void;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Stack gap={3} height="100%">
        <Typography>
          {message ??
            "Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."}
        </Typography>
        {onRefetch ? (
          <Button onClick={() => onRefetch()} variant="contained">
            Erneut versuchen
          </Button>
        ) : null}
      </Stack>
    </Box>
  );
}

export default ErrorDisplay;
