import type React from "react";
import { useState } from "react";
import { getSortDisplayName } from "./getSortDisplayName";
import { Button, Menu, Stack, Typography } from "@mui/material";
import Iconify from "../../../../Iconify";
import { sortOptions } from "./sort-configuration";
import { SortItem } from "./SortItem";
import { useUser } from "../../../../../hooks/useUser";
import { useSort } from "../../SortContext";

export function SortButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { activeSort, setSort } = useSort();
  const { hasPermission, isNotCustomer, role } = useUser();

  const sortDisplayName = getSortDisplayName(activeSort);
  return (
    <>
      <Button
        aria-controls={open ? "sort-control" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
      >
        <Stack alignItems="center" direction="row">
          <Typography
            sx={{
              textTransform: "none",
            }}
          >
            Sortieren nach
          </Typography>
          <Typography
            sx={{
              mr: 0.5,
            }}
            variant="subtitle2"
          >
            {sortDisplayName === "" ? "" : `: ${sortDisplayName}`}
          </Typography>
          <Iconify
            icon={open ? "heroicons:chevron-up" : "heroicons:chevron-down"}
          />
        </Stack>
      </Button>
      <Menu
        MenuListProps={{
          "aria-labelledby": "sort-control",
        }}
        anchorEl={anchorEl}
        id="sort-menu"
        onClose={handleClose}
        open={open}
        sx={{
          p: 2,
        }}
      >
        {sortOptions
          .filter(
            (value) =>
              value.displayPredicate?.call(
                undefined,
                role,
                hasPermission,
                isNotCustomer,
              ) ?? true,
          )
          .map((sort) => (
            <SortItem
              activeSort={activeSort}
              key={sort.key}
              onClick={() => {
                setSort(sort.key);
                handleClose();
              }}
              sort={sort}
            />
          ))}
      </Menu>
    </>
  );
}
