import moment from "moment";
import type { CampaignFragment } from "../generated/graphql";

export function calculateDiffInDays(
  dateA: moment.Moment | string,
  dateB: moment.Moment | string,
  countTodayAsFullDay: boolean = true,
): number {
  const diffA = diffInDays(dateA, dateB, countTodayAsFullDay);
  const diffB = diffInDays(dateB, dateA, countTodayAsFullDay);

  return Math.max(diffA, diffB);
}

function diffInDays(
  start: moment.Moment | string,
  end: moment.Moment | string,
  countTodayAsFullDay: boolean = true,
) {
  const startDate = moment(start).startOf("day");
  const endDate = moment(end).endOf("day");

  const diffWithDecimals = endDate.diff(startDate, "days", true);
  const absoluteDiffWithDecimals = Math.abs(diffWithDecimals);

  if (!countTodayAsFullDay) {
    const diffRoundedDownToFullDays = Math.floor(absoluteDiffWithDecimals);
    return diffRoundedDownToFullDays;
  }

  const diffRoundedUpToFullDays = Math.ceil(absoluteDiffWithDecimals);
  return diffRoundedUpToFullDays;
}

export type CampaignInfo = {
  icon: string;
  color: "primary" | "secondary" | "default";
  value: any;
  tooltip?: string;
};

export function getCampaignInfo(campaign: CampaignFragment): CampaignInfo[] {
  let location = "";
  if (campaign.c_location?.parent_region?.name !== undefined) {
    location = campaign.c_location.parent_region.name + " | ";
  }
  location += campaign.c_location?.name ?? "";

  const assignedPerson: CampaignInfo[] =
    campaign.responsible_person != null && campaign.responsible_person !== ""
      ? [
          {
            icon: "heroicons:user-solid",
            value: campaign.responsible_person,
            color: "default",
            tooltip: "Die verantwortliche Person für die Kampagne",
          },
        ]
      : [];

  const shortID: CampaignInfo[] = campaign.short_id
    ? [
        {
          icon: "heroicons:check-badge",
          value: campaign.short_id ?? "",
          color: "default",
          tooltip: "Die Kurz-ID der Kampagne",
        },
      ]
    : [];

  return [
    {
      icon: "heroicons:map-pin-solid",
      value: location,
      color: "primary",
    },
    {
      icon: "heroicons:building-office-solid",
      value: toTitleCase(campaign.work_location),
      color: "secondary",
    },
    ...shortID,
    ...assignedPerson,
  ];
}

function toTitleCase(s?: string | null) {
  if (s == null) return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}
