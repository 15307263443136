import { NoteFragment } from "../../../../../../generated/graphql";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { CB_GRAY } from "../../../../colors";
import { LeadingIcon } from "../../../../LeadingIcon";
import { UserProfileImage } from "../../../../shared/UserProfileImage";
import { formatDate } from "../../../../shared/utils";
import Box from "@mui/material/Box";
import React from "react";
import { SharedUser } from "../../../../contactBoardUtils";

export function NoteCard({
  note,
  boardUsers,
}: {
  note: NoteFragment;
  boardUsers: SharedUser[] | null;
}) {
  const author: SharedUser | undefined = boardUsers?.find(
    (user) => user.id === note.author_id,
  );

  const authorName = author?.name ?? note.author_id;

  const { firstName, lastName } = getFirstAndLastName(
    authorName,
    note.author_id,
  );

  return (
    <Box>
      <Card
        sx={{
          width: "100%",
          backgroundColor: CB_GRAY,
        }}
      >
        <CardContent>
          <LeadingIcon
            icon={
              <UserProfileImage firstName={firstName} lastName={lastName} />
            }
          >
            <Stack>
              <Typography fontWeight="bold" variant={"body2"}>
                {`${firstName} ${lastName}`}
              </Typography>
              <Typography variant={"caption"} color={"text.secondary"}>
                {formatDate(note.created_at)}
              </Typography>
              <Box mt={1}>
                <Typography variant={"body2"} fontSize={"1em"}>
                  {note.content}
                </Typography>
              </Box>
            </Stack>
          </LeadingIcon>
        </CardContent>
      </Card>
    </Box>
  );
}

function getFirstAndLastName(fullName: string | undefined, userId: string) {
  let firstName;
  let lastName;

  if (!fullName) {
    firstName = userId[0];
    lastName = userId[1];
    return { firstName, lastName };
  }

  const SEPARATOR = " ";
  const names = fullName.split(SEPARATOR);
  firstName = names[0];
  lastName = "";
  if (names.length === 1) {
    return { firstName, lastName };
  }

  lastName = names.slice(1).join(SEPARATOR);
  return { firstName, lastName };
}
