import { Button, Stack, Typography } from "@mui/material";
import { useSort } from "../../SortContext";
import React from "react";
import Iconify from "../../../../Iconify";
import type { SortDirection } from "./sort-configuration";

export function SortDirectionButton() {
  const { sortDirection, setSortDirection } = useSort();
  return (
    <Button
      onClick={() => {
        setSortDirection(
          sortDirection === "ascending" ? "descending" : "ascending",
        );
      }}
      variant="outlined"
    >
      <Stack alignItems="center" direction="row" gap={0.3}>
        <Typography>{getSortDirectionDisplayText(sortDirection)}</Typography>
        <Iconify
          icon={
            sortDirection === "ascending"
              ? "heroicons:arrow-up"
              : "heroicons:arrow-down"
          }
        />
      </Stack>
    </Button>
  );
}

function getSortDirectionDisplayText(sortDirection: SortDirection) {
  switch (sortDirection) {
    case "ascending":
      return "Aufsteigend";
    case "descending":
      return "Absteigend";
  }
}
