import type { HukGsDetailsFragment } from "generated/graphql";
import type { PLATFORM } from "hooks/campaign/getCampaignKPIs";
import { merge } from "lodash";
import moment from "moment/moment";
import ReactApexChart from "react-apexcharts";
import { BaseOptionChart } from "../../analytics/chart";
import { INSIGHTS_BLUE } from "../colors";
import type { ContactsPerDayChartData } from "./applicationInsightHelpers";
import { getHukGsContactsPerDayChartData } from "./applicationInsightHelpers";

export function HukContactsPerDayInsightsChart({
  gs,
  selectedTab,
}: {
  readonly gs: HukGsDetailsFragment;
  readonly selectedTab: PLATFORM;
}) {
  const unqualifiedContacts = gs.gs_campaigns
    .flatMap((c) => c.campaign?.unqualified_contacts_per_day ?? [])
    .concat(gs.unqualified_contacts_per_day ?? []);

  const qualifiedContacts = gs.gs_campaigns
    .flatMap((c) => c.campaign?.contacts_per_day ?? [])
    .concat(gs.contacts_per_day ?? []);

  const chartData: ContactsPerDayChartData = getHukGsContactsPerDayChartData(
    qualifiedContacts,
    unqualifiedContacts,
    true,
    selectedTab,
  );

  const sourceChartOptions: ApexCharts.ApexOptions = {
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    colors: [INSIGHTS_BLUE],
    chart: {
      animations: { enabled: false },
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const data = chartData[dataPointIndex];
        const date = data.x;
        const totalContacts = data.y;

        return `
        <div style=" display: flex; align-items: center; flex-direction: column">
            <div style="width: 100%; padding: 8px ; font-size: 12px; margin-bottom: 5px; background-color: #E9EDEF">
                ${moment(date).format("DD.MM.YYYY")}
            </div>
            <div style="padding: 6px 8px;display: flex; flex-direction: column; align-items: start">
            <div style="font-size: 12px; margin-bottom: 5px;">
                  <strong>${totalContacts} gesamt</strong>
              </div>
              <div style="font-size: 12px; margin-bottom: 5px">
                  <strong>${data.qualified_contacts}</strong> qualifiziert
              </div>
              <div style="font-size: 12px; margin-bottom: 5px">
                  <strong>${
                    data.new_unqualified_contacts
                  }</strong> unqualifiziert
              </div>

            </div>

        </div>
        `;
      },
    },
  };
  return (
    <ReactApexChart
      height={220}
      key={selectedTab}
      options={merge(BaseOptionChart(), sourceChartOptions)}
      series={[{ data: chartData }]}
      type="area"
    />
  );
}
