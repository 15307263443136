import { composeBackendUrl } from "hooks/useBackendRequest";
import { useEffect, useState } from "react";

const BACKEND_HEALTH_SERVICE_URL = composeBackendUrl("/health");
const HEALTH_CHECK_INTERVAL_IN_MILLISECONDS = 5 * 1000;

type HealthStatus = boolean | null;

const useBackendHealthService = (isEnabled?: boolean) => {
  const [isBackendHealthy, setIsBackendHealthy] = useState<HealthStatus>(null);

  useEffect(() => {
    if (isEnabled ?? true) {
      const checkHealth = async () => {
        try {
          const response = await fetch(BACKEND_HEALTH_SERVICE_URL);
          setIsBackendHealthy(response.ok);
        } catch (error) {
          setIsBackendHealthy(false);
        }
      };

      checkHealth();

      const healthCheckInterval = setInterval(
        checkHealth,
        HEALTH_CHECK_INTERVAL_IN_MILLISECONDS,
      );

      return () => clearInterval(healthCheckInterval);
    }
  }, []);

  return { isBackendHealthy };
};

export { useBackendHealthService };
