import type {
  SortDirection,
  SortKey,
} from "./components/sort/sort-configuration";
import {
  defaultSort,
  defaultSortDirection,
} from "./components/sort/sort-configuration";
import type { PropsWithChildren } from "react";
import React, { createContext, useEffect, useState } from "react";
import { useUser } from "../../../hooks/useUser";

export interface SortContextType {
  activeSort: SortKey;
  setSort: (sort: SortKey) => void;
  sortDirection: SortDirection;
  setSortDirection: (direction: SortDirection) => void;
}

export const SortContext = createContext<SortContextType>({
  activeSort: defaultSort,
  setSort: () => {},
  sortDirection: "descending",
  setSortDirection: () => {},
});

export function SortContextProvider({ children }: PropsWithChildren<any>) {
  const [activeSort, setActiveSort] = useState<SortKey>(defaultSort);
  const [sortDirection, setSortDirection] =
    useState<SortDirection>(defaultSortDirection);

  const { permissions } = useUser();

  useEffect(() => {
    setActiveSort(defaultSort);
    setSortDirection(defaultSortDirection);
  }, [permissions]);

  return (
    <SortContext.Provider
      value={{
        activeSort,
        setSort: (sort) => {
          setActiveSort(sort);
        },
        sortDirection,
        setSortDirection,
      }}
    >
      {children}
    </SortContext.Provider>
  );
}

export function useSort() {
  return React.useContext(SortContext);
}
