import { useGetInsightCampaignDetailsQuery } from "generated/graphql";
import { useParams } from "react-router-dom";
import { useUser } from "../useUser";

/**
 * Returns the current campaign based on the id from the URL.
 */
export default function useCurrentCampaign() {
  const { id } = useParams<"id">();
  const { hasAdvancedAccessToCampaigns, canSeeInternalInsights, isAdmin } =
    useUser();

  const { data, loading, error, refetch } = useGetInsightCampaignDetailsQuery({
    variables: {
      id: id!,
      hasAdvancedAccessToCampaigns: hasAdvancedAccessToCampaigns,
      canSeeInternalInsights: canSeeInternalInsights,
      hasAdvancedAccessToCampaignsOrCanSeeInternalInsights:
        hasAdvancedAccessToCampaigns || canSeeInternalInsights,
      isAdmin: isAdmin,
    },
  });
  return {
    campaign: data?.campaign_by_pk ?? null,
    loading,
    error,
    refetch,
  };
}
