import React, { PropsWithChildren } from "react";
import Loading from "./Loading";
import ErrorDisplay from "./ErrorDisplay";

export const DataLoadingDisplay: React.FC<
  PropsWithChildren<{
    loading: boolean;
    error: any | undefined;
    refetch: () => void;
    errorMessage?: string;
  }>
> = ({ children, loading, refetch, error, errorMessage }) => {
  if (loading) return <Loading />;
  if (error)
    return (
      <ErrorDisplay
        message={
          errorMessage ??
          "Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
        }
        onRefetch={refetch}
      />
    );
  return <>{children}</>;
};
