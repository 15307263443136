import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Iconify from "../components/Iconify";
import { Alert, Card, CardContent, Divider, List, Stack } from "@mui/material";
import NavSection from "../components/nav-section/NavSection";
import type { NavListProps } from "components/nav-section/type";
import NavbarAccount from "../components/navbar/NavbarAccount";
import ImpersonationDisplay from "../components/admin/ImpersonationDisplay";
import { useUser } from "hooks/useUser";
import { NavItemRoot } from "components/nav-section/NavItem";
import ImpersonationContext from "../contexts/ImpersonationContext";
import EverbayLogo from "../components/EverbayLogo";

const BaseGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  width: "100%",
  height: "100vh",
  maxHeight: "100vh",
  overflow: "hidden",
  gridTemplateColumns: `min-content 1fr`,
  gridTemplateRows: "min-content 1fr",
  gap: "0px 0px",
  gridTemplateAreas: `"Alert Alert"
  "Sidebar Main"    
                      "Sidebar Main";`,
  background: theme.palette.background.default,
}));

export default function AppBarLayout() {
  const navigate = useNavigate();

  const NAV_CONFIG: {
    subheader: string;
    items: NavListProps[];
    id: string;
  }[] = [
    {
      id: "main",
      subheader: "",
      items: [
        {
          featureFlag: "dashboard",
          title: "Dashboard",
          path: "/dashboard",
          icon: <Iconify icon="ic:round-dashboard" />,
        },
        {
          title: "Kampagnen",
          path: "/campaigns",
          icon: <Iconify icon="heroicons:megaphone-solid" />,
        },
        {
          featureFlag: "quotations",
          requiredPermission: "create:quotation",
          title: "Angebote",
          path: "/quotation-form",
          icon: <Iconify icon="mdi:form" />,
        },
        {
          title: "Kunden",
          path: "/customers",
          requiredPermission: "write:user",
          icon: <Iconify icon="heroicons:user-group-solid" />,
        },
        {
          requiredPermission: "write:campaign",
          title: "Bestellungen",
          path: "/campaigns/orders",
          icon: <Iconify icon="heroicons:shopping-cart-solid" />,
        },
      ],
    },
    // note: if you add a group here, you need to update the findBestMatchingTab function in NavSection.tsx
  ];

  const content = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [width, setWidth] = React.useState("300px");
  const { organization } = useContext(ImpersonationContext);
  const [impersonationChanged, setImpersonationChanged] = React.useState(false);
  const { showSensitiveDataWarning, isAdmin, isEverbayEmployee, user } =
    useUser();

  if (isAdmin || isEverbayEmployee || user?.organization === "huk") {
    NAV_CONFIG[0].items.push({
      title: "Geschäftsstellen",
      path: "/huk/gs",
      icon: <Iconify icon="heroicons:map-pin-solid" />,
    });
  }

  useEffect(() => {
    setWidth((content.current?.getBoundingClientRect().width ?? 0) + "px");
  }, [isOpen, organization, impersonationChanged]);

  const openTransition = "0.3s ease-in-out";

  // disables scrolling on sidebar
  document.body.style.overflow = "hidden";

  return (
    <BaseGrid
      sx={{
        position: "relative",
      }}
    >
      {showSensitiveDataWarning ? (
        <Alert
          severity="error"
          sx={{
            display: "flex",
            justifyContent: "center",
            gridArea: "Alert",
            fontSize: "22px",
            alignItems: "center",
          }}
        >
          ACHTUNG! INTERNE ANSICHT – NICHT MIT KUNDEN TEILEN!
        </Alert>
      ) : null}
      <Card
        sx={{
          gridArea: "Sidebar",
          m: 2,
          width: width,
          pb: 0,
          transition: openTransition,
        }}
      >
        <CardContent
          ref={content}
          sx={{
            p: 0,
            pb: "0 !important",
            width: "fit-content",
            height: "100%",
          }}
        >
          <Stack alignItems="stretch" height="100%">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => {
                  navigate("/");
                }}
                sx={{
                  width: isOpen ? "50px" : "35px",
                  mx: 2,
                  mt: 3,
                  mb: 0,
                  cursor: "pointer",
                  transition: openTransition,
                }}
              >
                <EverbayLogo />
              </Box>
            </Box>
            <Divider sx={{ mx: 2, my: 1 }} />

            <NavbarAccount isCollapsed={!isOpen} />
            <ImpersonationDisplay
              isOpen={isOpen}
              onDisplayChange={() => {
                setImpersonationChanged(!impersonationChanged);
              }}
            />
            <Box
              sx={{
                transition: openTransition,
                overflow: "hidden",
              }}
            >
              <NavSection isCollapse={!isOpen} navConfig={NAV_CONFIG} />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <List sx={{ px: 1, overflow: "hidden" }}>
              <NavItemRoot
                active={false}
                isCollapse={!isOpen}
                item={{
                  title: "Einklappen",
                  path: "",
                  icon: (
                    <Iconify
                      icon={
                        isOpen
                          ? "heroicons:chevron-double-left"
                          : "heroicons:chevron-double-right"
                      }
                    />
                  ),
                }}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            </List>
          </Stack>
        </CardContent>
      </Card>
      <Box
        sx={{
          gridArea: "Main",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarGutter: "stable",
        }}
      >
        <Outlet />
      </Box>
    </BaseGrid>
  );
}
