import { CardContent, Stack } from "@mui/material";
import type { ContactFragment } from "generated/graphql";
import { QuestionAnswerDialogCard } from "./components/QuestionAnswerDialogCard";
import { shouldDisplayQuestion } from "../../../shared/utils";

export function QuestionAnswerTab({
  contact: { answers },
}: {
  readonly contact: ContactFragment;
}) {
  const filteredAnswers = answers.filter((answer) => {
    return (
      answer.question?.title !== undefined &&
      shouldDisplayQuestion(answer.question.title)
    );
  });

  return (
    <CardContent>
      <Stack height="100%" overflow="auto" spacing={2}>
        {filteredAnswers.map((answer) => (
          <QuestionAnswerDialogCard answer={answer} key={answer.id} />
        ))}
      </Stack>
    </CardContent>
  );
}
