import Box from "@mui/material/Box";
import Iconify from "../../Iconify";
import { CB_BLUE, CB_GREEN, CB_RED, CB_TURQUOISE, CB_YELLOW } from "../colors";

const USER_PROFILE_IMAGE_BACKGROUND_COLORS = [
  CB_YELLOW,
  CB_GREEN,
  CB_RED,
  CB_BLUE,
  CB_TURQUOISE,
];

export function UserProfileImage({
  firstName,
  lastName,
  size,
  isDeclined = false,
  doNotUseColor = false,
}: {
  firstName: string;
  lastName: string;
  size?: number;
  isDeclined?: boolean;
  doNotUseColor?: boolean;
}) {
  size = size || 40;

  const backgroundColor = isDeclined
    ? CB_RED
    : calculateIconColor(firstName, lastName);

  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: doNotUseColor ? "grey.500" : backgroundColor,
        color: "white",
        fontSize: (16 * size) / 40,
        fontWeight: "bold",
      }}
    >
      {!isDeclined &&
        firstName[0]?.toUpperCase() + (lastName[0] ?? "").toUpperCase()}
      {isDeclined && (
        <Iconify
          icon={"material-symbols:close-rounded"}
          sx={{ width: size - 5, height: size - 5 }}
        />
      )}
    </Box>
  );
}

function calculateIconColor(firstName: string, lastName: string) {
  const name = firstName + lastName;
  const insecureHash = calcInsecureHashCode(name);
  const colorIndex = insecureHash % 4;

  return USER_PROFILE_IMAGE_BACKGROUND_COLORS[colorIndex];
}

/**
 * {@link https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0}
 */
function calcInsecureHashCode(str: string): number {
  let h = 0;
  for (let i = 0; i < str.length; i++)
    h = (Math.imul(31, h) + str.charCodeAt(i)) | 0;
  return Math.abs(h);
}
