import { useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
// @mui
import { Collapse, List } from "@mui/material";
//
import { NavItemRoot, NavItemSub } from "./NavItem";
import { NavListProps } from "./type";
import { useUser } from "../../hooks/useUser";

// ----------------------------------------------------------------------

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
  active: boolean;
};

export function NavListRoot({ active, list, isCollapse }: NavListRootProps) {
  const { hasPermission } = useUser();
  const [open, setOpen] = useState(active);

  const hasChildren = list.children;
  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen((open) => !open)}
          setOpen={setOpen}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || [])
                .filter(
                  (child) =>
                    child.requiredPermission == null ||
                    hasPermission(child.requiredPermission),
                )
                .map((item) => (
                  <NavListSub key={item.title} list={item} />
                ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} isCollapse={isCollapse} />;
}

const getActive = (path: string, pathname: string): boolean =>
  path ? !!matchPath({ path: path, end: false }, pathname) : false;

// ----------------------------------------------------------------------

type NavListSubProps = {
  list: NavListProps;
};

function NavListSub({ list }: NavListSubProps) {
  const { pathname } = useLocation();

  const activeRoot = getActive(list.path, pathname);
  const { hasPermission } = useUser();
  const [open, setOpen] = useState(activeRoot);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={activeRoot}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || [])
              .filter(
                (child) =>
                  child.requiredPermission == null ||
                  hasPermission(child.requiredPermission),
              )
              .map((item) => (
                <NavItemSub
                  key={item.title}
                  item={item}
                  active={getActive(item.path, pathname)}
                />
              ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={activeRoot} />;
}
