import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import ImpersonationContext from "./ImpersonationContext";
import { getExpectedRole } from "./LogtoApolloContext";

export interface User {
  organization: string;

  id: string;

  roles: UserRole[];
  scopes: Permission[];

  email: string;

  name: string;

  username: string;
  picture: string | null;

  region_slugs: string[] | null;

  location_slugs: string[] | null;

  can_only_see_additional_campaigns: boolean | null;
  additional_campaign_ids: string[] | null;
}

export type UpdateUserDto = {
  id: string;
  name?: string;
  organization: string;
  location_slugs: string[];
  region_slugs: string[];
  email?: string;
  can_only_see_additional_campaigns: boolean;
  additional_campaign_ids: string[];
};

export type CreateNewUserDto = {
  email: string;
  name: string;
  organization: string;
  password: string;
  username: string;
  location_slugs: string[];
  region_slugs: string[];
  can_only_see_additional_campaigns: boolean;
};

export type UserRole =
  | "woop_admin"
  | "creator"
  | "support"
  | "performance_marketing"
  | "sales"
  | "customer"
  | "partner";

export const USER_ROLE_PRIORITY = [
  "woop_admin",
  "creator",
  "support",
  "performance_marketing",
  "sales",
  "partner",
  "seller",
  "customer",
];

export type Permission =
  | "read:contact_board"
  | "reject:applicant"
  | "read:internal_insights"
  | "read:external_insights"
  | "edit:campaign"
  | "write:campaign"
  | "edit:organization"
  | "write:organization"
  | "write:location"
  | "delete:location"
  | "write:transaction"
  | "pause:campaign"
  | "stop:campaign"
  | "write:user"
  | "read:user"
  | "update:user"
  | "delete:user"
  | "read:campaign_budget"
  | "write:budget_increase"
  | "read:creative"
  | "write:creative"
  | "create:quotation"
  | "approve:ad"
  | "write:destinations"
  | "write:email_text"
  | "delete:applicant";

export function hasAnyOfRoles(
  expectedRoles: UserRole[],
  actualRole: UserRole | null,
) {
  return expectedRoles.some((role) => role === actualRole);
}

export interface UserContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  firstName: string | null;
  lastName: string | null;
  role: UserRole | null;
  hasAdvancedAccessToCampaigns: boolean;
  isNotCustomer: boolean;
  hasPermission: (permission: Permission) => boolean;
  permissions: Permission[];
  isAdmin: boolean;
  canSeeInternalInsights: boolean;
  showSensitiveDataWarning: boolean;
  canSeeSensibleData: boolean;
  canSeeMarginalReturn: boolean;
  canSeeImpersonation: boolean;

  isEverbayPartnerOrganization: boolean;
  isEverbayEmployee: boolean;
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => {},
  firstName: null,
  lastName: null,

  role: null,
  hasAdvancedAccessToCampaigns: false,
  isNotCustomer: false,
  hasPermission: () => false,
  permissions: [],
  isAdmin: false,
  canSeeInternalInsights: false,
  showSensitiveDataWarning: false,
  canSeeSensibleData: false,
  canSeeMarginalReturn: false,
  canSeeImpersonation: false,
  isEverbayPartnerOrganization: false,
  isEverbayEmployee: false,
});

function isUserInWarningDialogBlacklist(user: User | null) {
  if (user == null) {
    return false;
  }
  const blacklist = [
    "julian.hartl@selectcode.de",
    "florian.glombik@selectcode.de",
    "matthias.weirich@selectcode.de",
    "gustav.lund@selectcode.de",
    "simon.huang@selectcode.de",
    "pk@everbay.de",
    "bobur.khayitov@selectcode.de",
  ];
  const email = user.email;
  return blacklist.includes(email);
}

const ROLES_THAT_CAN_SEE_IMPERSONATION: UserRole[] = ["woop_admin"];
const ROLES_THAT_CAN_SEE_MARGINAL_RETURN: UserRole[] = ["woop_admin"];
const EVERBAY_EMPLOYEE_ROLES: UserRole[] = [
  "woop_admin",
  "support",
  "creator",
  "performance_marketing",
  "sales",
];

export function UserContextProvider({
  children,
}: {
  readonly children: ReactNode;
}) {
  const { organization } = useContext(ImpersonationContext);
  const [user, setUser] = useState<User | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);

  const roles: UserRole[] =
    organization == null ? user?.roles ?? [] : ["customer"];
  const permissions = user?.scopes ?? [];
  const expectedRole = user == null ? null : getExpectedRole(roles);
  const canSeeInternalInsights =
    expectedRole === "woop_admin" || expectedRole === "performance_marketing";
  const showSensitiveDataWarning =
    canSeeInternalInsights && !isUserInWarningDialogBlacklist(user);
  const isImpersonatingOrganization = organization !== null;

  const isEverbayPartnerOrganization = !!user?.roles.includes("partner");
  const isEverbayEmployee =
    expectedRole != null && EVERBAY_EMPLOYEE_ROLES.includes(expectedRole);
  useEffect(
    function updateFirstAndLastName() {
      const parts = user?.name?.split(" ", 2);

      setFirstName(parts?.[0] ?? null);
      setLastName(parts?.[1] ?? null);
    },
    [user?.name],
  );

  return (
    <UserContext.Provider
      value={{
        user:
          user == null
            ? null
            : {
                ...user,
                organization: organization ?? user.organization,
                roles: roles,
              },
        role: expectedRole,
        setUser,
        firstName,
        lastName,

        // todo: revert this to roles.includes("woop_admin") || roles.includes("creator")
        hasAdvancedAccessToCampaigns:
          expectedRole === "woop_admin" ||
          expectedRole === "creator" ||
          expectedRole === "support",
        isNotCustomer:
          expectedRole !== "customer" && expectedRole !== "partner",
        hasPermission: (permission: Permission) =>
          permissions.includes(permission),
        permissions: permissions,
        isAdmin: expectedRole === "woop_admin",
        canSeeInternalInsights: canSeeInternalInsights,
        showSensitiveDataWarning: showSensitiveDataWarning,
        canSeeSensibleData:
          canSeeInternalInsights && !isImpersonatingOrganization,
        canSeeImpersonation:
          expectedRole == null
            ? false
            : ROLES_THAT_CAN_SEE_IMPERSONATION.includes(expectedRole),
        canSeeMarginalReturn:
          expectedRole == null
            ? false
            : ROLES_THAT_CAN_SEE_MARGINAL_RETURN.includes(expectedRole),

        isEverbayPartnerOrganization: isEverbayPartnerOrganization,
        isEverbayEmployee,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
