import { useLogto } from "@logto/react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { EmailEditor } from "components/mails/EmailEditor";
import * as MailTemplates from "components/mails/mail-templates";
import { ContactBoardContext } from "contexts/ContactBoardContext";
import type {
  CampaignDetailsFragment,
  ContactFragment,
  HukGsDetailsFragment,
} from "generated/graphql";
import { BACKEND_RESOURCE, composeBackendUrl } from "hooks/useBackendRequest";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import type { DropResult } from "react-beautiful-dnd";
import { useSearchParams } from "react-router-dom";
import Loading from "../Loading";

type RejectContactDto = {
  campaignId: string;
  contactId: string;
  firstName: string;
  email: string;
  content: string;
};

// Because one <p> tag is always present in the email editor, we add 7 to the intended minimum length of 100 characters
const MIN_MAIL_LENGTH = 107;

export function ApplicantRejectionDialog({
  handleUpdateContactEntry,
  campaign,
  rejectionTemplate,
}: {
  readonly handleUpdateContactEntry: (
    result?: DropResult,
    isDeclineFromDialog?: boolean,
  ) => Promise<boolean>;
  readonly campaign: CampaignDetailsFragment | HukGsDetailsFragment;
  readonly rejectionTemplate: string;
}) {
  const campaignId = campaign.id;

  const [_, setSearchParams] = useSearchParams();

  const { contactToBeRejected, setContactToBeRejected } =
    useContext(ContactBoardContext);

  const contact = contactToBeRejected?.contactEntry?.contact;

  const [customRejectionText, setCustomRejectionText] = useState<string>(
    MailTemplates.compileTemplate(rejectionTemplate, campaign, {
      first_name: contact?.first_name,
      last_name: contact?.last_name,
    }),
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const { getAccessToken } = useLogto();

  const isValidInput = customRejectionText.length > MIN_MAIL_LENGTH;

  async function handleConfirmDeclineContact() {
    setIsSubmitting(true);

    try {
      await sendRejectionMail();
      await handleUpdateContactEntry(contactToBeRejected?.dropResult, true);
    } catch (error: unknown) {
      console.error("Could not decline contact", error);
      Sentry.captureException(error);
    }
    closeDialog();
    setIsSubmitting(false);
  }

  function closeDialog() {
    setContactToBeRejected(null);
  }

  function editTemplate() {
    setContactToBeRejected(null);
    setSearchParams({ tab: "emails", template: "1" });
  }

  async function sendRejectionMail() {
    try {
      const token = await getAccessToken(BACKEND_RESOURCE);
      const declineContactParams = getDeclinedContactParams();
      if (declineContactParams === null) {
        console.error(
          "Could not get decline contact params, decline mail not send",
        );
        return;
      }

      const response = await fetch(composeBackendUrl("/contact/reject"), {
        method: "POST",
        body: JSON.stringify(declineContactParams),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // noinspection ExceptionCaughtLocallyJS (intended to be caught locally)
        throw new Error(`${response.statusText}`);
      }

      enqueueSnackbar(
        `Absage an ${contact?.first_name} ${contact?.last_name} wurde versendet!`,
        {
          variant: "success",
        },
      );

      return response;
    } catch (error: unknown) {
      console.error("Could not decline contact", error);
      enqueueSnackbar(`Fehler beim Versenden der Absage-E-Mail: ${error}`, {
        variant: "error",
      });
      Sentry.captureException(error);
      throw error;
    }
  }

  function getDeclinedContactParams(): RejectContactDto | null {
    if (!contactToBeRejected?.contactEntry.contact) {
      return null;
    }

    const contact: ContactFragment = contactToBeRejected.contactEntry.contact;

    return {
      campaignId: campaignId,
      contactId: contact.id ?? "",
      firstName: contact.first_name ?? "",
      email: contact.email_address ?? "",
      content: customRejectionText,
    };
  }

  return (
    <Dialog
      aria-describedby="declined-contact-dialog-description"
      aria-labelledby="decline-contact-dialog-title"
      fullWidth
      maxWidth="xl"
      onClose={closeDialog}
      open={contactToBeRejected !== null}
    >
      <DialogTitle id="decline-contact-dialog-title" sx={{ minWidth: "35rem" }}>
        Absage-E-Mail an {contact?.first_name} {contact?.last_name} ({" "}
        {contact?.email_address} )
      </DialogTitle>
      <DialogContent sx={{ marginTop: 2, paddingBottom: 0 }}>
        <EmailEditor
          enabled={!isSubmitting}
          setValue={setCustomRejectionText}
          value={customRejectionText}
        />
        {isValidInput ? (
          <FormHelperText> </FormHelperText>
        ) : (
          <FormHelperText sx={{ mb: 0 }}>
            Noch {MIN_MAIL_LENGTH - customRejectionText.length} Zeichen, um die
            E-Mail absenden zu können
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button
            disabled={isSubmitting}
            onClick={editTemplate}
            sx={{ mr: "auto" }}
          >
            Vorlage bearbeiten
          </Button>
          <Button disabled={isSubmitting} onClick={closeDialog}>
            Abbrechen
          </Button>
          <Button
            color="error"
            disabled={isSubmitting || !isValidInput}
            onClick={handleConfirmDeclineContact}
            sx={{ textTransform: "none" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Absage versenden
              {isSubmitting ? <Loading sx={{ pl: 1, p: 0 }} /> : null}
            </Box>
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
