import { useContext, useEffect, useState } from "react";
import { useLogto } from "@logto/react";
import { merge } from "lodash";
import ImpersonationContext from "../contexts/ImpersonationContext";

export interface BaseState<SerializedData> {
  data: SerializedData | null;
  loading: boolean;
  error: any;
}

export interface State<SerializedData> extends BaseState<SerializedData> {
  refetch: () => Promise<void>;
}

export interface LazyState<SerializedData> extends BaseState<SerializedData> {
  request: SerializedRequestHandler<SerializedData>;
}

export type SerializedRequestHandler<SerializedData> = (
  request?: SerializedRequest<any>,
) => Promise<SerializedResponse<SerializedData>>;

export interface SerializedRequest<Body> {
  path?: string;
  body?: Body;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: Record<string, string>;
}

export interface SerializedResponse<SerializedData> {
  data?: SerializedData;
  error?: Error;
}

export class RequestError extends Error {
  constructor(
    public readonly status: number,
    public readonly statusText: string,
    public readonly data: any,
  ) {
    super(`${status}: ${statusText}`);
  }
}

export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL!;

/** Logto resource URL of the backend -
 * this is not the same as the URL where the backend is hosted, but rather the identifier configured for the resource in Logto */
export const BACKEND_RESOURCE = "https://backend.gowoop.de";

export function composeBackendUrl(path: string) {
  return BACKEND_BASE_URL + (path.startsWith("/") ? path : `/${path}`);
}

export const useLazyBackendRequest = <SerializedData>(
  path?: string,
  lazyRequest?: SerializedRequest<any>,
): LazyState<SerializedData> => {
  const [data, setData] = useState<SerializedData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { getAccessToken } = useLogto();
  const { organization } = useContext(ImpersonationContext);

  const makeRequest: SerializedRequestHandler<SerializedData> = async (
    serializedRequest?: SerializedRequest<any>,
  ): Promise<SerializedResponse<SerializedData>> => {
    setLoading(true);
    const accessToken = await getAccessToken(BACKEND_RESOURCE);
    const baseOptions: { headers: Record<string, string> } = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        ...(organization ? { "x-role-override": "customer" } : {}),
      },
    };

    // Check if the request includes FormData and adjust headers accordingly
    if (serializedRequest?.body instanceof FormData) {
      // If the body is an instance of FormData, we delete the Content-Type so it's set automatically with the boundary
      delete baseOptions.headers["Content-Type"];
    } else {
      // Otherwise, we set it to application/json for regular JSON requests
      baseOptions.headers["Content-Type"] = "application/json";
    }

    const optionsWithAuth =
      serializedRequest != null
        ? merge(serializedRequest, baseOptions, lazyRequest)
        : merge(baseOptions, lazyRequest);
    const backendPath = serializedRequest?.path ?? path;
    if (backendPath == null) {
      throw new Error(
        "No backend path provided. Please set the path in the hook or pass it as an argument to the request function.",
      );
    }
    const response = await fetch(
      composeBackendUrl(backendPath),
      optionsWithAuth,
    );
    try {
      const data = await response.json();
      if (response.ok) {
        setData(data);
        return {
          data,
        };
      } else {
        const error = new RequestError(
          response.status,
          response.statusText,
          data,
        );
        setError(error);
        return {
          error: error,
        };
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
        return {
          error: e,
        };
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    request: (request) => makeRequest(request),
    loading,
    error,
  };
};

const useBackendRequest = <SerializedData>(
  path: string,
): State<SerializedData> => {
  const state = useLazyBackendRequest<SerializedData>(path);

  useEffect(() => {
    state.request();
  }, [path]);

  return {
    ...state,
    refetch: async () => {
      await state.request();
    },
  };
};

export default useBackendRequest;
