import Label from "../../Label";
import React from "react";
import type { SxProps } from "@mui/material";

export function TestCampaignLabel({ sx }: { readonly sx?: SxProps }) {
  return (
    <Label color="warning" size="large" sx={sx}>
      Test
    </Label>
  );
}
