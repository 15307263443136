import type {
  Ad_Platform_Enum,
  CampaignFragment,
  HukGsFragment,
} from "generated/graphql";
import { retrieveRemainingBudget } from "utils/kpi_calculation/retrieveRemainingBudget";
import type { PLATFORM } from "./getCampaignKPIs";
import getCampaignKPIs from "./getCampaignKPIs";

type KpiPlatforms = "GESAMT" | keyof typeof Ad_Platform_Enum;

export function getHukGsKPIs(gs: HukGsFragment, platform: PLATFORM) {
  const campaigns = gs.gs_campaigns;
  let campaignBudget = 0;
  let budgetLeft = 0;
  let impressions = 0;
  let reach = 0;
  let clicks = 0;
  let ctrSum = 0;
  let campaignsWithCTR = 0;
  const totalLeadsMapResult = new Map<PLATFORM, number>();
  const qualifiedLeadsMapResult = new Map<PLATFORM, number>();
  let nonZeroKPIPlatformsResult = [] as KpiPlatforms[];

  [
    { ...gs, ad_campaign_metrics: [] } as unknown as CampaignFragment,
    ...campaigns.map((c) => c.campaign),
  ].forEach((campaign) => {
    if (campaign == null) {
      return null;
    }
    const {
      externalKpis,
      platformKPIs,
      qualifiedLeadsMap,
      totalLeadsMap,
      nonZeroKPIPlatforms,
    } = getCampaignKPIs({
      campaign: campaign,
      includeInternalInsights: false,
      selectedPlatform: platform,
    });

    campaignBudget += externalKpis[0].value ?? 0;
    budgetLeft += retrieveRemainingBudget(campaign) ?? 0;
    impressions += platformKPIs[0].value ?? 0;
    reach += platformKPIs[1].value ?? 0;
    nonZeroKPIPlatforms.forEach((platform) => {
      if (!nonZeroKPIPlatformsResult.includes(platform)) {
        nonZeroKPIPlatformsResult.push(platform);
      }
    });

    totalLeadsMap.forEach((value, key) => {
      if (totalLeadsMapResult.has(key)) {
        totalLeadsMapResult.set(
          key,
          (totalLeadsMapResult.get(key) ?? 0) + value,
        );
      } else {
        totalLeadsMapResult.set(key, value);
      }
    });
    qualifiedLeadsMap.forEach((value, key) => {
      if (qualifiedLeadsMapResult.has(key)) {
        qualifiedLeadsMapResult.set(
          key,
          (qualifiedLeadsMapResult.get(key) ?? 0) + value,
        );
      } else {
        qualifiedLeadsMapResult.set(key, value);
      }
    });

    clicks += campaign.ad_campaign_metrics
      .filter(
        (metrics) => platform === "GESAMT" || metrics.platform === platform,
      )
      .reduce((metrics_acc, data) => {
        return metrics_acc + (data.clicks ?? 0);
      }, 0);
    ctrSum += campaign.insights?.click_through_rate ?? 0;
    if (campaign.insights?.click_through_rate != null) {
      campaignsWithCTR++;
    }
  });

  const totalLeadCount = totalLeadsMapResult.get(platform) ?? 0;
  const qualifiedLeadCount = qualifiedLeadsMapResult.get(platform) ?? 0;
  const averageCTR = ctrSum / campaignsWithCTR / 100;

  const spentBudget = campaignBudget - budgetLeft;

  const kpis = [
    {
      name: "Gesamtbudget",
      value: campaignBudget,
      displayValue: campaignBudget.toLocaleString("de") + " €",
    },
    {
      name: "Impressionen",
      value: impressions,
      displayValue: impressions.toLocaleString("de"),
    },
    {
      name: "Reichweite",
      value: reach,
      displayValue: reach.toLocaleString("de"),
    },
    {
      name: "CPL",
      value: spentBudget / totalLeadCount,
      displayValue: (spentBudget / totalLeadCount ?? 0).toLocaleString("de", {
        style: "currency",
        currency: "EUR",
      }),
    },
    {
      name: "CPC",
      value: spentBudget / clicks,
      displayValue: (spentBudget / clicks).toLocaleString("de", {
        style: "currency",
        currency: "EUR",
      }),
    },
    {
      name: "CTR",
      value: averageCTR,
      displayValue: Number.isNaN(averageCTR)
        ? "-"
        : averageCTR.toLocaleString("de", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
    },
  ];

  if (nonZeroKPIPlatformsResult.length === 2) {
    nonZeroKPIPlatformsResult = nonZeroKPIPlatformsResult.filter(
      (platform) => platform === "GESAMT",
    );
  }

  if (totalLeadsMapResult.size === 2) {
    totalLeadsMapResult.delete(
      Array.from(totalLeadsMapResult.keys()).filter(
        (key) => key !== "GESAMT",
      )[0],
    );
  }
  if (qualifiedLeadsMapResult.size === 2) {
    qualifiedLeadsMapResult.delete(
      Array.from(totalLeadsMapResult.keys()).filter(
        (key) => key !== "GESAMT",
      )[0],
    );
  }

  return {
    kpis,
    nonZeroKPIPlatforms: nonZeroKPIPlatformsResult,
    totalLeadsMap: totalLeadsMapResult,
    qualifiedLeadsMap: qualifiedLeadsMapResult,
    totalLeadCount,
    qualifiedLeadCount,
  };
}
