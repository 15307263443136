import type { RegionFragment } from "generated/graphql";
import { useGetAllRegionsQuery } from "generated/graphql";
import { RegionDropdown } from "components/RegionDropdown";

function findRegionsFromNames(names: string[], regions: RegionFragment[]) {
  return regions.filter((region) => names.includes(region.name));
}

export function RegionFilterDropdown({
  setValues,
  values,
}: {
  readonly setValues: (values: string[]) => void;
  readonly values: string[];
}) {
  const { data } = useGetAllRegionsQuery();

  if (
    data?.organization_region == null ||
    data?.organization_region.length === 0
  )
    return <div />;

  const regions = findRegionsFromNames(values, data.organization_region);

  return (
    <RegionDropdown
      label="Regionen auswählen"
      multiple
      onChange={(value) => {
        if (Array.isArray(value)) {
          setValues(value.map((v) => v.name));
          return;
        }
        setValues(value?.name == null ? [] : [value.name]);
      }}
      options={data.organization_region}
      value={regions == null ? null : regions}
    />
  );
}
