import { Card, CardContent, Grid, Stack, Tab, Tabs } from "@mui/material";
import type { PLATFORM } from "hooks/campaign/getCampaignKPIs";
import type { CampaignFragment, HukGsDetailsFragment } from "generated/graphql";
import { INSIGHTS_GREEN, INSIGHTS_RED } from "../colors";
import StatProperty from "../StatProperty";
import { getUnqualifiedLeads } from "./answerInsightsHelpers";
import { HukContactsPerDayInsightsChart } from "./HukContactsPerDayInsightsChart";

export interface GsCampaigns {
  __typename?: "huk_gs_campaigns";
  campaign?: CampaignFragment | null;
}

export interface GsApplicationInsightsProps {
  readonly gs: HukGsDetailsFragment;
  readonly totalLeadsMap: Map<PLATFORM, number>;
  readonly qualifiedLeadsMap: Map<PLATFORM, number>;
  readonly selectedTab: PLATFORM;
  readonly setSelectedTab: (value: PLATFORM) => void;
}

export default function ApplicationInsights({
  gs,
  totalLeadsMap,
  qualifiedLeadsMap,
  selectedTab,
  setSelectedTab,
}: GsApplicationInsightsProps) {
  const unqualifiedLeads = getUnqualifiedLeads(
    totalLeadsMap.get(selectedTab),
    qualifiedLeadsMap.get(selectedTab),
  );
  return (
    <Grid item width="100%" xs={12}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Tabs
          allowScrollButtonsMobile
          onChange={(e, value) => {
            setSelectedTab(value);
          }}
          scrollButtons="auto"
          sx={{ ml: 2, minHeight: 0 }}
          value={selectedTab}
          variant="scrollable"
        >
          {Array.from(totalLeadsMap).map(([key, value]) => (
            <Tab
              disableRipple
              key={key}
              label={key.toLocaleLowerCase("de")}
              sx={{ minHeight: 0, py: 1 }}
              value={key}
            />
          ))}
        </Tabs>
      </Stack>

      <Stack direction="row" gap={2} justifyContent="stretch" width="100%">
        <Card
          sx={{
            flexGrow: 1,
          }}
        >
          <CardContent>
            <Stack>
              <StatProperty
                caption="gesamt"
                showDash={totalLeadsMap.get(selectedTab) == undefined}
                value={totalLeadsMap.get(selectedTab)?.toString()}
              />
              <StatProperty
                caption="qualifiziert"
                progress={
                  qualifiedLeadsMap.get(selectedTab) == undefined ||
                  totalLeadsMap.get(selectedTab) == undefined
                    ? undefined
                    : {
                        value:
                          (qualifiedLeadsMap.get(selectedTab) ?? 0) /
                          (totalLeadsMap.get(selectedTab) ?? 1),
                        color: INSIGHTS_GREEN,
                      }
                }
                showDash={qualifiedLeadsMap.get(selectedTab) == undefined}
                value={qualifiedLeadsMap.get(selectedTab)?.toString()}
              />
              <StatProperty
                caption="unqualifiziert"
                progress={
                  unqualifiedLeads == undefined ||
                  totalLeadsMap.get(selectedTab) == undefined
                    ? undefined
                    : {
                        value:
                          unqualifiedLeads /
                          (totalLeadsMap.get(selectedTab) ?? 1),
                        color: INSIGHTS_RED,
                      }
                }
                showDash={unqualifiedLeads == undefined}
                value={unqualifiedLeads?.toString()}
              />
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            flexGrow: 2,
          }}
        >
          <CardContent
            sx={{
              height: "100%",
            }}
          >
            <HukContactsPerDayInsightsChart gs={gs} selectedTab={selectedTab} />
          </CardContent>
        </Card>
      </Stack>
    </Grid>
  );
}
