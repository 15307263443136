import { PropsWithChildren } from "react";
import { Permission } from "../contexts/UserContext";
import { PermissionToggle } from "./PermissionToggle";

export function RequiresPermission(
  props: PropsWithChildren<{
    permission: Permission;
  }>,
) {
  return (
    <PermissionToggle
      permission={props.permission}
      allowed={props.children}
      else={<></>}
    />
  );
}
