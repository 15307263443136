import { useContext, useEffect } from "react";
import ImpersonationContext from "../../contexts/ImpersonationContext";
import Box from "@mui/material/Box";
import {
  ImpersonationFragment,
  useGetImpersonationOrganizationQuery,
} from "../../generated/graphql";
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "../Iconify";
import ImpersonationRegionAndLocationDropdown from "../ImpersonationRegionAndLocationDropdown";

function ImpersonationDisplayContent(
  organizationId: string,
  props: { isOpen: boolean; onDisplayChange: () => void },
) {
  const { data, loading } = useGetImpersonationOrganizationQuery({
    variables: {
      id: organizationId,
    },
  });
  useEffect(() => {
    props.onDisplayChange();
  }, [loading]);
  if (loading) {
    return <Box />;
  }
  const organization = data?.organization_by_pk;

  if (organization == null) {
    console.log("Organization is null", organizationId);
    return <Box />;
  }
  if (props.isOpen) return <ImpersonationCard organization={organization} />;
  return (
    <Box
      sx={{
        mb: 1,
      }}
    >
      <EndImpersonationButton />
    </Box>
  );
}

const ImpersonationDisplay = (props: {
  isOpen: boolean;
  onDisplayChange: () => void;
}) => {
  const { organization: organizationId } = useContext(ImpersonationContext);
  if (organizationId == null) return <Box />;
  return ImpersonationDisplayContent(organizationId, props);
};

function EndImpersonationButton() {
  const { organization, setOrganization } = useContext(ImpersonationContext);
  return (
    <Stack direction="row" justifyContent="center">
      <Tooltip title={"Du bist gerade als " + organization + " eingeloggt."}>
        <IconButton
          onClick={() => {
            setOrganization(null);
          }}
        >
          <Iconify
            icon={"material-symbols:stop-circle-rounded"}
            color={"red"}
            height={24}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

const ImpersonationCard = ({
  organization,
}: {
  organization: ImpersonationFragment;
}) => {
  const { setLocation, setRegion, region, location } =
    useContext(ImpersonationContext);

  return (
    <Box sx={{ m: 1 }}>
      <Card>
        <CardContent>
          <Stack gap={3}>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Box>
                <Typography variant={"h6"}>Achtung!</Typography>
                <Typography variant={"caption"}>
                  Du bist gerade als{" "}
                  <Typography
                    variant={"caption"}
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {organization?.name}
                  </Typography>{" "}
                  eingeloggt.
                </Typography>
              </Box>
              <EndImpersonationButton />
            </Stack>
            <ImpersonationRegionAndLocationDropdown
              regions={organization.regions}
              onLocationSelected={(location) =>
                setLocation(location?.slug ?? null)
              }
              onRegionSelected={(region) => setRegion(region?.slug ?? null)}
              initialLocation={location}
              initialRegion={region}
            />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ImpersonationDisplay;
