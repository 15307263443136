import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://9085338ccc6fb23c98e3d5265edf46de@o4507027747438592.ingest.us.sentry.io/4507163266646016",
    integrations: [new Sentry.BrowserTracing()],
    tracePropagationTargets: [
      "https://backend.everbay.de",
      "https://api.everbay.de",
    ],
    tracesSampleRate: 1.0,
  });
}
