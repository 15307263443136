import React, { Component } from "react";
import { AnswerDistribution } from "./answerInsightsHelpers";
import Chart from "react-apexcharts";
import { merge } from "lodash";
import { BaseOptionChart } from "../../chart";
import { theme } from "../../../theme/theme";
import { fNumber, fPercent } from "../../../utils/formatNumber";
import { INSIGHT_COLORS } from "../colors";

export const AnswerDistributionChart: React.FC<{
  answerDistribution: AnswerDistribution;
}> = ({ answerDistribution }) => {
  const chartOptions: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    colors: INSIGHT_COLORS,
    labels: Array.from(answerDistribution.keys()),
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      floating: false,
      horizontalAlign: "center",
      position: "bottom",
      height: 60,
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          labels: {
            name: {
              formatter: function (title: string) {
                return removeExplanationFromQuestionTitle(title);
              },
            },
            value: {
              formatter: (
                val: number,
                w: { globals: { seriesTotals: number[] } },
              ) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return `${fPercent((val / sum) * 100)}`;
              },
            },
            total: {
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              },
              label: "Antworten",
            },
          },
        },
      },
    },
  });

  return (
    <Chart
      height={310}
      type={"donut"}
      key={"answer-dist-bar-chart"}
      series={Array.from(answerDistribution.values())}
      options={chartOptions}
    />
  );
};

function removeExplanationFromQuestionTitle(questionTitle: string) {
  const startOfExplanationDetails = questionTitle.indexOf("(");

  let shortenedTitle = questionTitle;

  const hasExplanation = startOfExplanationDetails != -1;
  if (hasExplanation) {
    shortenedTitle = questionTitle.substring(0, startOfExplanationDetails);
  }

  return shortenedTitle;
}
