import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { ProgressCircle } from "./ProgressCircle";

const EM_DASH = "–";

export interface StatPropertyProps {
  caption?: string;
  value?: string;
  showDash?: boolean;
  progress?: {
    value: number;
    color: string;
  };
}

const StatProperty: FC<StatPropertyProps> = ({
  showDash,
  value,
  caption,
  progress,
}) => {
  const valueText = (
    <Typography variant="h4">{showDash ? EM_DASH : value}</Typography>
  );
  return (
    <Stack gap={0.2} alignItems={"center"}>
      {progress ? (
        <ProgressCircle progress={progress} half={true} value={value} />
      ) : (
        valueText
      )}
      {caption && (
        <Typography
          color="text.secondary"
          variant="subtitle2"
          textAlign={"center"}
        >
          {caption}
        </Typography>
      )}
    </Stack>
  );
};

export default StatProperty;
