import { Stack, Typography } from "@mui/material";
import React from "react";

export function NoAttachments() {
  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
      <Typography variant={"h6"}>Keine Anhänge</Typography>
    </Stack>
  );
}
