import { IconButton, Stack } from "@mui/material";
import type React from "react";
import ErrorDisplay from "../../ErrorDisplay";
import Iconify from "../../Iconify";
import type { ContactPopupProps } from "./ContactPopup";
import { ContactPopupContentCard } from "./ContactPopupContentCard";
import { ContactPopupHeader } from "./ContactPopupHeader";

export function ContactPopupContent({
  onClose,
  boardUsers,
  refetchBoardUsers,
  contactEntry,
  isDeclined,
  customDragProps,
  columnName,
  showOriginCampaign = false,
}: ContactPopupProps) {
  if (!contactEntry?.contact) {
    return <ErrorDisplay message="Contact not found" />;
  }

  return (
    <Stack
      gap={1}
      height="100%"
      overflow="hidden"
      p={3}
      sx={{
        width: {
          md: "100%",
          lg: "90%",
          xl: "90%",
        },
      }}
    >
      <Stack direction="row" justifyContent="end" width="100%">
        <IconButton onClick={() => onClose()}>
          <Iconify icon="heroicons:x-mark" />
        </IconButton>
      </Stack>
      <ContactPopupHeader
        columnName={columnName}
        contactEntry={contactEntry}
        customDragProps={customDragProps}
        isDeclined={isDeclined}
        onClose={onClose}
        showOriginCampaign={showOriginCampaign}
      />
      <ContactPopupContentCard
        boardUsers={boardUsers}
        contact={contactEntry.contact}
        contactBoardEntryId={contactEntry.id}
        refetchBoardUsers={refetchBoardUsers}
      />
    </Stack>
  );
}
