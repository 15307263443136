import { alpha, styled, Theme, useTheme } from "@mui/material/styles";
import { BoxProps } from "@mui/material";
import { ColorSchema } from "./palette";

export type LabelColor =
  | "default"
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";

type LabelVariant = "filled" | "outlined" | "ghost";

const RootStyle = styled("span")(({
  theme,
  ownerState,
}: {
  theme: Theme;
  ownerState: {
    color: LabelColor;
    variant: LabelVariant;
    size: "small" | "medium" | "large";
  };
}) => {
  const isLight = theme.palette.mode === "light";
  const { color, variant } = ownerState;

  const styleFilled = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
  });

  const styleOutlined = (color: ColorSchema) => ({
    color: theme.palette[color].main,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette[color].main}`,
  });

  const styleGhost = (color: ColorSchema) => ({
    color: theme.palette[color][isLight ? "main" : "light"],
    backgroundColor: alpha(theme.palette[color].light, 0.16),
  });

  let padding = theme.spacing(1.5, 1.5);
  if (ownerState.size === "small") {
    padding = theme.spacing(0.5, 1);
  } else if (ownerState.size === "large") {
    padding = theme.spacing(2, 2);
  }

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 4,
    cursor: "default",
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    padding: padding,
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey["50"],
    fontWeight: theme.typography.fontWeightBold,

    ...(color !== "default"
      ? {
          ...(variant === "filled" && { ...styleFilled(color) }),
          ...(variant === "outlined" && { ...styleOutlined(color) }),
          ...(variant === "ghost" && { ...styleGhost(color) }),
        }
      : {
          ...(variant === "outlined" && {
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }),
          ...(variant === "ghost" && {
            color: isLight
              ? theme.palette.text.secondary
              : theme.palette.common.white,
            backgroundColor: theme.palette.grey[200],
          }),
        }),
  };
});

interface Props extends BoxProps {
  color?: LabelColor;
  variant?: LabelVariant;
  size?: "small" | "medium" | "large";
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export default function Label({
  color = "default",
  variant = "ghost",
  size = "medium",
  children,
  sx,
  onClick,
}: Props) {
  const theme = useTheme();

  return (
    <RootStyle
      ownerState={{ color, variant, size }}
      sx={sx}
      theme={theme}
      onClick={onClick}
    >
      {children}
    </RootStyle>
  );
}
