import type { ReactNode } from "react";
import { createContext, useMemo, useState } from "react";
import type { DropResult } from "react-beautiful-dnd";
import type { ContactBoardEntryFragment } from "generated/graphql";

export interface ContactBoardContextProps {
  declineColumnDroppableId: string | null;
  setDeclineColumnDroppableId: (declineDroppableId: string | null) => void;
  sourceColumnId: string | null;
  setSourceColumnId: (sourceColumnId: string | null) => void;

  /**
   * This is used to pass the contact to be declined from the DeclineContactButton to the ApplicantRejectionDialog
   *
   * see {@link DeclineContactButton} and {@link ApplicantRejectionDialog}:
   * if != null the {@link ApplicantRejectionDialog} is opened
   */
  contactToBeRejected: ContactToBeRejected | null;
  setContactToBeRejected: (
    contactToBeRejected: ContactToBeRejected | null,
  ) => void;
}

export const ContactBoardContext = createContext<ContactBoardContextProps>({
  declineColumnDroppableId: null,
  setDeclineColumnDroppableId: () => {},

  sourceColumnId: null,
  setSourceColumnId: () => {},

  contactToBeRejected: null,
  setContactToBeRejected: () => {},
});

export function ContactBoardContextProvider({
  children,
}: {
  readonly children: ReactNode;
}) {
  const [declineColumnDroppableId, setDeclineColumnDroppableId] = useState<
    string | null
  >(null);
  const [sourceColumnId, setSourceColumnId] = useState<string | null>(null);
  const [contactToBeRejected, setContactToBeRejected] =
    useState<ContactToBeRejected | null>(null);

  const contextValue: ContactBoardContextProps = useMemo(
    () => ({
      declineColumnDroppableId: declineColumnDroppableId,
      setDeclineColumnDroppableId: setDeclineColumnDroppableId,
      sourceColumnId: sourceColumnId,
      setSourceColumnId: setSourceColumnId,
      contactToBeRejected: contactToBeRejected,
      setContactToBeRejected: setContactToBeRejected,
    }),
    [
      declineColumnDroppableId,
      setDeclineColumnDroppableId,
      sourceColumnId,
      setSourceColumnId,
      contactToBeRejected,
      setContactToBeRejected,
    ],
  );

  return (
    <ContactBoardContext.Provider value={contextValue}>
      {children}
    </ContactBoardContext.Provider>
  );
}

export type ContactToBeRejected = {
  contactEntry: ContactBoardEntryFragment;
  dropResult: DropResult;
};
