import { useSearchDebounce } from "hooks/useSearchDebounce";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";

export interface SearchContextType {
  search: string | null;
  debouncedSearch: string | null;
  updateSearchQuery: (value: string) => void;
}

export const SearchContext = createContext<SearchContextType>({
  search: "",
  debouncedSearch: null,
  updateSearchQuery: () => {},
});

export function SearchContextProvider({
  children,
}: {
  readonly children?: ReactNode;
}) {
  const [{ search, debouncedSearch }, updateSearchQuery] =
    useSearchDebounce(350);

  const value = useMemo<SearchContextType>(
    () => ({ search, debouncedSearch, updateSearchQuery }),
    [search, debouncedSearch, updateSearchQuery],
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

export function useSearch() {
  return useContext(SearchContext);
}
