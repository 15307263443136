import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import Iconify from "../../../../../Iconify";
import React from "react";

export function AttachmentOptionsMenu({
  isOpen,

  onClose,
  downloadAttachment,
  openAttachment,
  anchorEl,
}: {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  downloadAttachment: () => void;
  openAttachment: () => void;
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuItem
        onClick={() => {
          downloadAttachment();
          onClose();
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Iconify icon={"heroicons:arrow-down-tray"}></Iconify>
          <Typography>Download</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={() => {
          openAttachment();
          onClose();
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Iconify icon={"heroicons:folder-open"}></Iconify>
          <Typography>Öffnen</Typography>
        </Stack>
      </MenuItem>
    </Menu>
  );
}
