import type React from "react";
import { Fragment, useState } from "react";
import { Button, Drawer, Stack, Typography } from "@mui/material";
import Iconify from "../../../../Iconify";

export function FilterButton({
  content,
}: {
  readonly content: (props: { toggleDrawer: () => void }) => React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  function toggleDrawer() {
    setOpen(!open);
  }

  return (
    <>
      <Button
        endIcon={<Iconify icon="material-symbols:filter-list-rounded" />}
        onClick={() => {
          toggleDrawer();
        }}
        variant="outlined"
      >
        <Typography>Filter</Typography>
      </Button>
      <Drawer
        BackdropProps={{
          invisible: true,
        }}
        anchor="right"
        onClose={toggleDrawer}
        open={open}
      >
        {content({ toggleDrawer })}
      </Drawer>
    </>
  );
}
