import { DeepPartial } from "types";
import { CampaignFragment } from "generated/graphql";
import { getTotalBudget } from "./getCampaignBudget";
import { retrieveRemainingBudget } from "./retrieveRemainingBudget";

export function calculateExternalCpl(
  campaign: DeepPartial<CampaignFragment>,
  qualifiedLeads: number,
) {
  if (qualifiedLeads === 0) {
    return undefined;
  }

  const totalBudget = getTotalBudget(campaign);
  const remainingBudget = retrieveRemainingBudget(campaign);

  if (remainingBudget === undefined) {
    return undefined;
  }

  return (totalBudget - remainingBudget) / qualifiedLeads;
}
