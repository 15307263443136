import { styled } from "@mui/material/styles";
import { Avatar, Box, Typography } from "@mui/material";
import Iconify from "../Iconify";
import { useUser } from "../../hooks/useUser";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

type Props = {
  isCollapsed: boolean | undefined;
};

export default function NavbarAccount({ isCollapsed }: Props) {
  const { user } = useUser();

  return (
    <Box sx={{ m: 1 }}>
      <RootStyle
        sx={{
          ...(isCollapsed && {
            bgcolor: "transparent",
          }),
        }}
      >
        <Avatar src={user?.picture ?? undefined}>
          <Iconify icon={"heroicons:user-solid"} />
        </Avatar>

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapsed && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {user?.email}
          </Typography>
        </Box>
      </RootStyle>
    </Box>
  );
}
