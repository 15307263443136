import React, { PropsWithChildren } from "react";
import { UserRole } from "../contexts/UserContext";
import { RoleToggle } from "./RoleToggle";

export function RequiresRole(
  props: PropsWithChildren<{
    allowedRoles: UserRole[];
  }>,
) {
  return (
    <RoleToggle
      allowedRoles={props.allowedRoles}
      allowed={<>{props.children}</>}
      else={<></>}
    />
  );
}
