import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { useUser } from "hooks/useUser";
import type { Filter, Section } from "./filter-configuration";
import { FilterDrawerFooter } from "./FilterDrawerFooter";
import { FilterDrawerHeader } from "./FilterDrawerHeader";
import { FilterDrawerSection } from "./FilterDrawerSection";

type FilterDrawerContentProps<Key extends string> = {
  readonly handleClose: () => void;
  readonly sections: Section<Filter<Key>>[];
};

export function FilterDrawerContent<Key extends string>({
  handleClose,
  sections,
}: FilterDrawerContentProps<Key>) {
  const { role } = useUser();
  return (
    <Stack gap={1} height="100%" minWidth="300px" overflow="hidden" py={2}>
      <FilterDrawerHeader handleClose={handleClose} />
      <Box
        sx={{ scrollbarGutter: "stable both-edges", overflowY: "auto", px: 1 }}
      >
        <Stack flexGrow={1} gap={2}>
          {sections
            .filter(
              (value) => value.displayPredicate?.call(undefined, role) ?? true,
            )
            .map((section) => (
              <FilterDrawerSection key={section.name} section={section} />
            ))}
        </Stack>
      </Box>
      <FilterDrawerFooter />
    </Stack>
  );
}
