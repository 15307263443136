import { Box, Button, Card, Link, Stack, Typography } from "@mui/material";
import logo from "../assets/logo_full.png";
import { useLogto } from "@logto/react";
import { Helmet } from "react-helmet-async";

type LoginPageProps = {
  readonly redirectUrl: string;
};

export default function LoginPage({ redirectUrl }: LoginPageProps) {
  const { signIn } = useLogto();
  localStorage.setItem("redirectUrl", redirectUrl);

  async function loginClicked() {
    await signIn(
      `${window.location.protocol}//${window.location.host}/callback`,
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        background: "url('/bg-dark.jpg')",
        backgroundSize: "cover",
      }}
    >
      <Helmet>
        <title>Login | everbay</title>
      </Helmet>
      <Stack
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
        height="100%"
        justifyContent={{ xs: "center", md: "space-around" }}
      >
        <Box
          sx={{
            mb: { xs: 8, md: 0 },
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <img alt="Everbay Logo" src={logo} width="220px" />
          <Typography
            color="#FFF"
            sx={{
              mt: 2,
            }}
            textAlign="center"
            variant="h5"
            whiteSpace="nowrap"
          >
            Hallo. Schön, dass Sie wieder da sind.
          </Typography>
        </Box>
        <Card sx={{ p: 4 }}>
          <Stack spacing={2}>
            <Typography align="center" fontWeight="bold" variant="h5">
              Anmelden
            </Typography>
            <Button onClick={loginClicked} size="large" variant="contained">
              Jetzt anmelden
            </Button>
            <Typography align="center">
              Neu hier? <Link href="https://everbay.de">Demo vereinbaren</Link>
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
}
