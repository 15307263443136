import { CardContent, Link, Stack, Typography } from "@mui/material";
import CalendarIcon from "components/icons/waveIcons/CalendarIcon";
import MailIcon from "components/icons/waveIcons/MailIcon";
import PhoneIcon from "components/icons/waveIcons/PhoneIcon";
import TimeIcon from "components/icons/waveIcons/TimeIcon";
import UploadIcon from "components/icons/waveIcons/UploadIcon";
import type { ContactFragment } from "generated/graphql";
import moment from "moment";
import { CB_ICONS_GREY } from "../../../colors";
import { LeadingIcon } from "../../../LeadingIcon";
import { composeName, findCvURL, formatDate } from "../../../shared/utils";

const MATCH_DIGITS_REGEX = /\b\d+\b/;
const ICON_SIZE = "1rem";

/**
 * Highlights the number in the date description (e.g. "3 days ago" -> **3** days ago)
 */
export function formatAndHighlightTimeAgo(date: string | undefined) {
  const timeAgo = moment(date).fromNow();
  return timeAgo.replace(MATCH_DIGITS_REGEX, "<strong>$&</strong>");
}

export function ProfileTab({
  contact: {
    answers,
    converted_at,
    email_address,
    first_name,
    last_name,
    phone_number,
  },
}: {
  readonly contact: ContactFragment;
}) {
  const contactTime = answers.find(
    (answer) =>
      answer.question?.title ===
      "Wann können wir dich am besten am Telefon erreichen? (Mehrfachauswahl möglich)",
  );
  const formattedContactTime = contactTime?.value
    ?.split(";")
    .map((time) => time.trim())
    .join(", ");

  const formattedTimeAgo = formatAndHighlightTimeAgo(converted_at ?? undefined);

  const iconsStyle = {
    fill: CB_ICONS_GREY,
    width: ICON_SIZE,
    height: ICON_SIZE,
  };

  const cvUrl = findCvURL(answers);

  return (
    <CardContent>
      <Stack gap={1} sx={{ pl: 3 }}>
        <Typography variant="subtitle1">
          {composeName(first_name, last_name)}
        </Typography>
        <LeadingIcon
          icon={<PhoneIcon style={iconsStyle} />}
          sx={{ alignItems: "center" }}
        >
          <Typography variant="body1">{phone_number}</Typography>
        </LeadingIcon>
        <LeadingIcon
          icon={<MailIcon style={iconsStyle} />}
          sx={{ alignItems: "center" }}
        >
          <Typography variant="body1">{email_address}</Typography>
        </LeadingIcon>
        <LeadingIcon
          icon={<CalendarIcon style={iconsStyle} />}
          sx={{ alignItems: "center" }}
        >
          <Typography fontWeight="bold" variant="body1">
            {formatDate(converted_at, false)}
          </Typography>
          <Typography color={CB_ICONS_GREY} variant="body1">
            &nbsp; (
            <span dangerouslySetInnerHTML={{ __html: formattedTimeAgo }} />)
          </Typography>
        </LeadingIcon>
        {contactTime != undefined && (
          <LeadingIcon
            icon={<TimeIcon style={iconsStyle} />}
            sx={{ alignItems: "center" }}
          >
            <Typography fontWeight="bold" variant="body1">
              {formattedContactTime}
            </Typography>
          </LeadingIcon>
        )}
        {cvUrl != undefined && (
          <LeadingIcon
            icon={<UploadIcon style={iconsStyle} />}
            sx={{ alignItems: "center" }}
          >
            <Link href={cvUrl} rel="noreferrer" target="_blank">
              Lebenslauf
            </Link>
          </LeadingIcon>
        )}
      </Stack>
    </CardContent>
  );
}
