import { ResponsiblePersonFilterDropdown } from "components/campaigns/filter/components/filter/ResponsiblePersonFilterDropdown";
import { LocationFilterDropdown } from "./LocationFilterDropdown";
import { RegionFilterDropdown } from "./RegionFilterDropdown";
import type { UserRole } from "contexts/UserContext";
import { hasAnyOfRoles } from "contexts/UserContext";

export type CampaignFilterKey =
  | "campaign_type_payed"
  | "campaign_status_live"
  | "campaign_type_test"
  | "campaign_status_paused"
  | "campaign_status_finished"
  | "campaign_status_draft"
  | "campaign_status_all"
  | "campaign_region"
  | "campaign_location"
  | "campaign_visibility_all"
  | "campaign_visibility_not_archived"
  | "campaign_visibility_archived"
  | "campaign_no_ad_campaigns_only"
  | "campaign_only_partners"
  | "responsible_person";

export interface FilterOptions {
  type: "checkbox" | "custom";
  buildComponent?: (props: {
    setValues: (values: string[]) => void;
    values: string[];
  }) => JSX.Element;
}

export interface Filter<Key extends string> {
  key: Key;
  displayText: string;

  options?: FilterOptions;
  isParent?: boolean;
}

export interface Section<T> {
  name: string;
  filters: T[];
  displayPredicate?: (roles: UserRole | null) => boolean;
}

export const defaultCampaignFilterKeys: CampaignFilterKey[] = [
  "campaign_status_all",
  "campaign_type_payed",
  "campaign_type_test",
  "campaign_visibility_not_archived",
];
export const campaignFilterSections: Section<Filter<CampaignFilterKey>>[] = [
  {
    name: "Status",
    filters: [
      {
        key: "campaign_status_all",
        displayText: "Alle",
        isParent: true,
      },
      {
        key: "campaign_status_live",
        displayText: "Aktiv",
      },
      {
        key: "campaign_status_paused",
        displayText: "Pausiert",
      },
      {
        key: "campaign_status_finished",
        displayText: "Abgeschlossen",
      },
      {
        key: "campaign_status_draft",
        displayText: "Ausstehend",
      },
    ],
  },

  {
    name: "Typ",
    filters: [
      {
        key: "campaign_type_payed",
        displayText: "Bezahlt",
      },
      {
        key: "campaign_type_test",
        displayText: "Test",
      },
    ],
  },
  {
    name: "Sichtbarkeit",
    displayPredicate: (role) => role != "customer",
    filters: [
      {
        key: "campaign_visibility_all",
        displayText: "Alle",
        isParent: true,
      },
      {
        key: "campaign_visibility_not_archived",
        displayText: "Nicht ausgeblendet",
      },
      {
        key: "campaign_visibility_archived",
        displayText: "Ausgeblendet",
      },
    ],
  },
  {
    name: "Standort & Region",
    filters: [
      {
        key: "campaign_region",
        displayText: "Region",
        options: {
          type: "custom",
          buildComponent: ({ setValues, values }) => (
            <RegionFilterDropdown setValues={setValues} values={values} />
          ),
        },
      },
      {
        key: "campaign_location",
        displayText: "Standort",
        options: {
          type: "custom",
          buildComponent: ({ setValues, values }) => (
            <LocationFilterDropdown setValues={setValues} values={values} />
          ),
        },
      },
    ],
  },
  {
    name: "Zuständige Person",
    filters: [
      {
        key: "responsible_person",
        displayText: "Zuständige Person",
        options: {
          type: "custom",
          buildComponent: ({ setValues, values }) => (
            <ResponsiblePersonFilterDropdown
              setValues={setValues}
              values={values}
            />
          ),
        },
      },
    ],
  },
  {
    name: "Sonstiges",
    displayPredicate: (role) =>
      hasAnyOfRoles(["woop_admin", "support", "creator"], role),
    filters: [
      {
        key: "campaign_no_ad_campaigns_only",
        displayText: "Nur ohne Ad-Kampagnen",
      },
      {
        key: "campaign_only_partners",
        displayText: "Nur Partner",
      },
    ],
  },
];
