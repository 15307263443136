import { Autocomplete, TextField } from "@mui/material";
import { useGetDistinctResponsiblePersonsQuery } from "generated/graphql";

export function ResponsiblePersonFilterDropdown({
  values,
  setValues,
}: {
  readonly values: string[];
  readonly setValues: (values: string[]) => void;
}) {
  const { data, loading } = useGetDistinctResponsiblePersonsQuery();
  const options: string[] = (data?.distinct_persons ?? [])
    .map((c) => c.responsible_person)
    .filter((v): v is string => v != null);

  return (
    <Autocomplete
      autoHighlight
      id="person-select"
      multiple
      onChange={(event, value) => {
        if (Array.isArray(value)) {
          setValues(value);
          return;
        }
        setValues(value == null ? [] : [value]);
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
          }}
          label="Zuständige Person"
        />
      )}
      sx={{ width: 300 }}
      value={values ?? []}
    />
  );
}
