import useBackendRequest, { useLazyBackendRequest } from "./useBackendRequest";
import { SharedUser } from "../components/contact_board/contactBoardUtils";
import { useEffect } from "react";

const useContactBoardUsers = (contactBoardId?: string, campaignId?: string) => {
  const path = `/users/contact-board?boardId=${
    contactBoardId ?? ""
  }&campaignId=${campaignId ?? ""}`;

  const state = useLazyBackendRequest(path);

  useEffect(
    function makeRequestOnceParamsAreLoaded() {
      if (contactBoardId && campaignId) {
        state.request();
      }
    },
    [contactBoardId, campaignId],
  );

  return {
    ...state,
    data: state.data as SharedUser[] | null,
    refetch: async () => {
      await state.request();
    },
  };
};
export default useContactBoardUsers;
