import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

export function OriginCampaignLink({
  originCampaign,
}: {
  readonly originCampaign: {
    id: string;
    name: string;
  } | null;
}) {
  if (!originCampaign) {
    return null;
  }
  return (
    <Link
      component={RouterLink}
      sx={{ cursor: "pointer" }}
      to={`/campaigns/${originCampaign?.id}?tab=insights`}
      variant="body2"
    >
      {originCampaign?.name}
    </Link>
  );
}
