import { ContactFragment } from "../../../../../generated/graphql";
import { CardContent, Stack } from "@mui/material";
import React from "react";
import { formatDate } from "../../../shared/utils";
import { NoAttachments } from "./components/NoAttachments";
import { AttachmentCard } from "./components/AttachmentCard";

const attachmentUrl = "https://uploads.leadnotifications.co";

export function AttachmentsTab({ contact }: { contact: ContactFragment }) {
  const attachments = contact.answers.filter(
    (answer) => answer.value?.includes(attachmentUrl),
  );
  console.log(contact.converted_at);
  return (
    <CardContent>
      <Stack spacing={2}>
        {attachments.length != 0 ? (
          attachments.map((attachment) => (
            <AttachmentCard
              key={attachment.id}
              attachment={{
                ...attachment,
                createdAt: contact.converted_at ?? "",
              }}
            />
          ))
        ) : (
          <NoAttachments />
        )}
      </Stack>
    </CardContent>
  );
}
