import type { DeepPartial } from "types";
import type { CampaignFragment } from "generated/graphql";

export function getCampaignBudget(
  campaign: DeepPartial<CampaignFragment>,
): number | undefined {
  return campaign.customer_budget_initial;
}

export function getTotalBudget(
  campaign: DeepPartial<CampaignFragment>,
): number {
  return campaign.customer_budget_total;
}
