import { DeepPartial } from "types";
import { CampaignFragment } from "generated/graphql";
import { getTotalBudget } from "./getCampaignBudget";
import { calculateRemainingMediaBudget } from "./calculateRemainingMediaBudget";
import { getMediaBudget } from "./getMediaBudget";

export function calculateContribution(campaign: DeepPartial<CampaignFragment>) {
  const budget = getTotalBudget(campaign);
  const mediaBudget = getMediaBudget(campaign);
  const remainingMediaBudget = calculateRemainingMediaBudget(campaign);
  if (budget != null && mediaBudget != null && remainingMediaBudget != null) {
    return budget - (mediaBudget - remainingMediaBudget);
  }
  return undefined;
}
