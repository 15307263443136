import { Divider, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "components/Iconify";

export function FilterDrawerHeader({
  handleClose,
}: {
  readonly handleClose: () => void;
}) {
  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        px={2}
      >
        <Typography variant="h5">Filter</Typography>
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <Iconify icon="heroicons:x-circle" />
        </IconButton>
      </Stack>
      <Divider
        sx={{
          mb: 1,
          mx: 2,
        }}
      />
    </>
  );
}
