import type { Filter, Section } from "./filter-configuration";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { FilterDrawerItem } from "./FilterDrawerItem";
import { useFilter } from "../../FilterContext";

export function FilterDrawerSection<Key extends string>({
  section,
}: {
  readonly section: Section<Filter<Key>>;
}) {
  const {
    activeFilters,
    toggleFilter,
    setCustomFilterValues,
    customFilterValues,
  } = useFilter();

  const { name, filters } = section;

  return (
    <Box>
      <Typography fontSize="1.1em" fontWeight="bold">
        {name}
      </Typography>
      <Stack direction="column" gap={0.5} sx={{ scrollbarGutter: "stable" }}>
        {filters.map((filter) => (
          <FilterDrawerItem
            active={activeFilters.has(filter.key)}
            filter={filter}
            key={filter.key}
            section={section}
            setCustomFilterValues={setCustomFilterValues}
            toggleFilter={toggleFilter}
            values={customFilterValues.get(filter.key) ?? []}
          />
        ))}
      </Stack>
    </Box>
  );
}
