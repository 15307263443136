import type { CampaignFragment } from "../../../generated/graphql";
import { LightTooltip } from "../../LigthTooltip";
import { IconButton } from "@mui/material";
import Iconify from "../../Iconify";
import React from "react";
import { useNavigate } from "react-router-dom";

export function EditCampaignButton({
  campaign,
}: {
  readonly campaign: CampaignFragment;
}) {
  const navigate = useNavigate();

  return (
    <LightTooltip title="Kampagne bearbeiten">
      <IconButton
        color="primary"
        onClick={() => {
          navigate(`/campaigns/${campaign.id}/edit`);
        }}
        size="small"
      >
        <Iconify icon="heroicons:pencil" />
      </IconButton>
    </LightTooltip>
  );
}
