import { LightTooltip } from "../../LigthTooltip";
import { Box } from "@mui/material";
import React from "react";
import ExternalToolLink, {
  MONDAY_ICON,
} from "../campaign_card/ExternalToolLink";
import type { CampaignFragment } from "../../../generated/graphql";

export function ToMondayBoardAction({
  campaign,
}: {
  readonly campaign: CampaignFragment;
}) {
  return (
    <LightTooltip title="Zum Monday-Board">
      <Box>
        <ExternalToolLink icon={MONDAY_ICON} url={campaign.monday_url} />
      </Box>
    </LightTooltip>
  );
}
