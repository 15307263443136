import { Stack } from "@mui/material";
import { RequiresPermission } from "components/RequiresPermission";
import type { CampaignFragment } from "generated/graphql";
import Label from "../../Label";
import { CopyPerspectiveIdAction } from "../campaign_actions/CopyPerspectiveIdAction";
import { EditPerspectiveFunnel } from "../campaign_actions/EditPerspectiveFunnel";
import { ToApplicantBoardAction } from "../campaign_actions/ToApplicantBoardAction";
import { ToMondayBoardAction } from "../campaign_actions/ToMondayBoardAction";

export function AdminCampaignActionsExternalTools({
  campaign,
}: {
  readonly campaign: CampaignFragment;
}) {
  const adCampaignCount = campaign.ad_campaign_metrics.length;
  const hasNoConnectedAdCampaign = adCampaignCount === 0;

  return (
    <RequiresPermission permission="edit:campaign">
      <Stack direction="row" flexWrap="wrap" gap={1} marginTop={2}>
        {hasNoConnectedAdCampaign ? (
          <Label color="error" size="small">
            Keine Ad-Kampagne verknüpft
          </Label>
        ) : null}
        {campaign.perspective_campaign_id != null &&
        campaign.perspective_campaign_id !== "" ? (
          <>
            <CopyPerspectiveIdAction
              perspectiveCampaignId={campaign.perspective_campaign_id}
            />
            <EditPerspectiveFunnel
              perspectiveCampaignId={campaign.perspective_campaign_id}
            />
          </>
        ) : null}
        <ToMondayBoardAction campaign={campaign} />
        <ToApplicantBoardAction campaign={campaign} />
      </Stack>
    </RequiresPermission>
  );
}
