import type { CampaignFragment } from "../../../generated/graphql";
import { Box } from "@mui/material";
import { EditCampaignButton } from "../campaign_actions/EditCampaignButton";
import { ToApplicantBoardAction } from "../campaign_actions/ToApplicantBoardAction";
import { ToFunnelButton } from "../ToFunnelButton";
import { CampaignStatusLabel } from "../campaign_labels/CampaignStatusLabel";
import React from "react";
import { getCampaignStatus } from "../../../utils/getCampaignStatus";
import { PermissionToggle } from "../../PermissionToggle";

export function CampaignActionsAndStatus({
  campaign,
}: {
  readonly campaign: CampaignFragment;
}) {
  return (
    <Box alignItems="center" display="flex" gap={1} justifyContent="flex-end">
      <PermissionToggle
        allowed={<EditCampaignButton campaign={campaign} />}
        else={<ToApplicantBoardAction campaign={campaign} />}
        permission="edit:campaign"
      />
      {campaign.perspective_campaign?.url !== undefined && (
        <ToFunnelButton
          perspectiveCampaignURL={campaign.perspective_campaign.url}
        />
      )}
      <CampaignStatusLabel
        campaignStatus={getCampaignStatus(campaign)}
        isHidden={campaign.is_hidden}
      />
    </Box>
  );
}
