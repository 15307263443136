import { useRef, useState } from "react";
import { alpha } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import MenuPopover from "../MenuPopover";
import IconButtonAnimate from "../animate/IconButtonAnimate";
import { useLogto } from "@logto/react";
import Iconify from "../Iconify";
import { useUser } from "../../hooks/useUser";

const MENU_OPTIONS: { label: string }[] = [];

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { signOut } = useLogto();
  const { user } = useUser();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function logout() {
    await signOut(`${window.location.origin}/`);
  }

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={user?.picture ?? undefined}>
          <Iconify icon={"heroicons:user-solid"} />
        </Avatar>
      </IconButtonAnimate>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: "max-content", minWidth: "220px" }}
      >
        <Box sx={{ my: 1.5, px: 2.5, width: "max-content" }}>
          <Typography variant="subtitle1" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email ?? "Keine E-Mail-Adresse festgelegt"}
          </Typography>
        </Box>

        <Divider />
        <Stack spacing={0.5} sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2, borderRadius: 1 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        {MENU_OPTIONS.length > 0 && <Divider />}

        <MenuItem
          onClick={logout}
          sx={{ typography: "body2", py: 1, px: 2, borderRadius: 1, m: 1 }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
