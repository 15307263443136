import { Stack, Typography } from "@mui/material";
import Iconify from "../Iconify";
import React from "react";
import type { Variant } from "@mui/material/styles/createTypography";

export function SomethingNotFoundDisplay(props: {
  readonly description: string;
  readonly variant?: Variant;
}) {
  const { description } = props;

  return (
    <Stack alignItems="center" gap={1} height="100%" justifyContent="center">
      <Iconify fontSize="3em" icon="mdi:emoticon-sad-outline" />
      <Typography variant={props.variant ?? "h4"}>{description}</Typography>
    </Stack>
  );
}
