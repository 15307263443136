import {
  ContactFragment,
  useGetNotesQuery,
} from "../../../../../generated/graphql";
import ErrorDisplay from "../../../../ErrorDisplay";
import Loading from "../../../../Loading";
import { CardContent, Stack } from "@mui/material";
import { LeadingIcon } from "../../../LeadingIcon";
import Box from "@mui/material/Box";
import React from "react";
import { UserProfileImage } from "../../../shared/UserProfileImage";
import { useUser } from "../../../../../hooks/useUser";
import { NewNoteField } from "./components/NewNoteField";
import { NoNotes } from "./components/NoNotes";
import { NoteCard } from "./components/NoteCard";
import { SharedUser } from "../../../contactBoardUtils";

export function NotesTab({
  contact,
  contactBoardEntryId,
  boardUsers,
  refetchBoardUsers,
}: {
  contact: ContactFragment;
  contactBoardEntryId: string;
  boardUsers: SharedUser[] | null;
  refetchBoardUsers: () => void;
}) {
  return (
    <CardContent
      sx={{
        height: "100%",
      }}
    >
      <NotesTabContent
        contact={contact}
        contactBoardEntryId={contactBoardEntryId}
        boardUsers={boardUsers}
        refetchBoardUsers={refetchBoardUsers}
      />
    </CardContent>
  );
}

export function NotesTabContent({
  contact,
  contactBoardEntryId,
  boardUsers,
  refetchBoardUsers,
}: {
  contact: ContactFragment;
  contactBoardEntryId: string;
  boardUsers: SharedUser[] | null;
  refetchBoardUsers: () => void;
}) {
  const { data, error, loading, refetch } = useGetNotesQuery({
    variables: {
      contact_id: contact.id,
    },
  });
  const { user } = useUser();

  const firstName = user?.name?.split(" ")[0] || "";
  const lastName = user?.name?.split(" ")[1] || "";
  if (error != null) {
    return <ErrorDisplay message={error.message} onRefetch={refetch} />;
  }

  if (loading) {
    return <Loading />;
  }

  const notes = data!.board_note;
  return (
    <Stack spacing={4} height={"100%"} justifyContent={"space-between"}>
      <Stack spacing={2} height={"100%"} overflow={"auto"}>
        {notes.length != 0 ? (
          notes.map((note) => (
            <NoteCard key={note.id} note={note} boardUsers={boardUsers} />
          ))
        ) : (
          <NoNotes />
        )}
      </Stack>
      <Box
        sx={{
          height: "200px",
        }}
      >
        <LeadingIcon
          icon={
            <Box
              sx={{
                flexGrow: 1,
                mr: 2,
              }}
            >
              <UserProfileImage firstName={firstName} lastName={lastName} />
            </Box>
          }
        >
          <NewNoteField
            contactId={contact.id}
            contactBoardEntryId={contactBoardEntryId}
            boardUsers={boardUsers}
            refetchBoardUsers={refetchBoardUsers}
          />
        </LeadingIcon>
      </Box>
    </Stack>
  );
}
