import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import type { ContactBoardEntryFragment } from "generated/graphql";
import type { ContactToBeRejected } from "contexts/ContactBoardContext";
import { ContactBoardContext } from "contexts/ContactBoardContext";
import Toggle from "../../Toggle";
import { LightTooltip } from "../../LigthTooltip";
import type { DropResult } from "react-beautiful-dnd";
import { DRAGGABLE_TYPE_CONTACT } from "../contactBoardUtils";
import type { CustomDragProps } from "../BoardColumn";

export function DeclineContactButton(props: {
  readonly contactEntry: ContactBoardEntryFragment;
  readonly isDeclined: boolean;
  readonly customDragProps: CustomDragProps;
}) {
  const { contactEntry, isDeclined, customDragProps } = props;

  const { declineColumnDroppableId, setContactToBeRejected } =
    useContext(ContactBoardContext);

  function callRejectContactDragAndDropHandler() {
    const dropResult: DropResult = {
      draggableId: contactEntry.id,
      type: DRAGGABLE_TYPE_CONTACT,
      source: {
        index: customDragProps.source.index,
        droppableId: customDragProps.source.droppableId,
      },
      reason: "DROP",
      mode: "FLUID",
      destination: {
        droppableId: declineColumnDroppableId ?? "",
        index: 0,
      },
      combine: null,
    };

    const updatedContactToBeDeclined: ContactToBeRejected = {
      contactEntry: contactEntry,
      dropResult: dropResult,
    };

    setContactToBeRejected(updatedContactToBeDeclined);
  }

  const button = (
    <Button
      color="darkRed"
      disabled={isDeclined}
      onClick={callRejectContactDragAndDropHandler}
      sx={{
        boxShadow: "none",
        borderRadius: "5px",
        px: 2,
        height: "30px",
      }}
      variant="contained"
    >
      <Typography sx={{ color: "white" }} variant="body2">
        Absagen
      </Typography>
    </Button>
  );

  return (
    <Box>
      <Toggle
        off={button}
        on={
          <LightTooltip title="Es wurde bereits eine Absage versendet">
            {button}
          </LightTooltip>
        }
        showOn={isDeclined}
      />
    </Box>
  );
}
