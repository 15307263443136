import Iconify from "../../Iconify";
import Label from "../../Label";
import type React from "react";
import Box from "@mui/material/Box";

export interface ExternalToolConfig {
  readonly url?: string | null;
  readonly icon?: string;
}

export const MONDAY_ICON = "logos:monday-icon";
const ExternalToolLink: React.FC<
  React.PropsWithChildren<ExternalToolConfig>
> = ({ url, icon, children }) => {
  if (url == null || url === "") {
    return <Box />;
  }
  return (
    <Label
      onClick={() => window.open(url, "blank", "noopener,noreferrer")}
      size="small"
      sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
      variant="ghost"
    >
      {icon && <Iconify icon={icon} />}
      {children}
    </Label>
  );
};

export default ExternalToolLink;
