import type { SortKey } from "./sort-configuration";
import { sortOptions } from "./sort-configuration";

export function getSortDisplayName(sort: SortKey | null) {
  if (sort === null) {
    return "";
  }
  const sortOption = sortOptions.find((s) => s.key === sort);
  if (sortOption === undefined) {
    return "";
  }
  return sortOption.displayText;
}
