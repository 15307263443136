import type React from "react";
import { useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import ContactBoardColumnToolBar from "./ContactBoardColumnToolBar";
import ContactBoardEntryDraggable from "./ContactBoardEntryDraggable";
import type {
  ContactBoardColumnWithEntriesFragment,
  ContactBoardEntryFragment,
} from "../../generated/graphql";
import { Board_Column_Type_Enum } from "../../generated/graphql";
import type { SharedUser } from "./contactBoardUtils";
import { DRAGGABLE_TYPE_CONTACT } from "./contactBoardUtils";
import Loading from "../Loading";
import Toggle from "../Toggle";
import composeRefs from "@seznam/compose-react-refs/composeRefs";
import type { ApplicantPopupState } from "./ContactBoard";
import useSearchParamsState from "../../hooks/useSearchParamsState";
import { getApplicantNameSearch, isApplicantSearchMatch } from "./search";

export default function BoardColumn(props: {
  readonly column: ContactBoardColumnWithEntriesFragment;
  readonly index: number;
  readonly columns: ContactBoardColumnWithEntriesFragment[];
  readonly boardUsers: SharedUser[] | null;
  readonly handleDeleteColumn: (
    deletedColumn: ContactBoardColumnWithEntriesFragment,
    rowForContactsInColumn: ContactBoardColumnWithEntriesFragment | undefined,
  ) => void;
  readonly handleUpdateColumnName: (
    columnToBeUpdated: ContactBoardColumnWithEntriesFragment,
    updatedName: string,
  ) => void;
  readonly refetchBoardUsers: () => void;
  readonly applicantPopupState: ApplicantPopupState | null;
}) {
  const { column, index, columns, applicantPopupState } = props;
  const [waitingForDeletion, setWaitingForDeletion] = useState<boolean>(false);
  const [searchQuery] = useSearchParamsState("query", "" as string);

  // const debouncedSearchQuery = useDeferredValue(searchQuery);

  const applicantSearch = getApplicantNameSearch(searchQuery);

  const filteredEntries = column.contact_board_entries.filter(({ contact }) =>
    isApplicantSearchMatch(applicantSearch, contact),
  );

  const [openMoveContactsToColumnDialog, setOpenMoveContactsToColumnDialog] =
    useState(false);

  const [columnToMoveContacts, setColumnToMoveContacts] = useState<
    ContactBoardColumnWithEntriesFragment | undefined
  >(columns.length > 0 ? columns[0] : undefined);

  const handleDeleteColumnNewColumnSelection = async () => {
    const contactsInColumn = column.contact_board_entries.length > 0;

    if (!contactsInColumn) {
      props.handleDeleteColumn(column, undefined);
    } else {
      setOpenMoveContactsToColumnDialog(true);
    }
  };

  const handleMoveContactsToColumn = async () => {
    setWaitingForDeletion(true);
    await props.handleDeleteColumn(column, columnToMoveContacts);
    setWaitingForDeletion(false);
    handleCloseDeleteColumnDialog();
  };

  const handleCloseDeleteColumnDialog = () => {
    setOpenMoveContactsToColumnDialog(false);
  };

  const isDeclinedColumn = column.type === Board_Column_Type_Enum.Declined;

  const contactsInColumn = column.contact_board_entries.length;

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current == null) return;
    if (applicantPopupState == null) return;
    if (applicantPopupState.column.id !== column.id) return;
    const index = filteredEntries.findIndex(
      (entry) => entry.id === applicantPopupState.applicantBoardEntry.id,
    );
    if (index === -1) return;

    scrollRef.current.children[index].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [applicantPopupState]);

  return (
    <Draggable draggableId={column.id} index={index}>
      {(provided) => (
        <Paper
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{
            bgcolor: "grey.50016",
            p: 2,
            pr: 0,
            borderRadius: 2,
            mb: 2,
            mr: 3,
          }}
        >
          <Stack
            spacing={3}
            {...provided.dragHandleProps}
            sx={{
              height: "100%",
            }}
          >
            <ContactBoardColumnToolBar
              amountOfDisplayedApplicants={filteredEntries.length}
              column={column}
              isDeclinedColumn={isDeclinedColumn}
              onDelete={handleDeleteColumnNewColumnSelection}
              updateColumnName={props.handleUpdateColumnName}
            />

            <Dialog
              aria-describedby="delete-column-with-contacts-dialog-description"
              aria-labelledby="delete-column-with-contacts-dialog-title"
              onClose={() => setOpenMoveContactsToColumnDialog(false)}
              open={openMoveContactsToColumnDialog}
            >
              <DialogTitle id="delete-column-with-contacts-dialog-title">
                Spalte "{column.name}" mit {contactsInColumn}{" "}
                {contactsInColumn !== 1 ? "Kontakten" : " Kontakt"} löschen?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="delete-column-with-contacts-dialog-description">
                  Bitte angeben in welche Spalte die Kontakte aus Spalte "
                  {column.name}" verschoben werden sollen:
                  <TextField
                    SelectProps={{ native: true }}
                    disabled={waitingForDeletion}
                    fullWidth
                    label="Verschieben in Spalte"
                    onChange={(event) => {
                      const newColumnId = event.target.value;
                      const newColumn = columns.find(
                        (boardColumn) => boardColumn.id === newColumnId,
                      );
                      if (newColumn === undefined) {
                        console.error(
                          `Cannot find column with id ${newColumnId}`,
                        );
                      }
                      setColumnToMoveContacts(newColumn);
                    }}
                    placeholder="Verschieben in Spalte"
                    select
                    sx={{ flexShrink: 2, mt: 3, mb: 2 }}
                    value={columnToMoveContacts?.id}
                  >
                    {columns
                      .filter(
                        (boardColumn) =>
                          boardColumn.id !== column.id &&
                          //   Moving to declined column is not allowed for now as sending out the mails would need to be handled
                          boardColumn.type !== Board_Column_Type_Enum.Declined,
                      )
                      .map((boardColumn) => {
                        return (
                          <option key={boardColumn.id} value={boardColumn.id}>
                            {boardColumn.name}
                          </option>
                        );
                      })}
                  </TextField>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Toggle
                  off={
                    <>
                      {" "}
                      <Button
                        disabled={waitingForDeletion}
                        onClick={handleCloseDeleteColumnDialog}
                      >
                        Abbrechen
                      </Button>
                      <Button
                        color="error"
                        disabled={waitingForDeletion}
                        onClick={handleMoveContactsToColumn}
                        sx={{ textTransform: "none" }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Kontakte verschieben & Spalte löschen
                          {waitingForDeletion ? (
                            <Box sx={{ pl: 1 }}>
                              <Loading />
                            </Box>
                          ) : null}
                        </Box>
                      </Button>
                    </>
                  }
                  on={<Loading sx={{ p: 0 }} />}
                  showOn={waitingForDeletion}
                />
              </DialogActions>
            </Dialog>

            <Droppable droppableId={column.id} type={DRAGGABLE_TYPE_CONTACT}>
              {(provided) => (
                <Stack
                  ref={
                    // https://github.com/atlassian/react-beautiful-dnd/issues/1873
                    composeRefs(
                      provided.innerRef,
                      scrollRef,
                    ) as React.RefObject<HTMLDivElement>
                  }
                  {...provided.droppableProps}
                  minWidth="280px"
                  spacing={1}
                  sx={{ overflowY: "auto", minHeight: 0, flexGrow: 1 }}
                >
                  {column.contact_board_entries
                    .filter(({ contact }) =>
                      isApplicantSearchMatch(applicantSearch, contact),
                    )
                    .map(
                      (
                        contactBoardEntry: ContactBoardEntryFragment,
                        index: number,
                      ) => (
                        <ContactBoardEntryDraggable
                          contactBoardEntry={contactBoardEntry}
                          index={index}
                          isDeclined={isDeclinedColumn}
                          key={`contact-kanban-card-${contactBoardEntry.id}`}
                        />
                      ),
                    )}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </Stack>
        </Paper>
      )}
    </Draggable>
  );
}

export type CustomDragProps = {
  source: {
    droppableId: string;
    index: number;
  };
};
