import { CampaignFragment } from "../generated/graphql";

export const CampaignStatus = {
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  FINISHED: "FINISHED",
} as const;

export type CampaignStatusType =
  (typeof CampaignStatus)[keyof typeof CampaignStatus];

export function getCampaignStatus(
  campaign: CampaignFragment,
): CampaignStatusType | null {
  if (campaign.status == null) return null;
  return campaign.status as CampaignStatusType;
}

export function toCampaignStatus(
  status: string | null | undefined,
): CampaignStatusType {
  if (!status) return CampaignStatus.FINISHED;
  return status as CampaignStatusType;
}
