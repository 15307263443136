import type { ApolloError } from "@apollo/client";
import { NoAccountsRounded } from "@mui/icons-material";
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import ContactBoard from "components/contact_board/ContactBoard";
import type { Permission } from "contexts/UserContext";
import type { HukGsDetailsFragment } from "generated/graphql";
import { useUser } from "hooks/useUser";
import type React from "react";
import { useEffect } from "react";
import ErrorDisplay from "../../ErrorDisplay";
import Iconify from "../../Iconify";
import { GsInsightsDisplay } from "./GsInsightsDisplay";
import useSearchParamsState from "../../../hooks/useSearchParamsState";

const _tabs = {
  insights: "",
  board: "",
  budget: "",
  ads: "",
  funnel: "",
  emails: "",
};

type TAB_KEY = keyof typeof _tabs;

function isTabKey(key: string): key is TAB_KEY {
  return key in _tabs;
}

interface CustomTab {
  value: TAB_KEY;
  icon: React.ReactElement;
  label: string;
  component: React.ReactNode;
  requiresPermission?: Permission;
}

interface GsDetailContentProps {
  readonly gs: HukGsDetailsFragment | null | undefined;
  readonly error: ApolloError | undefined;
  readonly onRefetch: () => void;
  readonly setIsPageScrollable: (isScrollable: boolean) => void;
}

export function GsDetailContent({
  gs,
  error,
  onRefetch,
  setIsPageScrollable,
}: GsDetailContentProps) {
  const [maybeTab, setTab] = useSearchParamsState("tab", "insights" as TAB_KEY);
  let currentTab: TAB_KEY = "insights";
  if (isTabKey(maybeTab)) {
    currentTab = maybeTab;
  }

  useEffect(() => {
    setIsPageScrollable(currentTab !== "board" && currentTab !== "ads");
  }, [currentTab]);

  const { hasPermission } = useUser();

  if (error != null) {
    return <ErrorDisplay onRefetch={onRefetch} />;
  }
  if (gs == null) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Stack alignItems="center" gap={3}>
          <NoAccountsRounded sx={{ fontSize: 100 }} />
          <Typography variant="h4">Kampagne nicht gefunden</Typography>
        </Stack>
      </Box>
    );
  }

  const CAMPAIGN_DETAILS_TABS: CustomTab[] = [
    {
      value: "insights",
      label: "Insights",
      icon: <Iconify height={20} icon="mdi:graph-box" width={20} />,
      component: <GsInsightsDisplay gs={gs} />,
    },
    {
      value: "board",
      label: "Bewerber",
      icon: <Iconify height={20} icon="ic:round-account-box" width={20} />,
      component: <ContactBoard campaign={gs} showOriginCampaign />,
      requiresPermission: "read:contact_board",
    },
  ];

  const permittedTabs = CAMPAIGN_DETAILS_TABS.filter((tab) => {
    if (tab.requiresPermission) {
      return hasPermission(tab.requiresPermission);
    }
    return true;
  });
  const activeTab = permittedTabs.find((tab) => {
    return tab.value === currentTab;
  });
  useEffect(() => {
    if (activeTab != null) return;
    setTab("insights");
  }, [activeTab]);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        mt: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mb={3}
      >
        <Tabs
          allowScrollButtonsMobile
          onChange={(e, value) => setTab(value)}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
        >
          {permittedTabs.map((tab) => (
            <Tab
              disableRipple
              icon={tab.icon}
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Stack>

      {activeTab == null ? <div /> : activeTab.component}
    </Container>
  );
}
