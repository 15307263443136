import { useEffect, useState } from "react";

export type SearchDebounce = {
  search: string | null;
  debouncedSearch: string | null;
};

export function useSearchDebounce(
  delay = 350,
): [SearchDebounce, (value: string | null) => void] {
  const [debouncedSearch, setDebouncedSearch] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedSearch(search), delay);
    return () => clearTimeout(handler);
  }, [search, delay]);

  function updateSearchQuery(value: string | null): void {
    if (value === "") {
      value = null;
    }
    setSearch(value);
  }

  return [{ search, debouncedSearch }, updateSearchQuery];
}
