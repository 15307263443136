import React, { useState } from "react";
import { Stack } from "@mui/material";
import { LocationFragment, RegionFragment } from "../generated/graphql";
import { LocationDropdown } from "./LocationDropdown";
import { RegionDropdown } from "./RegionDropdown";

const ImpersonationRegionAndLocationDropdown = ({
  regions,
  onRegionSelected,
  onLocationSelected,
  initialLocation,
  initialRegion,
}: {
  regions: RegionFragment[];
  onRegionSelected: (region: RegionFragment | null) => void;
  onLocationSelected: (location: LocationFragment | null) => void;
  initialRegion?: string | null;
  initialLocation?: string | null;
}) => {
  const [selectedRegion, setSelectedRegion] = useState<RegionFragment | null>(
    initialRegion
      ? regions.find((r) => r.slug === initialRegion) ?? null
      : null,
  );
  const [selectedLocation, setSelectedLocation] =
    useState<LocationFragment | null>(
      initialLocation
        ? selectedRegion?.locations.find((l) => l.slug === initialLocation) ??
            null
        : null,
    );

  const locations = selectedRegion?.locations ?? [];

  return (
    <Stack gap={2}>
      <RegionDropdown
        options={regions}
        onChange={(value) => {
          const region = value as RegionFragment | null;
          setSelectedRegion(region);
          setSelectedLocation(null);
          onRegionSelected(region);
        }}
        value={selectedRegion}
      />
      {selectedRegion && (
        <LocationDropdown
          options={locations}
          onChange={(value) => {
            const location = value as LocationFragment | null;
            setSelectedLocation(location);
            onLocationSelected(location);
          }}
          value={selectedLocation}
        />
      )}
    </Stack>
  );
};

export default ImpersonationRegionAndLocationDropdown;
