import { CB_DARK_GREEN_40, CB_GRAY } from "../../../../colors";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { formatDate } from "../../../../shared/utils";
import { useTheme } from "@mui/material/styles";
import type moment from "moment";

const paddingX = 3;

export type ChatMessage = {
  id: string;
  text: string;
  sentAt: moment.Moment;
} & (
  | {
      isSentByApplicant: true;
      applicantFullName: string;
    }
  | {
      isSentByApplicant: false;
    }
);

export function ChatMessageTile({
  message,
  previousMessage,
}: {
  readonly message: ChatMessage;
  readonly previousMessage: ChatMessage | undefined;
}) {
  const color = message.isSentByApplicant ? CB_DARK_GREEN_40 : CB_GRAY;
  const widthInPercent = 80;
  const theme = useTheme();
  const darkGray = theme.palette.grey[700];
  return (
    <Stack
      alignItems="center"
      direction={message.isSentByApplicant ? "row" : "row-reverse"}
      spacing={2}
    >
      <Box
        sx={{
          width: 100 - widthInPercent + "%",
        }}
      />
      <Stack
        gap={0.5}
        sx={{
          width: widthInPercent + "%",
        }}
      >
        {message.isSentByApplicant &&
        (previousMessage === undefined ||
          !previousMessage.isSentByApplicant) ? (
          <ApplicantNameHeader
            applicantFullName={message.applicantFullName}
            color={darkGray}
          />
        ) : null}
        <Card
          sx={{
            backgroundColor: color,
            borderRadius: 3,
          }}
        >
          <CardContent
            sx={{
              pt: 2,
              px: paddingX,
              "&:last-child": {
                pb: 1,
              },
            }}
          >
            <Stack gap={0.2}>
              <Typography
                sx={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {message.text}
              </Typography>
              <MessageSentAt sentAt={message.sentAt} />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
}

function ApplicantNameHeader({
  color,
  applicantFullName,
}: {
  readonly color: string;
  readonly applicantFullName: string;
}) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        mr: paddingX,
      }}
    >
      <Typography
        color={color}
        sx={{
          fontWeight: "bolder",
        }}
      >
        {applicantFullName}
      </Typography>
    </Stack>
  );
}

function MessageSentAt({ sentAt }: { readonly sentAt: moment.Moment }) {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <Typography color="text.secondary" variant="caption">
        {formatDate(sentAt)}
      </Typography>
    </Stack>
  );
}
