import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import "moment/locale/de";
import { LeadingIcon } from "./LeadingIcon";
import type { ContactBoardEntryFragment } from "../../generated/graphql";
import { formatDate } from "./shared/utils";
import { getContactScoreColor } from "./shared/ContactScoreCircle";
import { UserProfileImage } from "./shared/UserProfileImage";
import { merge } from "lodash";
import { theme } from "../../theme/theme";
import useSearchParamsState from "../../hooks/useSearchParamsState";

export function ContactBoardCard({
  contactBoardEntry,
  isDeclined,
}: {
  readonly contactBoardEntry: ContactBoardEntryFragment;
  readonly isDeclined: boolean;
}) {
  const contactScore = 75; // TODO exchange with actual score from contact
  const [_, setApplicantId] = useSearchParamsState("applicant_id");
  return (
    <Card
      onClick={() => {
        const applicantId = contactBoardEntry.contact?.id;
        setApplicantId(applicantId);
      }}
      sx={{
        ...getCardStyle(isDeclined, contactScore),
        mb: 1,
        mr: 2,
        w: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={3}
        >
          <LeadingIcon
            icon={
              <UserProfileImage
                firstName={contactBoardEntry.contact?.first_name ?? "-"}
                isDeclined={isDeclined}
                lastName={contactBoardEntry.contact?.last_name ?? "-"}
                size={40}
              />
            }
          />
        </Grid>
        <Grid item xs={9}>
          <Stack>
            <Stack direction="row">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">{`${contactBoardEntry.contact?.first_name} ${contactBoardEntry.contact?.last_name}`}</Typography>
                {/*TODO add on implementation of https://app.clickup.com/t/863h4m79c -> aswell in ContactPopupHeader */}
                {/*<PinContactButton onClick={() => {}} />*/}
              </Box>
            </Stack>
            <Stack alignItems="center" direction="row" gap={5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography color="text.secondary" variant="body2">
                  {formatDate(
                    contactBoardEntry.contact?.converted_at ?? undefined,
                  )}
                </Typography>
                {/*TODO re add once calculation of contact score is defined -> aswell in ContactPopupHeader */}
                {/*<ContactScoreCircle sx={{ ml: 2 }} status={75} />*/}
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

function getCardStyle(isDeclined: boolean, contactScore: number) {
  const declinedBorderStyle = {
    borderStyle: "solid",
    borderWidth: 1,
    //@ts-ignore
    borderColor: theme.palette.cbRed.main,
  };

  const scoreBorderStyle = {
    border: "none",
    borderLeft: "solid",
    borderWidth: 5,
    borderColor: getContactScoreColor(contactScore),
  };

  let appliedBorderStyle;
  if (isDeclined) {
    appliedBorderStyle = declinedBorderStyle;
  } else {
    appliedBorderStyle = scoreBorderStyle;
  }

  const sxProps = merge(
    {
      padding: "1rem",
    },
    appliedBorderStyle,
  );

  return sxProps;
}
