import moment from "moment/moment";
import { calculateDiffInDays } from "./CampaignDetailsUtil";
import { clamp } from "lodash";
import { CampaignFragment } from "generated/graphql";

export interface CampaignTimeMetrics {
  daysElapsed: number;
  daysTotal: number;
  daysElapsedPercentage: number;
  campaignHasNotStarted: boolean;
  isCampaignOver: boolean;
  campaignHasDefinedEnd: boolean;
  daysLeft: number;
  duration: number;
  formattedStartDate?: string;
  formattedEndDate?: string;
  preciseElapsedPercentage: number;
}

const DATE_FORMAT = "DD.MM.YYYY";

export const getCampaignTimeMetrics = (
  campaign: CampaignFragment,
): CampaignTimeMetrics => {
  return calculateCampaignTimeMetrics(campaign.start, campaign.end);
};

export function calculateCampaignTimeMetrics(
  start: string | null | undefined,
  end: string | null | undefined,
): CampaignTimeMetrics {
  const isDaysLeftDefined = start != null && end != null;

  let daysElapsed = 0;
  let daysTotal = 0;
  let daysElapsedPercentage = 0;
  let campaignHasNotStarted = true;
  let isCampaignOver = false;
  const campaignHasDefinedEnd = end != null;
  let daysLeft = 0;
  let preciseElapsedPercentage = 0;

  if (isDaysLeftDefined) {
    const campaignStart = moment(start);
    const campaignEnd = moment(end);

    const today = moment(Date.now());
    campaignHasNotStarted = today.isBefore(campaignStart);

    isCampaignOver = today.isAfter(campaignEnd);
    daysTotal = calculateDiffInDays(campaignStart, campaignEnd);
    daysElapsed = calculateDiffInDays(today, campaignStart, false);
    const minutesTotal = daysTotal * 24 * 60;

    daysLeft = daysTotal - daysElapsed;
    if (campaignHasNotStarted || !campaignHasDefinedEnd) {
      daysElapsedPercentage = 0;
      preciseElapsedPercentage = 0;
    } else if (isCampaignOver) {
      daysElapsedPercentage = 100;
      preciseElapsedPercentage = 100;
    } else {
      daysElapsedPercentage = clamp((daysElapsed / daysTotal) * 100, 0, 100);

      preciseElapsedPercentage = clamp(
        (today.diff(campaignStart, "minutes") / minutesTotal) * 100,
        0,
        100,
      );
    }
  }
  let duration;
  if (!campaignHasDefinedEnd) {
    duration = daysElapsed;
  } else if (campaignHasNotStarted || isCampaignOver) {
    duration = daysTotal;
  } else {
    duration = daysLeft;
  }
  const formattedStartDate =
    start != null ? moment(start).format(DATE_FORMAT) : undefined;
  const formattedEndDate =
    end != null ? moment(end).format(DATE_FORMAT) : undefined;
  return {
    daysElapsed,
    daysTotal,
    daysElapsedPercentage,
    campaignHasNotStarted,
    isCampaignOver,
    campaignHasDefinedEnd,
    daysLeft,
    duration,
    formattedStartDate,
    formattedEndDate,
    preciseElapsedPercentage,
  };
}

export default getCampaignTimeMetrics;
