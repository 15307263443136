import type { MutationUpdaterFn } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import type {
  GetNotesQuery,
  InsertNoteMutation,
} from "../../../../../../generated/graphql";
import {
  GetNotesDocument,
  NoteFragmentDoc,
  useInsertNoteMutation,
} from "../../../../../../generated/graphql";
import { useUser } from "../../../../../../hooks/useUser";
import Iconify from "../../../../../Iconify";
import type { SharedUser } from "../../../../contactBoardUtils";

const NewNoteSchema = Yup.object().shape({
  content: Yup.string().required("Notiz darf nicht leer sein"),
});

type NewNote = Yup.InferType<typeof NewNoteSchema>;

export function NewNoteField({
  contactId,
  contactBoardEntryId,
  boardUsers,
  refetchBoardUsers,
}: {
  readonly contactId: string;
  readonly contactBoardEntryId: string;
  readonly boardUsers: SharedUser[] | null;
  readonly refetchBoardUsers: () => void;
}) {
  const [insertNote, { loading }] = useInsertNoteMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();

  const updateCache: MutationUpdaterFn<InsertNoteMutation> = (
    cache,
    { data },
  ) => {
    const newNote = data?.insert_board_note_one;
    if (newNote != null) {
      const existingNotes = cache.readQuery<GetNotesQuery>({
        query: GetNotesDocument,
        variables: { contact_id: contactId },
      });

      cache.writeQuery({
        query: GetNotesDocument,
        variables: { contact_id: contactId },
        data: {
          board_note: [newNote, ...(existingNotes?.board_note ?? [])],
        },
      });
    }
  };

  async function onSubmit(values: NewNote) {
    try {
      await insertNote({
        variables: {
          input: {
            content: values.content,
            author_id: user?.id,
            contact_id: contactId,
            contact_board_entry_id: contactBoardEntryId,
          },
        },
        update: updateCache,
      });
      formik.resetForm();

      const unknownUserAdded =
        boardUsers?.find(
          (loadedBoardUser) => loadedBoardUser.id === user?.id,
        ) === undefined;
      if (unknownUserAdded) {
        refetchBoardUsers();
      }
    } catch (e) {
      enqueueSnackbar("Notiz konnte nicht hinzugefügt werden.", {
        variant: "error",
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      content: "",
    },
    onSubmit: onSubmit,
    validationSchema: NewNoteSchema,
  });

  return (
    <Card
      sx={{
        width: "100%",
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <TextField
            InputProps={{
              disableUnderline: true, // <== added this
            }}
            fullWidth
            maxRows={4}
            multiline
            placeholder="Neue Notiz"
            variant="standard"
            {...formik.getFieldProps("content")}
            error={formik.submitCount != 0 && Boolean(formik.errors.content)}
            helperText={formik.submitCount != 0 && formik.errors.content}
          />
          <Stack alignItems="center" direction="row" justifyContent="flex-end">
            <LoadingButton
              loading={loading}
              loadingIndicator={
                <Iconify icon="heroicons:check" sx={{ color: "white" }} />
              }
              onClick={() => formik.submitForm()}
              variant="contained"
            >
              Senden
            </LoadingButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
