import type { DeepPartial } from "types";
import type { Ad_Platform_Enum, CampaignFragment } from "generated/graphql";

export type PlatformKPIs = {
  impressions: number;
  reach: number | undefined;
  clicks: number;
  spent: number;
};

export function calculateKpisForPlatform(
  campaign: DeepPartial<CampaignFragment>,
  platform: Ad_Platform_Enum | null,
): PlatformKPIs {
  let adCampaignMetrics = campaign.ad_campaign_metrics ?? [];
  if (platform != null) {
    adCampaignMetrics = (campaign.ad_campaign_metrics ?? []).filter(
      (metric: any) => metric.platform === platform,
    );
  }
  return (adCampaignMetrics ?? []).reduce<PlatformKPIs>(
    (acc, current) => {
      return {
        impressions: acc.impressions + (current?.impressions ?? 0),
        reach: (acc.reach ?? 0) + (current?.reach ?? 0),
        clicks: acc.clicks + (current?.clicks ?? 0),
        spent: acc.spent + (current?.spend ?? 0),
      };
    },
    { impressions: 0, reach: 0, clicks: 0, spent: 0 },
  );
}
