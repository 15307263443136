import React, { ElementType, Fragment } from "react";
import { NavLink as RouterLink } from "react-router-dom";

import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Iconify from "../Iconify";
import { NavItemProps } from "./type";
import { NavItemCollapsedChildrenMenu } from "./NavItemCollapsedChildrenMenu";

const DASHBOARD_NAVBAR_ROOT_ITEM_HEIGHT = 48,
  DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT = 40,
  DASHBOARD_NAVBAR_ICON_ITEM_SIZE = 22;

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ElementType<any>;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  isCollapse?: boolean;
}

const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop: string) =>
    !["activeRoot", "activeSub", "subItem", "isCollapse"].includes(prop),
})<ListItemStyleProps>(
  ({ activeRoot, activeSub, subItem, theme, isCollapse }) => ({
    ...theme.typography.body2,
    position: "relative",
    height: DASHBOARD_NAVBAR_ROOT_ITEM_HEIGHT,
    textTransform: "capitalize",
    paddingLeft: theme.spacing(isCollapse ? 1 : 2),
    paddingRight: theme.spacing(isCollapse ? 1 : 1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    // activeRoot
    ...(activeRoot && {
      ...theme.typography.subtitle2,
      color: theme.palette.primary.main,
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity,
      ),
    }),
    // activeSub
    ...(activeSub && {
      ...theme.typography.subtitle2,
      color: theme.palette.text.primary,
    }),
    // subItem
    ...(subItem && {
      height: DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT,
    }),
    ...(isCollapse && {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  }),
);

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
}

const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "isCollapse",
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  whiteSpace: "nowrap",
  transition: theme.transitions.create(["width", "opacity"], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: DASHBOARD_NAVBAR_ICON_ITEM_SIZE,
  height: DASHBOARD_NAVBAR_ICON_ITEM_SIZE,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": { width: "100%", height: "100%" },
});

// ----------------------------------------------------------------------

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
  setOpen,
  onClick,
}: NavItemProps) {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { title, path, icon, info, children } = item;

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onClick != undefined) {
      e.preventDefault();
      onClick();
    }
  }

  const renderContent = isCollapse ? (
    icon && (
      <ListItemIconStyle sx={{ marginRight: 0, position: "relative" }}>
        {icon}{" "}
        {children && (
          <Iconify
            icon={"heroicons:chevron-right"}
            sx={{
              position: "absolute",
              left: "100%",
              height: "18px !important",
            }}
          />
        )}
      </ListItemIconStyle>
    )
  ) : (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={title}
        isCollapse={isCollapse}
      />
      {
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      }
    </>
  );

  if (children) {
    return (
      <Fragment>
        <ListItemStyle
          onClick={
            isCollapse
              ? () => {
                  setOpen?.(true);
                }
              : onOpen
          }
          activeRoot={active}
          isCollapse={isCollapse}
        >
          {renderContent}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: "120%",
              width: "100%",
            }}
            ref={anchorRef}
          />
        </ListItemStyle>

        {isCollapse && children && (
          <NavItemCollapsedChildrenMenu
            item={item}
            onClose={() => {
              setOpen?.(false);
            }}
            open={open}
            anchorEl={anchorRef.current}
          />
        )}
      </Fragment>
    );
  }

  return (
    <ListItemStyle
      isCollapse={isCollapse}
      component={RouterLink as any}
      to={path}
      onClick={handleClick}
      activeRoot={active}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, "isCollapse">;

export function NavItemSub({
  item,
  open = false,
  active,
  onOpen,
}: NavItemSubProps) {
  const { title, path, info, children } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle
      key={title}
      component={RouterLink as any}
      to={path}
      activeSub={active}
      subItem
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "text.disabled",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: "scale(2)",
            bgcolor: "primary.main",
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
