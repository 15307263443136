import type { Filter, Section } from "./filter-configuration";
import { CampaignFilterKey } from "./filter-configuration";
import { Box, Checkbox, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFilter } from "../../FilterContext";

function CheckBoxFilterComponent<Key extends string>(props: {
  readonly checked: boolean;
  readonly toggleFilter: (filter: Key) => void;
  readonly filter: Filter<Key>;
  readonly section: Section<Filter<Key>>;
}) {
  const { filter, toggleFilter } = props;
  const { activeFilters } = useFilter();
  let isParentItemInSectionSelected = false;
  for (const sectionFilter of props.section.filters) {
    if (sectionFilter.isParent) {
      isParentItemInSectionSelected = activeFilters.has(sectionFilter.key);
    }
  }
  const theme = useTheme();
  const disabledColor = theme.palette.text.disabled;
  const disabled = isParentItemInSectionSelected && !filter.isParent;
  return (
    <Stack alignItems="center" direction="row" gap={0.3}>
      <Checkbox
        checked={props.checked || isParentItemInSectionSelected}
        disabled={disabled}
        onChange={() => {
          if (filter.isParent) {
            for (const sectionFilter of props.section.filters) {
              toggleFilter(sectionFilter.key);
            }
          } else {
            toggleFilter(filter.key);
          }
        }}
      />
      <Typography color={disabled ? disabledColor : undefined}>
        {filter.displayText}
      </Typography>
    </Stack>
  );
}

export function FilterDrawerItem<Key extends string>(props: {
  readonly filter: Filter<Key>;
  readonly active: boolean;
  readonly toggleFilter: (filter: Key) => void;
  readonly setCustomFilterValues: (filter: Key, values: any) => void;
  readonly values: any[];
  readonly section: Section<Filter<Key>>;
}) {
  const { filter, active, toggleFilter } = props;
  const isCustomFilter = filter.options?.type === "custom";
  const isCheckBoxFilter = !isCustomFilter;
  return isCheckBoxFilter ? (
    <CheckBoxFilterComponent
      checked={active}
      filter={filter}
      section={props.section}
      toggleFilter={toggleFilter}
    />
  ) : (
    <Box
      sx={{
        mt: 1,
      }}
    >
      {filter.options!.buildComponent!({
        setValues: (values) => {
          props.setCustomFilterValues(filter.key, values);
        },
        values: props.values,
      })}
    </Box>
  );
}
