import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { IconButton } from "@mui/material";
import Iconify from "../Iconify";
import { LightTooltip } from "../LigthTooltip";

export type Size = "small" | "medium" | "large";
export function ToFunnelButton({
  perspectiveCampaignURL,
  size,
  iconFontSize,
  color,
}: {
  readonly perspectiveCampaignURL: string;
  readonly size?: Size;
  readonly iconFontSize?: number;
  readonly color?: string;
}) {
  const { isNotCustomer } = useContext(UserContext);

  return (
    <LightTooltip title={isNotCustomer ? "Zum Funnel" : "Zur Kampagne"}>
      <IconButton
        color="primary"
        onClick={() =>
          window.open(perspectiveCampaignURL, "blank", "noopener,noreferrer")
        }
        size={size}
      >
        <Iconify
          color={color}
          fontSize={iconFontSize}
          icon="heroicons:arrow-top-right-on-square"
        />
      </IconButton>
    </LightTooltip>
  );
}
