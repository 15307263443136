import { PropsWithChildren } from "react";
import { ConfirmOptions, ConfirmProvider } from "material-ui-confirm";

const options: ConfirmOptions = {
  title: "Bist du sicher?",
  cancellationText: "Abbrechen",
  confirmationText: "OK",
  dialogProps: {
    maxWidth: "xs",
  },
  confirmationButtonProps: {
    color: "darkRed",
  },
};

export function ConfirmationDialogProvider(props: PropsWithChildren<any>) {
  return (
    <ConfirmProvider defaultOptions={options}>{props.children}</ConfirmProvider>
  );
}
