import { useMemo } from "react";
import type { Environment } from "./useEnvironment";
import useEnvironment, { environments } from "./useEnvironment";

export type FeatureFlag =
  | "funnel"
  | "applicantBoard"
  | "quotations"
  | "ads"
  | "dashboard"
  | "hubspot"
  | "maintenance";

export type Features = {
  [K in FeatureFlag]: readonly Environment[];
};

const features: Features = {
  funnel: ["development", "staging"],
  applicantBoard: ["development", "staging", "production"],
  quotations: environments,
  ads: environments,
  dashboard: ["development", "staging"],
  hubspot: ["development", "staging"],
  maintenance: ["development", "staging"],
};

export function useFeatureFlags() {
  const environment = useEnvironment();
  const featureFlags: Record<FeatureFlag, boolean> = useMemo(
    () =>
      (Object.keys(features) as FeatureFlag[]).reduce(
        (acc, featureKey) => {
          const enabledInCurrentEnv =
            features[featureKey].includes(environment);
          return {
            ...acc,
            [featureKey]: enabledInCurrentEnv,
          };
        },
        {} as Record<FeatureFlag, boolean>,
      ),
    [environment],
  );
  return featureFlags;
}

export default function useFeatureFlag(feature: FeatureFlag) {
  const featureFlags = useFeatureFlags();
  return {
    enabled: featureFlags[feature],
  };
}
