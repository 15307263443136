import { InputAdornment, OutlinedInput, Stack, Container } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { SomethingNotFoundDisplay } from "components/campaigns/SomethingNotFoundDisplay";
import { ApplicantRejectionDialog } from "components/contact_board/ApplicantRejectionDialog";
import * as MailTemplates from "components/mails/mail-templates";
import type { ContactToBeRejected } from "contexts/ContactBoardContext";
import { ContactBoardContext } from "contexts/ContactBoardContext";
import type {
  Board_Contact_Board_Column_Insert_Input,
  CampaignDetailsFragment,
  ContactBoardEntryFragment,
  GetContactBoardColumnsWithEntriesSubscription,
  HukGsDetailsFragment,
} from "generated/graphql";
import {
  Board_Column_Type_Enum,
  ContactBoardColumnFragmentDoc,
  ContactBoardEntryFragmentDoc,
  UpsertContactBoardColumnsDocument,
  UpsertContactBoardEntriesMutationDocument,
  useDeleteContactBoardColumnByIdMutation,
  useGetContactBoardColumnsWithEntriesPolledQuery,
  useGetContactBoardColumnsWithEntriesSubscription,
  useUpsertContactBoardColumnsMutation,
  useUpsertContactBoardEntriesMutationMutation,
} from "generated/graphql";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import type { DropResult } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector } from "store";
import useContactBoardUsers from "../../hooks/useContactBoardUsers";
import useSearchParamsState from "../../hooks/useSearchParamsState";
import Iconify from "../Iconify";
import { cloneObject } from "../ProgressCircle";
import AddContactColumn from "./AddContactColumn";
import BoardColumn from "./BoardColumn";
import ApplicantExportButton from "components/contact_board/ApplicantExportButton";
import {
  calculateUpdatedContactBoardEntries,
  convertContactBoardEntriesToUpsertVariables,
  DRAGGABLE_TYPE_CONTACT,
  updateColumnEntryPositions,
} from "./contactBoardUtils";
import { ContactPopup } from "./popup/ContactPopup";
import { getApplicantNameSearch, isApplicantSearchMatch } from "./search";
import SkeletonBoardColumn from "./SkeletonBoardColumn";
import { StrictModeDroppable } from "./StrictModeDroppable";

export type ContactBoardColumnWithEntriesFragment =
  GetContactBoardColumnsWithEntriesSubscription["board_contact_board_column"][number];

export type ApplicantPopupState = {
  applicantBoardEntry: ContactBoardEntryFragment;
  column: ContactBoardColumnWithEntriesFragment;
};

/** Inspired by https://minimals.cc/dashboard/kanban*/
export default function ContactBoard({
  campaign,
  showOriginCampaign = false,
}: {
  readonly campaign: CampaignDetailsFragment | HukGsDetailsFragment;
  readonly showOriginCampaign?: boolean;
}) {
  const [displayedColumns, setDisplayedColumns] = useState<
    ContactBoardColumnWithEntriesFragment[] | undefined
  >(undefined);

  const [displayedContactBoardId, setDisplayedContactBoardId] = useState<
    string | undefined
  >(undefined);

  const websocketsSupported = useSelector((state) => state.websockets);

  const rejectionTemplate =
    campaign.email_template_overwrite?.rejection ??
    (campaign.organization.settings?.address_formally
      ? MailTemplates.REJECTION_FORMAL
      : MailTemplates.REJECTION_INFORMAL);

  const {
    setDeclineColumnDroppableId,
    setContactToBeRejected,
    contactToBeRejected,
  } = useContext(ContactBoardContext);

  const [searchQueryParams, setSearchParamsQuery] = useSearchParamsState(
    "query",
    "" as string,
  );

  const search = getApplicantNameSearch(searchQueryParams);

  function setSearchQuery(query: string) {
    setSearchParamsQuery(query);
  }

  const { data: unfilteredSubscribedColumns, loading: subscriptionLoading } =
    useGetContactBoardColumnsWithEntriesSubscription({
      variables: {
        campaign_id: campaign.id,
      },
      skip: !websocketsSupported,
    });

  const { data: unfilteredPolledColumns, loading: polledLoading } =
    useGetContactBoardColumnsWithEntriesPolledQuery({
      variables: { campaign_id: campaign.id },
      skip: websocketsSupported,
      pollInterval: 10000,
    });

  const unfilteredColumns = websocketsSupported
    ? unfilteredSubscribedColumns
    : unfilteredPolledColumns;

  const loading: boolean = websocketsSupported
    ? subscriptionLoading
    : polledLoading;

  const { data: boardUsers, refetch: refetchBoardUsers } = useContactBoardUsers(
    displayedContactBoardId,
    campaign.id,
  );

  const [applicantId, setApplicantId] = useSearchParamsState("applicant_id");

  function getApplicantPopupState(): ApplicantPopupState | null {
    if (applicantId == null || displayedColumns == null) {
      return null;
    }
    let column: ContactBoardColumnWithEntriesFragment | undefined;
    let applicantBoardEntry: ContactBoardEntryFragment | undefined;
    for (column of displayedColumns) {
      applicantBoardEntry = column.contact_board_entries.find(
        (entry, index) => {
          return entry.contact?.id === applicantId;
        },
      );
      if (applicantBoardEntry != null) {
        break;
      }
    }
    if (applicantBoardEntry != null && column != null) {
      return { applicantBoardEntry, column };
    }
    return null;
  }

  const applicantPopupState = getApplicantPopupState();

  const { enqueueSnackbar } = useSnackbar();

  const [upsertContactBoardEntries, { loading: upsertingBoardEntries }] =
    useUpsertContactBoardEntriesMutationMutation();

  const [upsertContactBoardColumns, { loading: upsertingColumns }] =
    useUpsertContactBoardColumnsMutation();

  const [deleteColumnMutation, { loading: deletingColumns }] =
    useDeleteContactBoardColumnByIdMutation();

  const columnsFound =
    (unfilteredColumns?.board_contact_board_column.length ?? 0) > 0;

  useEffect(
    function onlyDisplayColumnsOfOneBoardIfMultipleFoundForCampaign() {
      const waitingWithRemoteUpdatesUntilMutationsAreFinished =
        upsertingBoardEntries || upsertingColumns || deletingColumns;

      if (columnsFound && !waitingWithRemoteUpdatesUntilMutationsAreFinished) {
        const displayedBoardId =
          unfilteredColumns!.board_contact_board_column[0].board_id;

        const displayedColumns =
          unfilteredColumns!.board_contact_board_column.filter(
            (column) => column.board_id === displayedBoardId,
          );

        const multipleBoardsForCampaignExist =
          displayedColumns.length <
          unfilteredColumns!.board_contact_board_column.length;
        if (multipleBoardsForCampaignExist) {
          console.warn(
            "Multiple contact boards found, using the first found board",
          );
        }

        setDisplayedColumns(displayedColumns);
      }
    },
    [unfilteredColumns],
  );

  useEffect(() => {
    setDisplayedContactBoardId(displayedColumns?.[0]?.board_id);

    const updatedDeclineColumDroppableId =
      displayedColumns?.find(
        (column) => column.type === Board_Column_Type_Enum.Declined,
      )?.id ?? null;
    if (updatedDeclineColumDroppableId === null) {
      console.warn(`declineDroppableId is null`);
    }
    setDeclineColumnDroppableId(updatedDeclineColumDroppableId);
  }, [displayedColumns]);

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, type } = result;

    if (destination === null || destination === undefined) {
      console.error(`destination is not set: ${destination}`);
      return;
    }

    const positionOnContactBoardNotChanged =
      destination.droppableId === source.droppableId &&
      destination.index === source.index;
    if (positionOnContactBoardNotChanged) {
      console.warn("position on contact board not changed");
      return;
    }

    if (type === DRAGGABLE_TYPE_CONTACT) {
      await handleUpdateContactEntry(result);
      return;
    }

    await handleUpdateColumnPosition(result);
  };

  /**
   * @return successfully updated contact entry (true if that is the case)
   */
  const handleUpdateContactEntry = async (
    result?: DropResult,
    isDeclineFromDialog: boolean = false,
  ): Promise<boolean> => {
    if (result === undefined) {
      console.error(`Drag and drop result is undefined`, result);
      enqueueSnackbar(`Fehler beim Aktualisieren des Bewerber-Boards`, {
        variant: "error",
      });
      return false;
    }

    const { destination, source, draggableId: contactEntryId } = result;

    const updatedColumns: ContactBoardColumnWithEntriesFragment[] = cloneObject(
      displayedColumns!,
    );

    const oldColumnWithContactEntries = updatedColumns?.find(
      (column) => column.id === source.droppableId,
    );
    const destinationColumnWithContactEntries = updatedColumns?.find(
      (column) => column.id === destination!.droppableId,
    );

    if (
      oldColumnWithContactEntries === undefined ||
      destinationColumnWithContactEntries === undefined
    ) {
      console.error(
        `column with contact entries not found; oldColumnId: ${oldColumnWithContactEntries?.id}, newColumnId: ${destinationColumnWithContactEntries?.id}}`,
      );
      return false;
    }

    const isDeclineContactAction =
      destinationColumnWithContactEntries.type ===
      Board_Column_Type_Enum.Declined;

    const openDeclineDialog = isDeclineContactAction && !isDeclineFromDialog;

    if (openDeclineDialog) {
      const contactEntryToBeDeclined =
        oldColumnWithContactEntries.contact_board_entries.find(
          (contactEntry) => contactEntry.id === contactEntryId,
        );

      if (contactEntryToBeDeclined === undefined) {
        const error = new Error(
          `Contact to be declined was not found in source column`,
        );
        console.error(error.message, {
          contactEntryId,
          oldColumnWithContactEntries,
        });
        enqueueSnackbar(error.message, { variant: "error" });
        return false;
      }

      const contactToBeDeclined: ContactToBeRejected = {
        contactEntry: contactEntryToBeDeclined,
        dropResult: result,
      };
      setContactToBeRejected(contactToBeDeclined);
      return false;
    }

    const oldIndex = source.index;
    const newIndex = destination!.index;

    const { updatedSourceColumnEntries, updatedDestinationColumnEntries } =
      calculateUpdatedContactBoardEntries(
        oldColumnWithContactEntries,
        destinationColumnWithContactEntries,
        oldIndex,
        newIndex,
        contactEntryId,
      );

    oldColumnWithContactEntries.contact_board_entries =
      updatedSourceColumnEntries;
    destinationColumnWithContactEntries.contact_board_entries =
      updatedDestinationColumnEntries;

    setDisplayedColumns(updatedColumns);

    const contactsToUpdateWithoutDuplicatesIfMovedInColumn = [
      ...updatedSourceColumnEntries,
      ...updatedDestinationColumnEntries,
    ].filter(
      (contact_a, index, self) =>
        index === self.findIndex((contact_b) => contact_b.id === contact_a.id),
    );

    try {
      await upsertContactBoardEntries({
        variables: {
          objects: [
            ...convertContactBoardEntriesToUpsertVariables(
              contactsToUpdateWithoutDuplicatesIfMovedInColumn,
            ),
          ],
        },
        update: (cache) => {
          cache.modify({
            fields: {
              contact_board_entries() {
                updatedSourceColumnEntries.forEach((entry) => {
                  cache.writeFragment({
                    data: entry,
                    fragment: ContactBoardEntryFragmentDoc,
                    fragmentName: "ContactBoardEntry",
                  });
                });

                updatedDestinationColumnEntries.forEach((entry) => {
                  cache.writeFragment({
                    data: entry,
                    fragment: ContactBoardEntryFragmentDoc,
                    fragmentName: "ContactBoardEntry",
                  });
                });

                return [...updatedSourceColumnEntries];
              },
            },
          });
        },
      });
      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        `Fehler beim Aktualisieren des Bewerber-Boards: ${error instanceof Error ? error.message : "Unbekannter Fehler"}`,
        {
          variant: "error",
        },
      );
      Sentry.captureException(error);
      return false;
    }
  };

  const handleUpdateColumnPosition = async (result: DropResult) => {
    const { destination, source } = result;

    const updatedColumns: ContactBoardColumnWithEntriesFragment[] = cloneObject(
      displayedColumns!,
    );

    const removedColumns = updatedColumns.splice(source.index, 1);

    const errorOnRemovingColumn = removedColumns.length < 1;
    if (errorOnRemovingColumn) {
      console.error(
        `Cannot update contact board: Error on removing column with index ${source.index}`,
      );
      return updatedColumns;
    }

    const movedColumn = removedColumns[0];

    updatedColumns.splice(destination!.index, 0, movedColumn);

    updatedColumns.forEach((column, index) => {
      column.position = index;
    });

    setDisplayedColumns(updatedColumns);

    const updatedColumnsUpsertionInput = updatedColumns.map((column) =>
      mapColumnWithEntriesToInsertInput(column),
    );

    try {
      await upsertContactBoardColumns({
        mutation: UpsertContactBoardColumnsDocument,
        variables: {
          columns: updatedColumnsUpsertionInput,
        },
        update: (cache) => {
          cache.modify({
            fields: {
              board_contact_board_column: () => {
                updatedColumns.forEach((column) => {
                  cache.writeFragment({
                    id: column.id,
                    fragment: ContactBoardColumnFragmentDoc,
                    data: {
                      position: column.position,
                    },
                  });
                });

                return updatedColumns;
              },
            },
          });
        },
      });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        `Fehler beim Verschieben der Spalte "${movedColumn.name}": ${error.message}`,
        {
          variant: "error",
        },
      );
    }
  };

  function mapColumnWithEntriesToInsertInput(
    column: ContactBoardColumnWithEntriesFragment,
  ): Board_Contact_Board_Column_Insert_Input {
    return {
      board_id: column.board_id,
      id: column.id,
      name: column.name,
      position: column.position,
    };
  }

  async function handleUpdateColumnName(
    columnToBeUpdated: ContactBoardColumnWithEntriesFragment,
    updatedName: string,
  ) {
    const updatedColumns: ContactBoardColumnWithEntriesFragment[] = cloneObject(
      displayedColumns!,
    );
    const updatedDisplayedColumn = updatedColumns.find(
      (column) => column.id === columnToBeUpdated.id,
    );

    if (updatedDisplayedColumn === undefined) {
      console.error(
        `Error updating column ${columnToBeUpdated.name} with id ${columnToBeUpdated.id}: column not found`,
        displayedColumns,
      );
      enqueueSnackbar(
        `Fehler beim aktualisieren der Spalte ${columnToBeUpdated.name}: Spalte wurde nicht gefunden`,
        {
          variant: "error",
        },
      );
      return;
    }

    updatedDisplayedColumn.name = updatedName;

    setDisplayedColumns(updatedColumns);

    try {
      await upsertContactBoardColumns({
        mutation: UpsertContactBoardColumnsDocument,
        variables: {
          columns: [mapColumnWithEntriesToInsertInput(updatedDisplayedColumn)],
        },
      });
    } catch (error: any) {
      enqueueSnackbar(
        `Fehler beim ändern des Namens der Spalte "${columnToBeUpdated.name}" zu "${updatedName}: ${error.message}"`,
        {
          variant: "error",
        },
      );
      console.error(error);
    }
  }

  async function handleAddColumn(
    newColumn: Board_Contact_Board_Column_Insert_Input,
  ) {
    const addedColumn: ContactBoardColumnWithEntriesFragment = {
      id: newColumn.id ?? "",
      board_id: newColumn.board_id ?? "",
      name: newColumn.name ?? "",
      position: newColumn.position ?? 0,
      contact_board_entries: [],
      contact_board_entries_aggregate: {
        aggregate: {
          count: 0,
        },
      },
    };

    const updatedColumns = [...(displayedColumns || []), addedColumn];
    setDisplayedColumns(updatedColumns);

    try {
      await upsertContactBoardColumns({
        mutation: UpsertContactBoardColumnsDocument,
        variables: { columns: [newColumn] },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              board_contact_board_column: () => {
                cache.writeFragment({
                  data: data?.insert_board_contact_board_column?.returning[0],
                  fragment: ContactBoardColumnFragmentDoc,
                  fragmentName: "ContactBoardColumn",
                });

                return [updatedColumns];
              },
            },
          });

          // TODO Verifying that the cache is updated correctly
          console.log(cache.extract());
        },
      });
    } catch (error) {
      enqueueSnackbar(
        `Spalte "${newColumn.name}" konnte nicht erstellt werden`,
        {
          variant: "error",
        },
      );
      console.error(error);
    }
  }

  async function handleDeleteColumn(
    deletedColumn: ContactBoardColumnWithEntriesFragment,
    newColumnForContacts: ContactBoardColumnWithEntriesFragment | undefined,
  ) {
    const contactsNeedToBeMovedToOtherColumn =
      newColumnForContacts !== undefined;

    let updatedColumns: ContactBoardColumnWithEntriesFragment[] | undefined =
      undefined;

    if (contactsNeedToBeMovedToOtherColumn) {
      updatedColumns = await moveContactsToOtherColumn(
        deletedColumn,
        newColumnForContacts!,
      );
    }

    await deleteColumn(deletedColumn.id, updatedColumns);
  }

  async function moveContactsToOtherColumn(
    oldColumn: ContactBoardColumnWithEntriesFragment,
    newColumn: ContactBoardColumnWithEntriesFragment,
  ): Promise<ContactBoardColumnWithEntriesFragment[] | undefined> {
    const contactsToBeMoved: ContactBoardEntryFragment[] = cloneObject(
      oldColumn.contact_board_entries,
    );
    contactsToBeMoved.forEach((contact) => (contact.column_id = newColumn.id));

    const newColumnWithMovedContacts = [
      ...newColumn.contact_board_entries,
      ...contactsToBeMoved,
    ];

    const entriesWithUpdatedPositions = updateColumnEntryPositions(
      newColumnWithMovedContacts,
    );

    const updatedColumns: ContactBoardColumnWithEntriesFragment[] = cloneObject(
      displayedColumns!,
    );

    const sourceColumn: ContactBoardColumnWithEntriesFragment | undefined =
      updatedColumns?.find((column) => column.id === oldColumn.id);
    const destinationColumn: ContactBoardColumnWithEntriesFragment | undefined =
      updatedColumns?.find((column) => column.id === newColumn.id);

    if (sourceColumn != null) {
      sourceColumn.contact_board_entries = [];
    }
    if (destinationColumn != null) {
      destinationColumn.contact_board_entries = entriesWithUpdatedPositions;
    }

    try {
      await upsertContactBoardEntries({
        mutation: UpsertContactBoardEntriesMutationDocument,
        variables: {
          objects: [
            ...convertContactBoardEntriesToUpsertVariables(
              destinationColumn?.contact_board_entries ?? [],
            ),
          ],
        },
        optimisticResponse: {
          insert_board_contact_board_entry: {
            returning: destinationColumn?.contact_board_entries ?? [],
          },
        },
      });

      return updatedColumns;
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        `Fehler beim Verschieben der Kontakte in die neue Spalte: ${error.message}`,
        {
          variant: "error",
        },
      );
    }
  }

  async function deleteColumn(
    deletedColumnId: string,
    updatedColumns?: ContactBoardColumnWithEntriesFragment[],
  ) {
    const columns: ContactBoardColumnWithEntriesFragment[] =
      updatedColumns ?? displayedColumns ?? [];

    const displayedColumnsWithoutDeletedColumn = columns?.filter(
      (column: ContactBoardColumnWithEntriesFragment) =>
        column.id !== deletedColumnId,
    );
    const deletedColumn = columns?.find(
      (column) => column.id === deletedColumnId,
    );

    setDisplayedColumns(displayedColumnsWithoutDeletedColumn);

    try {
      return await deleteColumnMutation({
        variables: { id: deletedColumnId },
        update: (cache) => {
          cache.modify({
            fields: {
              board_contact_board_column: () => {
                return [displayedColumnsWithoutDeletedColumn];
              },
            },
          });
        },
      });
    } catch (error) {
      enqueueSnackbar(
        `Die Spalte '${deletedColumn?.name}' konnte nicht gelöscht werden`,
        { variant: "error" },
      );
      console.error(error);
    }
  }

  if (unfilteredColumns?.board_contact_board_column.length === 0) {
    return (
      <SomethingNotFoundDisplay description="Es wurde kein Bewerber-Board gefunden" />
    );
  }

  if (!columnsFound && !loading) {
    return (
      <SomethingNotFoundDisplay description="Es wurde kein Bewerber-Board gefunden" />
    );
  }

  return (
    <Stack gap={1} height="100%" sx={{ overflowY: "hidden" }}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <OutlinedInput
          endAdornment={
            searchQueryParams != "" && (
              <InputAdornment position="end">
                <Iconify
                  color="black"
                  icon="heroicons-solid:x"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            )
          }
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          placeholder="Suchen"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="heroicons:magnifying-glass" />
            </InputAdornment>
          }
          sx={{
            mb: 2,
            maxWidth: {
              xs: "18ch",
              sm: "25ch",
              lg: "35ch",
            },
          }}
          value={searchQueryParams}
        />
        <ApplicantExportButton />
      </Stack>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ minHeight: 0, flexGrow: 1, mb: 2 }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable
            direction="horizontal"
            droppableId="all-columns"
            type="column"
          >
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                alignItems="stretch"
                direction="row"
                ref={provided.innerRef}
                sx={{ height: "100%", overflowY: "hidden" }}
              >
                {!columnsFound ? (
                  <SkeletonBoardColumn />
                ) : (
                  displayedColumns?.map((column, index: number) => (
                    <BoardColumn
                      applicantPopupState={applicantPopupState}
                      boardUsers={boardUsers}
                      column={column}
                      columns={displayedColumns}
                      handleDeleteColumn={handleDeleteColumn}
                      handleUpdateColumnName={handleUpdateColumnName}
                      index={index}
                      key={column.id}
                      refetchBoardUsers={refetchBoardUsers}
                    />
                  ))
                )}

                {provided.placeholder}

                {displayedContactBoardId != null ? (
                  <AddContactColumn
                    boardId={displayedContactBoardId}
                    handleAddColumn={handleAddColumn}
                    newColumnPosition={displayedContactBoardId.length}
                  />
                ) : null}
              </Stack>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <ApplicantRejectionDialog
          campaign={campaign}
          handleUpdateContactEntry={handleUpdateContactEntry}
          key={
            "reject-applicant-" + contactToBeRejected?.contactEntry?.contact?.id
          }
          rejectionTemplate={rejectionTemplate}
        />
        <ContactPopup
          boardUsers={boardUsers}
          columnName={applicantPopupState?.column.name ?? ""}
          contactEntry={applicantPopupState?.applicantBoardEntry}
          customDragProps={{
            source: {
              droppableId: applicantPopupState?.column.id ?? "",
              index:
                applicantPopupState?.column.contact_board_entries
                  .filter((entry) =>
                    isApplicantSearchMatch(search, entry.contact),
                  )
                  .findIndex(
                    (entry) =>
                      entry.id === applicantPopupState?.applicantBoardEntry.id,
                  ) ?? 0,
            },
          }}
          isDeclined={
            applicantPopupState?.column.type === Board_Column_Type_Enum.Declined
          }
          isOpen={applicantPopupState != null}
          onClose={() => {
            setApplicantId(undefined);
          }}
          refetchBoardUsers={refetchBoardUsers}
          showOriginCampaign={showOriginCampaign}
        />
      </Container>
    </Stack>
  );
}
