import { Card, CardContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import type { InsightsQuestionFragment } from "generated/graphql";
import { AnswerDistributionChart } from "./AnswerDistributionChart";
import type { QuestionInsights } from "./answerInsightsHelpers";
import {
  getQuestionInsights,
  reduceExplicitlyDisplayedAnswerOptions,
} from "./answerInsightsHelpers";

export function QuestionInsightsCard({
  question,
}: {
  readonly question: InsightsQuestionFragment;
}) {
  const insights: QuestionInsights = getQuestionInsights(question);
  const reducedInsights: QuestionInsights =
    reduceExplicitlyDisplayedAnswerOptions(
      insights,
      question.answer_categories,
      question.id === "zip" ? question.answer_categories?.length : undefined,
    );

  const title = question.title.replace("(Mehrfachauswahl möglich)", "");
  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Typography color="textSecondary" variant="body1">
          Beantwortet von{" "}
          <strong>{question.total_answer_count.aggregate?.count ?? 0}</strong>{" "}
          Bewerbern
        </Typography>
        <Box
          sx={{
            p: 4,
          }}
        >
          <AnswerDistributionChart
            answerDistribution={
              reducedInsights?.answerDistribution ?? new Map()
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
}
