import React from "react";
import "./App.css";
import Router from "./router/router";
import CssBaseline from "@mui/material/CssBaseline";
import { HelmetProvider } from "react-helmet-async";
import { ConfirmationDialogProvider } from "./contexts/ConfirmationDialogProvider";
import { SearchContextProvider } from "components/campaigns/filter/SearchContext";
import "@fontsource-variable/inter";

function App() {
  return (
    <ConfirmationDialogProvider>
      <HelmetProvider>
        <CssBaseline />
        <SearchContextProvider>
          <Router />
        </SearchContextProvider>
      </HelmetProvider>
    </ConfirmationDialogProvider>
  );
}

export default App;
