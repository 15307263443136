import type {
  Board_Contact_Board_Entry_Insert_Input,
  ContactBoardEntryFragment,
} from "../../generated/graphql";
import { cloneObject } from "../ProgressCircle";
import type { ContactBoardColumnWithEntriesFragment } from "./ContactBoard";

export const DRAGGABLE_TYPE_CONTACT = "contact-draggable";

export type UpdatedContactBoardEntries = {
  updatedSourceColumnEntries: ContactBoardEntryFragment[];
  updatedDestinationColumnEntries: ContactBoardEntryFragment[];
};

export type SharedUser = {
  id: string;

  name: string | undefined;

  picture: string | null;
};

/**
 * Updates the contact board entries of the source and destination column according to the drag and drop action
 */
export function calculateUpdatedContactBoardEntries(
  sourceColumn: ContactBoardColumnWithEntriesFragment,
  destinationColumn: ContactBoardColumnWithEntriesFragment,
  sourceIndex: number,
  destinationIndex: number,
  contactEntryId: string,
): UpdatedContactBoardEntries {
  const updatedContactBoardEntries: UpdatedContactBoardEntries = {
    updatedDestinationColumnEntries: [],
    updatedSourceColumnEntries: [],
  };

  const sourceColumnClone: ContactBoardColumnWithEntriesFragment =
    cloneObject(sourceColumn);
  const destinationColumnClone: ContactBoardColumnWithEntriesFragment =
    cloneObject(destinationColumn);

  const removedEntry = sourceColumnClone.contact_board_entries.splice(
    sourceIndex,
    1,
  );
  const errorOnRemovingEntry = removedEntry.length < 1;
  if (errorOnRemovingEntry) {
    console.error(
      `Cannot update contact board: Error on removing entry with id ${contactEntryId} from column ${sourceColumnClone.id}`,
    );
    return updatedContactBoardEntries;
  }

  const modifiedContactEntry = removedEntry[0];

  const contactMovedWithinColumn = sourceColumn.id === destinationColumn.id;
  if (contactMovedWithinColumn) {
    const updatedSourceColumnEntries = updateColumEntryPositionsWithNewEntry(
      sourceColumnClone.contact_board_entries,
      destinationIndex,
      modifiedContactEntry,
    );

    updatedContactBoardEntries.updatedSourceColumnEntries =
      updatedSourceColumnEntries;
    updatedContactBoardEntries.updatedDestinationColumnEntries =
      updatedSourceColumnEntries;
  } else {
    modifiedContactEntry.column_id = destinationColumnClone.id;

    updatedContactBoardEntries.updatedSourceColumnEntries =
      sourceColumnClone.contact_board_entries;
    updatedContactBoardEntries.updatedDestinationColumnEntries =
      updateColumEntryPositionsWithNewEntry(
        destinationColumnClone.contact_board_entries,
        destinationIndex,
        modifiedContactEntry,
      );
  }

  return updatedContactBoardEntries;
}

/** Updates the position attribute of the {@link entries} according to their current position in the array
 * <br>
 * <i>Used e.g. after moving a contact entry in / between columns</i>*/
export function updateColumnEntryPositions(
  entries: ContactBoardEntryFragment[],
): ContactBoardEntryFragment[] {
  const entriesCopy: ContactBoardEntryFragment[] = cloneObject(entries);

  entriesCopy.forEach((entry, index) => {
    entry.position = index;
  });

  return entriesCopy;
}

export function convertContactBoardEntriesToUpsertVariables(
  entries: ContactBoardEntryFragment[],
): Board_Contact_Board_Entry_Insert_Input[] {
  return entries.map((entry) => {
    return {
      column_id: entry.column_id,
      id: entry.id,
      contact_id: entry.contact!.id,
      score: entry.score,
      board_id: entry.board_id,
      position: entry.position,
    };
  });
}

function updateColumEntryPositionsWithNewEntry(
  entries: ContactBoardEntryFragment[],
  newEntryIndex: number | undefined,
  newEntry: ContactBoardEntryFragment | undefined,
): ContactBoardEntryFragment[] {
  const clonedEntries: ContactBoardEntryFragment[] = cloneObject(entries);
  let sortedByPosition = clonedEntries.sort(
    (entryA, entryB) =>
      (entryA.position ?? Number.MAX_SAFE_INTEGER) -
      (entryB.position ?? Number.MAX_SAFE_INTEGER),
  );

  if (newEntryIndex !== undefined && newEntry !== undefined) {
    sortedByPosition.splice(newEntryIndex, 0, newEntry);
  }

  sortedByPosition = updateColumnEntryPositions(sortedByPosition);

  return sortedByPosition;
}
