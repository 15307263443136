import { Grid, Stack } from "@mui/material";
import { CampaignCard } from "components/campaigns/CampaignCard";
import type { HukGsDetailsFragment } from "generated/graphql";
import type { PLATFORM } from "hooks/campaign/getCampaignKPIs";
import { useState } from "react";
import InsightsSection from "../InsightsSection";
import { ExternalHukKpiCard } from "./HukKpiCards";
import HukApplicationInsights from "./HukApplicationInsights";
import { HukQuestionInsightsSection } from "./HukQuestionInsightsSection";
import { getHukGsKPIs } from "hooks/campaign/getHukGsKPIs";

export function GsInsightsDisplay({
  gs,
}: {
  readonly gs: HukGsDetailsFragment;
}) {
  const [selectedPlatform, setSelectedPlatform] = useState<PLATFORM>("GESAMT");

  const [selectedContactPlatform, setSelectedContactPlatform] =
    useState<PLATFORM>("GESAMT");

  const campaigns = gs.gs_campaigns;
  const { kpis, nonZeroKPIPlatforms, totalLeadsMap, qualifiedLeadsMap } =
    getHukGsKPIs(gs, selectedPlatform);

  return (
    <Stack alignItems="stretch" spacing={7} sx={{ mb: 5, mt: 5 }}>
      {kpis[3] !== undefined && <ExternalHukKpiCard kpis={kpis} />}
      {totalLeadsMap.size > 0 ? (
        <InsightsSection title="Bewerbungen">
          <HukApplicationInsights
            gs={gs}
            qualifiedLeadsMap={qualifiedLeadsMap}
            selectedTab={selectedContactPlatform}
            setSelectedTab={setSelectedContactPlatform}
            totalLeadsMap={totalLeadsMap}
          />
        </InsightsSection>
      ) : null}
      <HukQuestionInsightsSection campaigns={campaigns} />
      <InsightsSection title="Kampagnen">
        <Grid alignItems="stretch" container justifyItems="stretch" spacing={3}>
          {campaigns?.map((campaign, index) => {
            if (!campaign.campaign) return null;
            return (
              <Grid
                display="flex"
                item
                key={campaign.campaign?.id ?? `campaign ${index}`}
                lg={6}
                sm={12}
                xl={4}
              >
                <CampaignCard campaign={campaign.campaign} />
              </Grid>
            );
          })}
        </Grid>
      </InsightsSection>
    </Stack>
  );
}
