import React, { createContext } from "react";

export interface ImpersonationOverrides {
  organization: string | null;
  region: string | null;
  location: string | null;

  setOrganization: (organization: string | null) => void;
  setRegion: (region: string | null) => void;
  setLocation: (location: string | null) => void;
}

const ImpersonationContext = createContext<ImpersonationOverrides>({
  organization: null,
  region: null,
  location: null,
  setOrganization: () => {},
  setRegion: () => {},
  setLocation: () => {},
});

export const ImpersonationProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const [organization, setOrganization] = React.useState<string | null>(null);
  const [region, setRegion] = React.useState<string | null>(null);
  const [location, setLocation] = React.useState<string | null>(null);

  return (
    <ImpersonationContext.Provider
      value={{
        organization,
        region,
        location,
        setOrganization,
        setRegion,
        setLocation,
      }}
    >
      {children}
    </ImpersonationContext.Provider>
  );
};

export default ImpersonationContext;
