import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import type { KPI } from "../../hooks/campaign/getCampaignKPIs";
import Iconify from "../Iconify";

export interface InsightsStatCardProps {
  readonly kpi: KPI;
  readonly progressCircleDisplay?: any;
}

function InsightsStat({ kpi, progressCircleDisplay }: InsightsStatCardProps) {
  return (
    <Stack alignItems="center" direction="row" justifyContent="center">
      {kpi.progress ? (
        <Box display={progressCircleDisplay}>
          <ProgressCircleSmall
            backgroundColor={kpi.progress.backgroundColor}
            color={kpi.progress.color}
            icon={kpi.progress.icon}
            progress={kpi.progress.value * 100}
          />
        </Box>
      ) : null}

      <Stack spacing={0.5} sx={{ ml: 2, alignItems: "center" }}>
        <Typography sx={{ textTransform: "capitalize" }} variant="body1">
          {kpi.name}
        </Typography>

        <Stack alignItems="center">
          <Typography
            sx={{ color: kpi.showDash ? undefined : kpi.displayValueColor }}
            variant="h6"
          >
            {kpi.showDash ? "-" : kpi.displayValue}
          </Typography>
          {kpi.caption ? (
            <Typography
              color={kpi.caption.displayValueColor}
              variant="subtitle2"
            >
              {kpi.caption.displayValue}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}

function ProgressCircleSmall({
  progress,
  color,
  backgroundColor,
  icon,
}: {
  readonly progress: number;
  readonly color?: string;
  readonly backgroundColor?: string;
  readonly icon?: string;
}) {
  const ICON_SIZE = 15;

  return (
    <Stack alignItems="center" direction="row" justifyContent="center">
      {icon ? (
        <Iconify
          icon={icon}
          sx={{
            color,
            width: ICON_SIZE,
            height: ICON_SIZE,
            position: "absolute",
          }}
        />
      ) : null}
      <CircularProgress
        size={56}
        sx={{ color: color, zIndex: 1, position: "absolute" }}
        thickness={4}
        value={progress}
        variant="determinate"
      />
      <CircularProgress
        size={56}
        sx={{
          position: "relative",
          color: backgroundColor ?? "grey.50016",
          top: 0,
          left: 0,
        }}
        thickness={4}
        value={100}
        variant="determinate"
      />
    </Stack>
  );
}

export default InsightsStat;
