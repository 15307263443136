import { Card, Divider, Stack, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import type { ContactFragment } from "generated/graphql";
import { useGetCampaignChatBotSettingsLazyQuery } from "generated/graphql";
import React, { useEffect } from "react";
import { CB_DARK_GREEN } from "../colors";
import type { SharedUser } from "../contactBoardUtils";
import { AttachmentsTab } from "./tabs/attachment/AttachmentsTab";
import { ChatTab } from "./tabs/chat/ChatTab";
import { NotesTab } from "./tabs/notes/NotesTab";
import { ProfileTab } from "./tabs/profile/ProfileTab";
import { QuestionAnswerTab } from "./tabs/qa/QuestionAnswerTab";

type TabKey = "profile" | "q&a" | "notes" | "chat" | "attachments";

export interface Tab {
  key: TabKey;
  display: string;
}

const tabs = [
  { key: "profile", display: "Profil" },
  { key: "q&a", display: "Q&A" },
  { key: "notes", display: "Notizen" },
  { key: "chat", display: "Chat" },
  // { key: "attachments", display: "Anhänge" },
];

export function ContentCardHeader({
  currentTab,
  setCurrentTab,
  displayChatTab,
}: {
  readonly currentTab: TabKey;
  readonly setCurrentTab: (tab: TabKey) => void;
  readonly displayChatTab: boolean;
}) {
  let displayedTabs = tabs;

  if (!displayChatTab) {
    displayedTabs = tabs.filter((tab) => tab.key !== "chat");
  }

  return (
    <Box p={2} width="100%">
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: CB_DARK_GREEN,
          },
        }}
        allowScrollButtonsMobile
        onChange={(e, value) => setCurrentTab(value)}
        scrollButtons="auto"
        sx={{
          width: "100%",
        }}
        value={currentTab}
        variant="scrollable"
      >
        {displayedTabs.map((tab) => (
          <Tab
            disableRipple
            key={tab.key}
            label={tab.display}
            value={tab.key}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export function ContactPopupContentCard(props: {
  readonly contact: ContactFragment;
  readonly contactBoardEntryId: string;
  readonly boardUsers: SharedUser[] | null;
  readonly refetchBoardUsers: () => void;
}) {
  const { contact, boardUsers, contactBoardEntryId, refetchBoardUsers } = props;
  const [currentTab, setCurrentTab] = React.useState<TabKey>("profile");
  const theme = useTheme();
  const gray = theme.palette.grey[300];

  const [getChatBotSettings, { data: campaignQueryData }] =
    useGetCampaignChatBotSettingsLazyQuery();
  useEffect(() => {
    (async () => {
      if (contact.campaign_id != null) {
        await getChatBotSettings({
          variables: { campaignId: contact.campaign_id },
        });
      }
    })();
  }, []);
  const chatBotSettings = campaignQueryData?.campaign_by_pk;
  function getChatBotKind() {
    return chatBotSettings?.chat_bot_enabled
      ? "v1"
      : chatBotSettings?.chat_bot_v2_enabled
        ? "v2"
        : null;
  }
  const chatBotKind = getChatBotKind();

  const isChatBotEnabled = chatBotKind != null;

  return (
    <Card
      sx={{
        border: "1px solid " + gray,
        borderRadius: "10px",
        width: "100%",
        height: "100%",
      }}
    >
      <Stack height="100%" width="100%">
        <ContentCardHeader
          currentTab={currentTab}
          displayChatTab={isChatBotEnabled}
          setCurrentTab={setCurrentTab}
        />
        <Divider />
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            overflow: "auto",
            height: "100%",
          }}
        >
          {currentTab === "profile" && <ProfileTab contact={contact} />}
          {currentTab === "q&a" && <QuestionAnswerTab contact={contact} />}
          {currentTab === "notes" && (
            <NotesTab
              boardUsers={boardUsers}
              contact={contact}
              contactBoardEntryId={contactBoardEntryId}
              refetchBoardUsers={refetchBoardUsers}
            />
          )}
          {currentTab === "chat" && (
            <ChatTab chatBotKind={chatBotKind} contact={contact} />
          )}
          {currentTab === "attachments" && <AttachmentsTab contact={contact} />}
        </Box>
      </Stack>
    </Card>
  );
}
