import { useEffect, useRef, useState } from "react";

export default function useListScroll({ items }: { items: any[] }) {
  const listEndRef = useRef<HTMLDivElement>(null);

  const [hasDoneInitialScroll, setHasDoneInitialScroll] = useState(false);

  useEffect(() => {
    if (listEndRef.current == null) return;
    listEndRef.current.scrollIntoView({
      behavior: hasDoneInitialScroll ? "smooth" : "instant",
      block: "start",
    });
    setHasDoneInitialScroll(true);
  }, [items, listEndRef.current]);

  return listEndRef;
}
