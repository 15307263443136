import type { PLATFORM } from "hooks/campaign/getCampaignKPIs";
import moment from "moment/moment";
import type {
  CampaignFragment,
  NewContactsPerDayFragment,
  UnqualifiedContactsPerDayFragment,
} from "generated/graphql";
import { calculateDiffInDays } from "utils/CampaignDetailsUtil";

export const DATE_FORMAT_STRING = "YYYY-MM-DD";

export interface ContactsPerDayInsightsChartProps {
  campaign: CampaignFragment;
  selectedTab: PLATFORM;
}

export type ContactsPerDayChartData = {
  x: string;
  y: number;
  new_unqualified_contacts: number;
  qualified_contacts: number;
}[];

export const getCampaignCardChartData = (campaign: CampaignFragment) => {
  const chartData = [];
  const start = moment(campaign.start);
  const end = moment(campaign.end);
  const diffDays = Math.min(
    calculateDiffInDays(end, start),
    calculateDiffInDays(moment(), start),
  );
  let total = 0;
  for (let i = 0; i < diffDays; i++) {
    const d = moment(campaign.start).add(i, "d").format(DATE_FORMAT_STRING);
    const entry = campaign.contacts_per_day.find(({ date }) => d === date);
    if (entry !== undefined) {
      total = total + entry.new_contacts;
      const y = entry.new_contacts;
      chartData.push({
        x: d,
        y,
        new_contacts: entry.new_contacts,
      });
    } else {
      chartData.push({
        x: d,
        y: 0,
        new_contacts: 0,
      });
    }
  }
  return chartData;
};

export const getContactsPerDayChartData = (
  campaign: CampaignFragment,
  sum: boolean = false,
  selectedTab: PLATFORM,
  unqualifiedContacts?: UnqualifiedContactsPerDayFragment[],
): ContactsPerDayChartData => {
  const chartData = [];
  const start = moment(campaign.start);
  const end = moment(campaign.end);

  const diffDays = Math.min(
    calculateDiffInDays(end, start),
    calculateDiffInDays(moment(), start),
  );

  let contactsSum = 0;
  let qualifiedContactsSum = 0;
  let unqualifiedContactsSum = 0;

  for (let i = 0; i < diffDays; i++) {
    const date = moment(campaign.start).add(i, "d").format(DATE_FORMAT_STRING);
    const entries = campaign.contacts_per_day.filter(
      (contactsPerDayEntry) =>
        contactsPerDayEntry.date === date &&
        isPlatform(selectedTab, contactsPerDayEntry.utm_source),
    );
    const qualifiedContactsCount = entries.reduce(
      (sum, entry) => sum + entry.new_contacts,
      0,
    );

    const matchingUnqualifiedContactsEntries = unqualifiedContacts?.filter(
      (unqualifiedContact) =>
        date === moment(unqualifiedContact.date).format(DATE_FORMAT_STRING) &&
        isPlatform(selectedTab, unqualifiedContact.utm_source),
    );

    const unqualifiedContactsCount =
      matchingUnqualifiedContactsEntries?.reduce(
        (sum, entry) => sum + entry.new_unqualified_contacts,
        0,
      ) ?? 0;
    unqualifiedContactsSum += unqualifiedContactsCount;

    contactsSum += qualifiedContactsCount + unqualifiedContactsCount;
    qualifiedContactsSum += qualifiedContactsCount;

    const dailyContacts = sum
      ? contactsSum
      : qualifiedContactsCount + unqualifiedContactsCount;
    const dailyQualifiedContacts = sum
      ? qualifiedContactsSum
      : qualifiedContactsCount;
    const dailyUnqualifiedContacts = sum
      ? unqualifiedContactsSum
      : unqualifiedContactsCount;

    chartData.push({
      x: date,
      y: dailyContacts,
      new_unqualified_contacts: dailyUnqualifiedContacts,
      qualified_contacts: dailyQualifiedContacts,
    });
  }
  return chartData;
};

export function getGsCampaignCardChartData(
  contactEntries: CampaignFragment["contacts_per_day"][number][],
) {
  const chartData = [];
  const start = contactEntries.reduce((earliestStart, entry) => {
    const start = moment(entry.date);
    if (start.isBefore(earliestStart)) {
      return start;
    } else {
      return earliestStart;
    }
  }, moment());

  const end = contactEntries.reduce((latestEnd, campaign) => {
    const end = moment(campaign.date);
    if (end.isAfter(latestEnd)) {
      return end;
    } else {
      return latestEnd;
    }
  }, start);

  const diffDays = Math.min(
    calculateDiffInDays(end, start),
    calculateDiffInDays(moment(), start),
  );
  for (let i = 0; i < diffDays; i++) {
    const d = start.clone().add(i, "d").format(DATE_FORMAT_STRING);
    const newApplicants = contactEntries.reduce((sum, entry) => {
      if (entry.date === d) {
        return sum + entry.new_contacts;
      }
      return sum;
    }, 0);
    const y = newApplicants;
    chartData.push({
      x: d,
      y,
      new_contacts: newApplicants,
    });
  }
  return chartData;
}

export const getHukGsContactsPerDayChartData = (
  qualifiedContacts: NewContactsPerDayFragment[],
  unqualifiedContacts: UnqualifiedContactsPerDayFragment[] = [],
  sum: boolean = false,
  selectedTab: PLATFORM,
): ContactsPerDayChartData => {
  const chartData = [];

  const start = qualifiedContacts.reduce((earliestStart, entry) => {
    const start = moment(entry.date);
    if (start.isBefore(earliestStart)) {
      return start;
    } else {
      return earliestStart;
    }
  }, moment());
  const end = qualifiedContacts.reduce((latestEnd, campaign) => {
    const end = moment(campaign.date);
    if (end.isAfter(latestEnd)) {
      return end;
    } else {
      return latestEnd;
    }
  }, start);

  const diffDays = Math.min(
    calculateDiffInDays(end, start),
    calculateDiffInDays(moment(), start),
  );

  const filteredQualifiedContacts = qualifiedContacts.filter((entry) =>
    isPlatform(selectedTab, entry.utm_source),
  );

  const filteredUnqualifiedContacts = unqualifiedContacts.filter((entry) =>
    isPlatform(selectedTab, entry.utm_source),
  );

  let contactsSum = 0;
  let qualifiedContactsSum = 0;
  let unqualifiedContactsSum = 0;

  for (let i = 0; i < diffDays; i++) {
    const date = moment(start).add(i, "d").format(DATE_FORMAT_STRING);

    const qualifiedContactsForDay = filteredQualifiedContacts.reduce(
      (sum, entry) => (entry.date === date ? sum + entry.new_contacts : sum),
      0,
    );

    const unqualifiedContactsForDay = filteredUnqualifiedContacts.reduce(
      (sum, entry) =>
        entry.date === date ? sum + entry.new_unqualified_contacts : sum,
      0,
    );

    qualifiedContactsSum += qualifiedContactsForDay;
    unqualifiedContactsSum += unqualifiedContactsForDay;

    contactsSum += qualifiedContactsForDay + unqualifiedContactsForDay;

    const dailyContacts = sum
      ? contactsSum
      : qualifiedContactsForDay + unqualifiedContactsForDay;
    const dailyQualifiedContacts = sum
      ? qualifiedContactsSum
      : qualifiedContactsForDay;
    const dailyUnqualifiedContacts = sum
      ? unqualifiedContactsSum
      : unqualifiedContactsForDay;

    chartData.push({
      x: date,
      y: dailyContacts,
      new_unqualified_contacts: dailyUnqualifiedContacts,
      qualified_contacts: dailyQualifiedContacts,
    });
  }
  return chartData;
};

const isPlatform = (
  selectedTab: string,
  utm_source: string | null | undefined,
): boolean => {
  if (selectedTab === "GESAMT") {
    return true;
  }
  return selectedTab === "UNBEKANNT"
    ? utm_source === null || utm_source === undefined
    : utm_source === selectedTab;
};

export const hasChartData = (chartData: any[]) => {
  return (
    chartData.length > 0 &&
    chartData.filter((e) => e.y != null && e.y !== 0).length >= 2
  );
};
