import type { GsInsightsQuestionFragment } from "generated/graphql";
import { useGetQuestionsForMultipleCampaignsQuery } from "generated/graphql";
import InsightsSection from "../InsightsSection";
import AnswerInsights from "./AnswerInsights";
import { filterQuestions } from "./answerInsightsHelpers";
import type { GsCampaigns } from "./HukApplicationInsights";

export function HukQuestionInsightsSection({
  campaigns,
}: {
  readonly campaigns: GsCampaigns[];
}) {
  const questionsResult = useGetQuestionsForMultipleCampaignsQuery({
    variables: {
      campaign_ids: campaigns.flatMap((campaign) =>
        campaign.campaign?.id != null ? [campaign.campaign.id] : [],
      ),
    },
  });

  // Merge questions and answers with same title
  const mergedQuestions = questionsResult.data?.question.reduce(
    (merged, question) => {
      const existing = merged.find(
        (existingQuestion) => existingQuestion.title === question.title,
      );
      if (existing != null) {
        existing.answers = existing.answers.concat(question.answers);
      } else {
        merged.push(structuredClone<GsInsightsQuestionFragment>(question));
      }
      return merged;
    },
    [] as GsInsightsQuestionFragment[],
  );

  if (mergedQuestions == null || mergedQuestions.length == 0) {
    return null;
  }

  const multipleChoiceQuestions = filterQuestions(mergedQuestions).filter(
    (q) => !q.title.includes("Datenschutz"),
  );

  return (
    <InsightsSection title="Fragen">
      <AnswerInsights questions={multipleChoiceQuestions} />
    </InsightsSection>
  );
}
