import { getOrganizationNamePrefixDative } from "contexts/OrganizationDesignationUtils";
import type {
  CampaignDetailsFragment,
  HukGsDetailsFragment,
} from "generated/graphql";
import Handlebars from "handlebars";

export const WELCOME_INFORMAL = `
<h3>Hallo {{ firstName }},</h3>
<p>vielen Dank für dein Interesse an einem Job {{ organization_name_prefix }} <strong>{{organization_name }}</strong>! Wir freuen uns, dich näher kennenzulernen!</p>
<p>In den nächsten Tagen wird sich ein Recruiter bei dir melden, um mit dir erste Fragen zu klären. Achte bitte auf Anrufe und Nachrichten von Nummern, die du noch nicht kennst und sei erreichbar. </p>

{{#if support_email}}
{{#unless cv_sent}}

<p>Und solltest du es noch nicht getan haben, schick uns bitte schnell auch einen Lebenslauf von dir an: </p>
<p><a target="_blank" rel="noopener noreferrer nofollow" href="mailto:{{support_email}}">{{support_email}}</a> </p>
<p>Den brauchen wir nämlich für den weiteren Bewerbungsprozess.</p>

{{/unless}}
{{/if}}

<p>
  Vielen Dank und auf bald,<br>
  <strong>dein Recruiting-Team</strong>
</p>

<hr>

<p>Du möchtest nochmal wissen, worauf du dich beworben hast?<br>
  <a target="_blank" rel="noopener noreferrer nofollow" href="{{ job_ad_url }}">Zur Stellenanzeige</a>
</p>
`;

export const WELCOME_FORMAL = `
<h3>Hallo,</h3>

<p>vielen Dank für Ihr Interesse an einem Job {{ organization_name_prefix }} <strong>{{ organization_name }}</strong>! Wir freuen uns, Sie näher kennenzulernen!</p>
<p>In den nächsten Tagen wird sich ein Recruiter bei Ihnen melden, um mit Ihnen erste Fragen zu klären. Achten Sie bitte auf Anrufe und Nachrichten von Nummern, die Sie noch nicht kennen und seien Sie erreichbar.</p>

{{#if support_email}}
{{#unless cv_sent}}

<p>Und sollten Sie es noch nicht getan haben, schicken Sie uns bitte schnell auch einen Lebenslauf von Ihnen an:</p>
<a href="mailto:{{support_email}}">{{support_email}})</a>
<p>Den brauchen wir nämlich für den weiteren Bewerbungsprozess.</p>

{{/unless}}
{{/if}}

<p>
Vielen Dank und auf bald,</br>
<strong>Ihr Recruiting-Team</strong>
</p>

<hr/>

<p>
Sie möchten sich erneut darüber informieren, für welche Position Sie sich beworben haben?</br>
<a href="{{ job_ad_url }}">Zur Stellenanzeige</a>
</p>
`;

export const REJECTION_INFORMAL = `
<h3>Hallo {{ firstName }},</h3>
<p>vielen Dank für deine Bewerbung und dein Interesse an einem Job {{organization_name_prefix}} {{organization_name}}!</p>
<p>Leider müssen wir dir mitteilen, dass wir sehr viele Bewerbungen bekommen haben und dich nicht in die engere Auswahl aufnehmen können.</p>
<p>Bitte verstehe unsere Absage nicht als Abwertung deiner Qualifikation. Im Bewerbungsprozess sind letztendlich oft kleine Details ausschlaggebend für die Entscheidung, die wir uns nicht leicht gemacht haben.</p>
<p>Wir wünschen dir viel Erfolg für deinen weiteren Weg!</p>

<p>
Beste Grüße,</br>
<strong>dein Recruiting-Team</strong>
</p>
`;

export const REJECTION_FORMAL = `
<h3>Hallo,</h3>
<p>vielen Dank für Ihre Bewerbung und Ihr Interesse an einem Job {{organization_name_prefix}} {{organization_name}}!</p>
<p>Leider müssen wir Ihnen mitteilen, dass wir sehr viele Bewerbungen bekommen haben und Sie nicht in die engere Auswahl aufnehmen können.</p>
<p>Bitte verstehen Sie unsere Absage nicht als Abwertung Ihrer Qualifikation. Im Bewerbungsprozess sind letztendlich oft kleine Details ausschlaggebend für die Entscheidung, die wir uns nicht leicht gemacht haben.</p>
<p>Wir wünschen Ihnen viel Erfolg für Ihren weiteren Weg!</p>

<p>
Beste Grüße,</br>
<strong>Ihr Recruiting-Team</strong>
</p>
`;

export function compileTemplate(
  template: string,
  campaign?: CampaignDetailsFragment | HukGsDetailsFragment,
  contact?: { first_name?: string; last_name?: string },
): string {
  const orgSettings = campaign?.organization;
  const orgSettingsOverride = campaign?.org_settings_override;

  const orgNamePrefix =
    orgSettings?.name_prefix != undefined
      ? getOrganizationNamePrefixDative(
          orgSettingsOverride?.name_prefix ?? orgSettings?.name_prefix,
        )
      : undefined;

  const orgName =
    orgSettingsOverride?.short_name ??
    orgSettings?.short_name ??
    orgSettings?.name;

  const params: {
    firstName?: string;
    lastName?: string;
    support_email?: string;
    organization_name_prefix?: string;
    organization_name?: string;
    job_ad_url?: string;
  } = {
    firstName: contact?.first_name,
    lastName: contact?.last_name,
    support_email: campaign?.support_email ?? undefined,
    organization_name_prefix: orgNamePrefix,
    organization_name: orgName,
    job_ad_url: campaign?.perspective_campaign?.url ?? "{{ job_ad_url }}",
  };

  return Handlebars.compile(template)(params);
}
