import { CampaignFragment } from "generated/graphql";
import { DeepPartial } from "types";
import { getMediaBudget } from "./getMediaBudget";
import { getTotalBudget } from "./getCampaignBudget";
import { retrieveRemainingBudget } from "./retrieveRemainingBudget";

export function calculateRemainingMediaBudget(
  campaign: DeepPartial<CampaignFragment>,
) {
  const mediaBudget = getMediaBudget(campaign);
  const adSpent = campaign.ad_campaign_metrics?.reduce((acc, curr) => {
    return acc + curr?.spend ?? 0;
  }, 0);
  if (mediaBudget != null && adSpent != null) {
    return mediaBudget - adSpent;
  }
  const totalBudget = getTotalBudget(campaign);
  const remainingBudget = retrieveRemainingBudget(campaign);
  if (totalBudget != null && mediaBudget != null && remainingBudget != null) {
    return mediaBudget * (remainingBudget / totalBudget);
  }
  return undefined;
}

export function calculateMediaBudgetBonus(
  campaign: DeepPartial<CampaignFragment>,
) {
  const additionalBudget = campaign.customer_budget_bonus ?? 0;
  const mediaBudgetShare = 1 - campaign.margin_in_percent / 100;
  return additionalBudget * mediaBudgetShare;
}
