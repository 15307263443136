import type { LabelColor } from "../../Label";
import Label from "../../Label";
import type { CampaignStatusType } from "utils/getCampaignStatus";

export function CampaignStatusLabel({
  campaignStatus,
  isHidden,
  size = "large",
}: {
  readonly campaignStatus?: CampaignStatusType | null;
  readonly isHidden?: boolean;
  readonly size?: "small" | "medium" | "large";
}) {
  const [labelText, color] = getCampaignStatusDisplay(campaignStatus, isHidden);

  return (
    <Label color={color} size={size} variant="ghost">
      {labelText}
    </Label>
  );
}

function getCampaignStatusDisplay(
  status?: CampaignStatusType | null,
  isHidden?: boolean,
): [string, LabelColor] {
  if (isHidden) return ["Ausgeblendet", "default"];
  switch (status) {
    case "ACTIVE":
      return ["Live", "success"];
    case "PAUSED":
      return ["Pausiert", "warning"];
    case "DRAFT":
      return ["Ausstehend", "info"];
    default:
    case "FINISHED":
      return ["Beendet", "default"];
  }
}
