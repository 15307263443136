import {
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import type { Ad_Platform_Enum, CampaignFragment } from "generated/graphql";
import { useUser } from "hooks/useUser";
import moment from "moment/moment";
import { Link as RouterLink } from "react-router-dom";
import { getCampaignInfo } from "utils/CampaignDetailsUtil";
import { EM_DASH } from "../../constants";
import getCampaignKPIs from "../../hooks/campaign/getCampaignKPIs";
import {
  getCampaignCardChartData,
  hasChartData,
} from "../campaign_insights/answer_insights/applicationInsightHelpers";
import { LightTooltip } from "../LigthTooltip";
import { RequiresPermission } from "../RequiresPermission";
import { RequiresRole } from "../RequiresRole";
import { AdminCampaignActionsExternalTools } from "./campaign_card/AdminCampaignActionsExternalTools";
import { AdPlatformBar } from "./campaign_card/AdPlatformBar";
import { CampaignActionsAndStatus } from "./campaign_card/CampaignActionsAndStatus";
import ContactsPerDayChart from "./campaign_card/ContactsPerDayChart";
import { KPIDisplay } from "./campaign_card/KPIDisplay";
import { CampaignInfoLabels } from "./campaign_labels/CampaignInfoLabels";
import { TestCampaignLabel } from "./campaign_labels/TestCampaignLabel";

const ChartContainer = styled(Box)(`
  svg {
    overflow: visible;
  }
`);

export function CampaignCard({
  campaign,
}: {
  readonly campaign: CampaignFragment;
}) {
  const campaignInfo = getCampaignInfo(campaign);

  const { hasAdvancedAccessToCampaigns } = useUser();
  const {
    kpis,
    platformKPIs,
    daysElapsedPercentage,
    campaignHasDefinedEnd,
    qualifiedLeads,
  } = getCampaignKPIs({
    campaign,
    includeInternalInsights: hasAdvancedAccessToCampaigns,
    selectedPlatform: "GESAMT",
  });
  const start = moment(campaign.start);
  const end = moment(campaign.end);

  const displayedKpis = kpis.filter((kpi) => kpi.displayIf);
  const chartData = getCampaignCardChartData(campaign);
  const showChart = hasChartData(chartData);
  const hasFunnel = campaign.perspective_campaign != null;
  const hasAdCampaign =
    campaign.ad_campaign_metrics != null &&
    campaign.ad_campaign_metrics.length > 0;

  function getAdPlatforms() {
    if (campaign.ad_campaign_metrics != null) {
      const platforms = new Set<Ad_Platform_Enum>();
      campaign.ad_campaign_metrics.forEach((metric) => {
        platforms.add(metric.platform);
      });
      return Array.from(platforms);
    }
    return [];
  }

  const adPlatforms = getAdPlatforms();

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack
          alignItems="start"
          direction="row"
          justifyContent="space-between"
          marginBottom={3}
          overflow="hidden"
          width="100%"
        >
          <Stack>
            {campaign.is_test ? (
              <TestCampaignLabel sx={{ alignSelf: "start", mb: 2 }} />
            ) : null}
            <LightTooltip title="Zu den Insights">
              <Box>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  to={`/campaigns/${campaign.id}?tab=insights`}
                  variant="h6"
                >
                  {campaign.name}
                </Link>
              </Box>
            </LightTooltip>
            <RequiresRole
              allowedRoles={[
                "woop_admin",
                "creator",
                "support",
                "sales",
                "partner",
              ]}
            >
              <Typography color="text.disabled" variant="subtitle1">
                {campaign.organization ? campaign.organization.name : null}
              </Typography>
            </RequiresRole>
          </Stack>

          <CampaignActionsAndStatus campaign={campaign} />
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          mb={3}
          spacing={3}
        >
          <Stack alignItems="center" sx={{ flexGrow: 1 }}>
            <Typography variant={showChart ? "h4" : "h3"}>
              {hasFunnel ? qualifiedLeads : kpis[1].displayValue}
            </Typography>
            <Typography color="text.disabled" variant="subtitle2">
              {hasFunnel ? "Qualifiziert" : String(kpis[1].name)}
            </Typography>
          </Stack>
          {showChart ? (
            <ChartContainer sx={{ py: 2, overflow: "visible", flexGrow: 1 }}>
              <ContactsPerDayChart chartData={chartData} />
            </ChartContainer>
          ) : null}
        </Stack>
        <Stack mb={2} spacing={1}>
          <LinearProgress value={daysElapsedPercentage} variant="determinate" />
          <Stack
            alignItems="start"
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography color="text.secondary" variant="subtitle2">
              {start.format("L")}
            </Typography>
            <Typography color="text.secondary" variant="subtitle2">
              {campaignHasDefinedEnd ? end.format("L") : EM_DASH}
            </Typography>
          </Stack>
        </Stack>
        <Divider
          flexItem
          sx={{ borderStyle: "dashed", mb: 2, mx: -3 }}
          variant="fullWidth"
        />
        {hasFunnel ? <KPIDisplay kpis={displayedKpis} /> : null}
        {hasAdCampaign ? <KPIDisplay kpis={platformKPIs} /> : null}
        {hasAdCampaign || hasFunnel ? (
          <Divider
            sx={{ borderStyle: "dashed", mb: 2, mx: -3 }}
            variant="fullWidth"
          />
        ) : null}
        <Stack sx={{ alignItems: "start" }}>
          <CampaignInfoLabels campaignInfo={campaignInfo} truncateLabel />
          <RequiresPermission permission="edit:campaign">
            <AdminCampaignActionsExternalTools campaign={campaign} />
          </RequiresPermission>
          <AdPlatformBar platforms={adPlatforms} />
        </Stack>
      </CardContent>
    </Card>
  );
}
