import type { BoxProps } from "@mui/material";
import {
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { ErrorBoundary } from "@sentry/react";
import { useUser } from "hooks/useUser";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Iconify from "./Iconify";
import AccountPopover from "./navbar/AccountPopover";

interface PageProps extends BoxProps {
  readonly children: ReactNode;

  readonly pageActions?: ReactNode;

  readonly title?: string;

  readonly showBackButton?: boolean;

  readonly backButtonTo?: string;

  readonly scrollable?: boolean;

  readonly paddingBottom?: number;

  readonly badges?: ReactNode;
}

interface PageHeaderProps extends BoxProps {
  scrollable: boolean;
  paddingBottom?: number;
  paddingTop?: number;
}

const PageHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "scrollable",
})<PageHeaderProps>(({ theme, scrollable, paddingBottom, paddingTop }) => ({
  background: `linear-gradient(0deg, ${alpha(
    theme.palette.background.default,
    0,
  )} 0%,${alpha(theme.palette.background.default, 1)} 15%)`,
  display: "flex",
  alignItems: "center",
  flexWrap: "nowrap",
  ...(scrollable
    ? {
        position: "sticky",
        left: 0,
        right: 0,
        top: 0,
        zIndex: 8,
      }
    : { flexShrink: 0 }),
  overflow: "hidden",
  padding: theme.spacing(
    paddingTop === undefined ? 3 : paddingTop,
    3,
    paddingBottom === undefined ? 3 : paddingBottom,
    3,
  ),
}));

/** font size * line height + padding of alert at the top */
export const SENSITIVE_DATA_WARNING_HEIGHT = "(1.5rem + 40px)";
const PAGE_HEIGHT_WITHOUT_SENSITIVE_DATA_WARNING = `calc(100vh - ${SENSITIVE_DATA_WARNING_HEIGHT})`;

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      title = "",
      pageActions,
      showBackButton = false,
      backButtonTo,
      scrollable = true,
      paddingBottom = 2,
      badges,
      ...other
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const { showSensitiveDataWarning } = useUser();

    return (
      <Box
        ref={ref}
        sx={{
          width: "100%",
          position: "relative",
          ...(!scrollable
            ? {
                height: showSensitiveDataWarning
                  ? PAGE_HEIGHT_WITHOUT_SENSITIVE_DATA_WARNING
                  : "100vh",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }
            : {}),
        }}
        {...other}
      >
        <Helmet>
          <title>{`${title} | everbay `}</title>
        </Helmet>

        <PageHeader
          paddingBottom={paddingBottom}
          paddingTop={showSensitiveDataWarning ? 2 : undefined}
          scrollable={scrollable}
        >
          {showBackButton ? (
            <IconButton
              color="primary"
              onClick={() => {
                if (backButtonTo != null) {
                  navigate(backButtonTo);
                } else {
                  navigate(-1);
                }
              }}
              sx={{ ml: -2, mr: 0.5 }}
            >
              <Iconify icon="heroicons:chevron-left-solid" />
            </IconButton>
          ) : null}
          <Typography variant="h4">{title}</Typography>

          <Box sx={{ flexGrow: 1, minWidth: 0 }} />
          <Stack alignItems="center" direction="row" gap={2}>
            {pageActions}
            {pageActions != null && (
              <Divider orientation="vertical" variant="middle" />
            )}
            <AccountPopover />
          </Stack>
        </PageHeader>
        {badges ? <Box ml={2.5}>{badges}</Box> : null}
        <Container
          maxWidth={false}
          sx={{
            pb: 2,
            height: "100%",
            ...(!scrollable
              ? { minHeight: 0, overflow: "hidden", flexGrow: 1 }
              : {}),
          }}
        >
          <ErrorBoundary
            fallback={({ error, componentStack, resetError }) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexGrow: 1,
                  gap: 2,
                }}
              >
                <Typography variant="h5">
                  Beim Anzeigen dieser Seite ist ein Fehler aufgetreten
                </Typography>
                <Typography>
                  Versuchen Sie, die Seite zu aktualisieren.
                </Typography>
                <Typography variant="body2">
                  Wenn das Problem weiterhin besteht, wenden Sie sich bitte mit
                  der folgenden Fehlermeldung an den Support:
                </Typography>
                <Typography
                  fontFamily="monospace"
                  sx={{ bgcolor: "grey.300", py: 1, px: 2, borderRadius: 2 }}
                  whiteSpace="pre"
                >
                  {error.message}
                </Typography>
              </Box>
            )}
          >
            {children}
          </ErrorBoundary>
        </Container>
      </Box>
    );
  },
);

export default Page;
