import { ApolloError } from "@apollo/client";
import { CardContent } from "@mui/material";
import { composeName } from "components/contact_board/shared/utils";
import { DataLoadingDisplay } from "components/DataLoadingDisplay";
import type {
  ContactFragment,
  LlmChatBotConversationFragment,
} from "generated/graphql";
import {
  useGetChatContentLazyQuery,
  useGetLlmChatBotContentSubscription,
  usePollLlmChatBotContentQuery,
} from "generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "store";
import type { ChatMessage } from "./components/ChatMessageTile";
import { ChatTabContent } from "./components/ChatTabContent";

export function ChatTab({
  contact,
  chatBotKind,
}: {
  readonly contact: ContactFragment;
  readonly chatBotKind: "v1" | "v2" | null;
}) {
  const [messages, setMessages] = useState<ChatMessage[] | undefined>(
    undefined,
  );

  const websocketsSupported = useSelector((state) => state.websockets);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  const [getV1Messages] = useGetChatContentLazyQuery({
    variables: { contact_id: contact.id },
  });

  const {
    data: llmChatBotDataFromSubscription,
    loading: llmChatBotSubscriptionLoading,
    error: llmChatBotSubscriptionError,
  } = useGetLlmChatBotContentSubscription({
    variables: {
      contactId: contact.id,
    },
    skip: !websocketsSupported,
  });

  const {
    data: llmChatBotDataFromPolling,
    loading: llmChatBotPollingLoading,
    error: llmChatBotPollingError,
  } = usePollLlmChatBotContentQuery({
    variables: {
      contactId: contact.id,
    },
    skip: websocketsSupported,
    pollInterval: 30000,
  });

  const llmChatBotData = websocketsSupported
    ? llmChatBotDataFromSubscription
    : llmChatBotDataFromPolling;
  const llmChatBotLoading = websocketsSupported
    ? llmChatBotSubscriptionLoading
    : llmChatBotPollingLoading;
  const llmChatBotError = websocketsSupported
    ? llmChatBotSubscriptionError
    : llmChatBotPollingError;

  function mapConversationMessages(
    messages: LlmChatBotConversationFragment["messages"] | undefined,
  ): ChatMessage[] | undefined {
    return messages?.map((chat) => {
      const text = chat.text;
      const sentAt = moment(chat.sent_at);
      const id = chat.id;
      let message: ChatMessage;
      if (chat.sent_by === "APPLICANT") {
        message = {
          id,
          text,
          sentAt,
          isSentByApplicant: true,
          applicantFullName: composeName(contact.first_name, contact.last_name),
        };
      } else {
        message = {
          id,
          text,
          sentAt,
          isSentByApplicant: false,
        };
      }
      return message;
    });
  }

  async function getMessages(): Promise<ChatMessage[] | undefined> {
    try {
      const { data } = await getV1Messages();
      const messages = data?.chat_bot_message;
      return messages?.map((chat) => {
        const text = chat.data["body"];
        const sentAt = moment(chat.created_at);
        const id = chat.id;
        let message: ChatMessage;
        if (chat.sender_type === "customer") {
          message = {
            id,
            text,
            sentAt,
            isSentByApplicant: true,
            applicantFullName: composeName(
              contact.first_name,
              contact.last_name,
            ),
          };
        } else {
          message = {
            id,
            text,
            sentAt,
            isSentByApplicant: false,
          };
        }
        return message;
      });
    } catch (e) {
      console.error(e);
      if (e instanceof ApolloError) {
        setError(e);
      }
      return undefined;
    }
  }

  async function loadV1Messages() {
    try {
      setLoading(true);
      const messages = await getMessages();
      setMessages(messages);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (chatBotKind === "v1") {
      loadV1Messages().then();
    }
  }, [contact.id, chatBotKind]);

  useEffect(() => {
    if (chatBotKind !== "v2") {
      return;
    }
    setLoading(llmChatBotLoading);
  }, [llmChatBotLoading]);

  useEffect(() => {
    if (chatBotKind !== "v2") {
      return;
    }
    setError(llmChatBotError);
  }, [llmChatBotError]);

  useEffect(() => {
    if (chatBotKind !== "v2") {
      return;
    }
    const messages = mapConversationMessages(
      llmChatBotData?.bot_conversation?.at(0)?.messages,
    );
    setMessages(messages);
  }, [llmChatBotData, chatBotKind]);

  return (
    <CardContent
      sx={{
        height: "100%",
      }}
    >
      <DataLoadingDisplay
        error={error}
        loading={loading}
        refetch={async () => {
          if (chatBotKind !== "v1") {
            return;
          }
          await loadV1Messages();
        }}
      >
        <ChatTabContent messages={messages} />
      </DataLoadingDisplay>
    </CardContent>
  );
}
