import { CampaignFragment } from "generated/graphql";
import { DeepPartial } from "types";
import { getMediaBudget } from "./getMediaBudget";
import { calculateRemainingMediaBudget } from "./calculateRemainingMediaBudget";

export function calculateInternalCpl(
  campaign: DeepPartial<CampaignFragment>,
  qualifiedLeads: number,
) {
  const mediaBudget = getMediaBudget(campaign);
  const remainingMediaBudget = calculateRemainingMediaBudget(campaign);
  if (mediaBudget == null || remainingMediaBudget == null) {
    return undefined;
  }
  const spent = mediaBudget - remainingMediaBudget;

  if (qualifiedLeads > 0) {
    return spent / qualifiedLeads;
  }
  return undefined;
}
