import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Link from "@tiptap/extension-link";
import { Typography as TiptapTypography } from "@tiptap/extension-typography";
import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { EditorActions } from "components/mails/EditorActions";
import type { EmailTemplate } from "components/mails/EmailTemplatesEditor";
import { useEffect } from "react";

const StyledEditorContent = styled(EditorContent)`
  .tiptap {
    margin: 0;
    padding: 1rem;
    height: 500px;
    overflow-y: scroll;

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 32px;
    }

    hr {
      border: none;
      border-bottom: 2px dotted #d0d0d0;
    }
  }

  [contenteditable="false"] {
    color: #999;
    cursor: not-allowed;
  }
`;

interface MarkdownEditorProps {
  readonly value: string;
  readonly setValue: (v: string) => void;
  readonly enabled: boolean;
  readonly placeholders?: EmailTemplate["placeholders"];
}

export function EmailEditor({
  value,
  setValue,
  enabled,
  placeholders,
}: MarkdownEditorProps) {
  const editor = useEditor({
    editable: enabled,
    extensions: [
      StarterKit,
      TiptapTypography,
      Link.configure({
        linkOnPaste: true,
        protocols: ["mailto"],
        openOnClick: false,
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      setValue(editor.getHTML());
    },
  });

  useEffect(() => {
    if (!editor) {
      return undefined;
    }

    editor.setEditable(enabled);
  }, [editor, enabled]);

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: "grey.300",
        overflow: "hidden",
      }}
    >
      {editor ? (
        <EditorActions
          editor={editor}
          enabled={enabled}
          placeholders={placeholders}
        />
      ) : null}
      <StyledEditorContent editor={editor} />
    </Box>
  );
}
