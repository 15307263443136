import type { ApolloLink, Operation } from "@apollo/client";
import {
  ApolloClient,
  concat,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import type { Scope } from "@sentry/react";
import { captureException, withScope } from "@sentry/react";
import type { StrictTypedTypePolicies } from "generated/graphql";
import { createClient } from "graphql-ws";
import type { GraphQLError } from "graphql/error";
import { Kind } from "graphql/language";
import { store } from "store";
import { websocketActions } from "store/slices/websocket";

const typePolicies: StrictTypedTypePolicies = {
  organization_region: {
    keyFields: ["slug", "organization_id"],
  },
  organization_location: {
    keyFields: ["slug", "organization_id"],
  },
  organization: {
    keyFields: ["id"],
  },
  organization_settings: {
    keyFields: ["organization_id"],
  },
  board_contact_board_column: {
    keyFields: ["id"],
  },
  board_contact_board_entry: {
    keyFields: ["id"],
  },
  campaign_routing_destination: {
    keyFields: ["location_id", "type"],
  },
  campaign_funnel: {
    keyFields: ["campaign_id"],
  },
  campaign_email_template_overwrite: {
    keyFields: ["campaign_id"],
  },
};

function setOperationContext(operation: Operation, scope: Scope) {
  const mainDef = getMainDefinition(operation.query);
  scope.setTag("operationName", operation.operationName);
  scope.setTag(
    "kind",
    mainDef.kind === Kind.OPERATION_DEFINITION ? mainDef.operation : "fragment",
  );
  scope.setContext("GraphQL Operation", {
    query: operation.query.loc?.source.body,
    variables: operation.variables,
  });
}

export function getApolloClient(
  authLink: ApolloLink,
  getHeaders: () => Promise<Record<string, string>>,
) {
  const httpUri =
    process.env.REACT_APP_GRAPHQL_ENDPOINT != null
      ? process.env.REACT_APP_GRAPHQL_ENDPOINT
      : `http://localhost:8080/v1/graphql`;

  const wsUri =
    process.env.REACT_APP_GRAPHQL_WS_ENDPOINT != null
      ? process.env.REACT_APP_GRAPHQL_WS_ENDPOINT
      : `ws://localhost:8080/v1/graphql`;

  const httpLink = new HttpLink({
    uri: httpUri,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUri,
      connectionParams: async () => {
        return {
          headers: {
            ...(await getHeaders()),
          },
          reconnect: true,
          lazy: true,
        };
      },
      retryAttempts: 2,
      on: {
        error: (error: unknown) => {
          console.error("Error in GraphQL WS Link");
          store.dispatch(websocketActions.disableWebsockets()); // Dispatch the disableWebsockets action
          console.error(error);
          captureException(error);
        },
        connected: () => {
          console.log("Connected to GraphQL WS");
          store.dispatch(websocketActions.enableWebsockets());
        },
      },
    }),
  );

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error: GraphQLError) => {
        if (error == null) return;
        console.error(error);
        withScope((scope) => {
          setOperationContext(operation, scope);
          scope.setContext("GraphQL Error", {
            message: error.message,
            locations: error.locations,
            extensions: error.extensions,
          });
          if (error.path != null) {
            scope.addBreadcrumb({
              category: "query-path",
              message: error.path.join(" > "),
              level: "debug",
            });
          }
          // Sanitize the error object
          const sanitizedError = new Error(error.message, {
            cause: error,
          });
          sanitizedError.stack = error.stack;
          sanitizedError.name = error.name;
          captureException(sanitizedError);
        });
      });
    }
    if (networkError) {
      console.log("Network Error in GraphQL Link");
      console.error(networkError);
      withScope((scope) => {
        setOperationContext(operation, scope);
        scope.setContext("Network Error", {
          name: networkError.name,
          message: networkError.message,
          stack: networkError.stack,
          cause: networkError.cause,
        });
        captureException(networkError);
      });
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    concat(errorLink, httpLink),
  );

  return new ApolloClient({
    link: concat(authLink, splitLink),
    cache: new InMemoryCache({ typePolicies }),
    connectToDevTools: true,
  });
}
