import { Box, List, ListSubheader } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFeatureFlags } from "hooks/useFeatureFlag";
import { useUser } from "hooks/useUser";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { NavListRoot } from "./NavList";
import { NavListProps, NavSectionProps } from "./type";

export const ListSubheaderStyle: any = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

function findBestMatchingTab(
  navConfig: NavListProps[],
  pathname: string,
): NavListProps | undefined {
  let bestMatchingTab: [number, NavListProps] | undefined = undefined;
  const pathSegments = pathname.split("/");
  for (const tab of navConfig) {
    if (tab.path == null) {
      continue;
    }
    const tabSegments = tab.path.split("/");
    let matchCount = 0;
    for (let i = 0; i < tabSegments.length; i++) {
      if (pathSegments[i] === tabSegments[i]) {
        matchCount++;
      } else {
        break;
      }
    }
    if (bestMatchingTab == null || matchCount > bestMatchingTab[0]) {
      bestMatchingTab = [matchCount, tab];
    }
  }
  return bestMatchingTab?.[1];
}

export default function NavSection({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  const { pathname } = useLocation();
  // todo: fix this once we have more than one group
  const activeList = useMemo(() => {
    return findBestMatchingTab(navConfig[0].items, pathname);
  }, [navConfig, pathname]);

  const { hasPermission } = useUser();

  const featureFlags = useFeatureFlags();
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.id} disablePadding sx={{ px: 1, overflow: "hidden" }}>
          {group.items.map((list) => {
            const isEnabled =
              list.featureFlag == null ? true : featureFlags[list.featureFlag];
            if (!isEnabled) {
              return null;
            }
            if (
              list.requiredPermission != null &&
              !hasPermission(list.requiredPermission)
            ) {
              return null;
            }
            return (
              <NavListRoot
                key={`nav-item-${list.title}`}
                active={list === activeList}
                list={list}
                isCollapse={isCollapse}
              />
            );
          })}
        </List>
      ))}
    </Box>
  );
}
