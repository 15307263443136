import type { LogtoConfig } from "@logto/react";
import { LogtoProvider } from "@logto/react";
import { ThemeProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { defaultCampaignFilterKeys } from "components/campaigns/filter/components/filter/filter-configuration";
import { FilterContextProvider } from "components/campaigns/filter/FilterContext";
import { SortContextProvider } from "components/campaigns/filter/SortContext";
import { ContactBoardContextProvider } from "contexts/ContactBoardContext";
import { ImpersonationProvider } from "contexts/ImpersonationContext";
import { UserContextProvider } from "contexts/UserContext";
import moment from "moment";
// @ts-expect-error
import deLocale from "moment/locale/de";
import { SnackbarProvider } from "notistack";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "@fontsource-variable/public-sans";
import { store } from "./store";
import { theme } from "theme/theme";
import App from "./App";
import LogtoApolloProvider from "./contexts/LogtoApolloContext";
import reportWebVitals from "./reportWebVitals";
import "sentry";
import "hubspot-chat";

moment.updateLocale("de", deLocale);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const config: LogtoConfig = {
  endpoint: "https://auth.everbay.de/",
  appId: "lnMAq3onT83h93P94AiLm",
  resources: ["https://api.gowoop.de", "https://backend.gowoop.de"],
  scopes: [
    "email",
    "custom_data",
    "read:user",
    "write:user",
    "update:user",
    "delete:user",
  ],
};

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <LogtoProvider config={config}>
          <ImpersonationProvider>
            <UserContextProvider>
              <SnackbarProvider
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                autoHideDuration={3000}
                maxSnack={1}
                preventDuplicate
              >
                <BrowserRouter>
                  <LogtoApolloProvider>
                    {/* https://mui.com/x/react-date-pickers/adapters-locale/#with-moment */}
                    <LocalizationProvider
                      adapterLocale="de"
                      dateAdapter={AdapterMoment}
                    >
                      <FilterContextProvider
                        defaultFilters={defaultCampaignFilterKeys}
                      >
                        <SortContextProvider>
                          <ContactBoardContextProvider>
                            <App />
                          </ContactBoardContextProvider>
                        </SortContextProvider>
                      </FilterContextProvider>
                    </LocalizationProvider>
                  </LogtoApolloProvider>
                </BrowserRouter>
              </SnackbarProvider>
            </UserContextProvider>
          </ImpersonationProvider>
        </LogtoProvider>
      </ThemeProvider>
    </ReduxProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
