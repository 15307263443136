import { createSlice } from "@reduxjs/toolkit";

const websocketSlice = createSlice({
  name: "websocket",
  initialState: true, // assuming WebSocket connections are allowed by default
  reducers: {
    enableWebsockets: () => true,
    disableWebsockets: () => false,
  },
});

export const { actions: websocketActions, reducer: websocketReducer } =
  websocketSlice;
