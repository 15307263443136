import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import type { KPI } from "../../../hooks/campaign/getCampaignKPIs";

export function KPIDisplay(props: { readonly kpis: KPI[] }) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      divider={<Divider flexItem orientation="vertical" sx={{ p: 0, my: 1 }} />}
      flexWrap="nowrap"
      justifyContent="space-between"
      mb={2}
      sx={{ gap: 2 }}
    >
      {props.kpis.map((kpi) => {
        return (
          <Box
            key={`KPIDisplay-${kpi.name}`}
            sx={{
              height: "auto",
              textAlign: "center",
              flexBasis: (1 / props.kpis.length) * 100 + "%",
              flexShrink: 1,
            }}
          >
            <Stack>
              <Typography
                color="text.disabled"
                sx={{ mb: 0.75 }}
                variant="caption"
              >
                {kpi.name}
              </Typography>
              <Typography color="text.primary" variant="subtitle1">
                {kpi.showDash ? "-" : kpi.displayValue}
              </Typography>
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
}
