import type { ContactBoardEntryFragment } from "../../generated/graphql";

export type ApplicantSearch =
  | {
      isFullNameSearch: true;
      firstName: string;
      lastName: string;
    }
  | {
      isFullNameSearch: false;
      firstOrLastName: string;
    };

export function getApplicantNameSearch(
  query: string,
): ApplicantSearch | undefined {
  if (query == "") {
    return;
  }
  const splitQuery = query.split(" ");
  if (splitQuery.length == 1) {
    return {
      isFullNameSearch: false,
      firstOrLastName: splitQuery[0].trim(),
    };
  }
  return {
    isFullNameSearch: true,
    firstName: splitQuery[0].trim(),
    lastName: splitQuery[1].trim(),
  };
}

export function isApplicantSearchMatch(
  applicantSearch: ApplicantSearch | undefined,
  applicant: ContactBoardEntryFragment["contact"],
): boolean {
  if (applicantSearch == undefined) {
    return true;
  }
  const firstName = applicant?.first_name?.toLowerCase();
  const lastName = applicant?.last_name?.toLowerCase();
  if (firstName == undefined || lastName == undefined) {
    return false;
  }
  if (applicantSearch.isFullNameSearch) {
    return (
      firstName.includes(applicantSearch.firstName.toLowerCase()) &&
      lastName.includes(applicantSearch.lastName.toLowerCase())
    );
  }
  return (
    firstName.includes(applicantSearch.firstOrLastName.toLowerCase()) ||
    lastName.includes(applicantSearch.firstOrLastName.toLowerCase())
  );
}
