import { NavListProps } from "./type";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

export function NavItemCollapsedChildrenMenu({
  item,
  open,
  onClose,
  anchorEl,
}: {
  item: NavListProps;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}) {
  const handleClose = () => {
    onClose();
  };
  const { hasPermission } = useUser();
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiMenu-paper": {
            bgcolor: "background.paper",
            border: "1px solid",
            borderColor: "divider",
            overflow: "auto",
            maxHeight: 300,
            minWidth: 200,
          },
        }}
      >
        {item.children
          ?.filter(
            (child) =>
              child.requiredPermission == null ||
              hasPermission(child.requiredPermission),
          )
          .map((item) => (
            <MenuItem
              component={RouterLink as any}
              to={item.path}
              onClick={() => {
                handleClose();
              }}
              key={item.title}
            >
              {item.title}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
