import { Organization_Name_Prefix_Enum } from "generated/graphql";

//TODO create a shared module for functionality like this using the yarn workspace features
/**
 * Make sure that the methods in this file are aligned with the logic in the backend
 * Path: /backend/src/contact/emails/utils/get-organization-name-prefix-dative.ts
 */

/**
 * Generates the dative form of the organization name prefix using the article defined in the prefix and the preposition "bei"
 * @param namePrefix Prefix for the organization from the database.
 * @example
 *   Der -> bei dem
 *   Die -> bei der
 */
export function getOrganizationNamePrefixDative(
  namePrefix: Organization_Name_Prefix_Enum | null,
): string {
  if (namePrefix == null) {
    return "bei der";
  }
  switch (namePrefix) {
    case Organization_Name_Prefix_Enum.Der:
      return "beim";
    case Organization_Name_Prefix_Enum.Die:
      return "bei der";
    case Organization_Name_Prefix_Enum.Das:
      return "beim";
    case Organization_Name_Prefix_Enum.Kein:
      return "bei";
    case Organization_Name_Prefix_Enum.Am:
      return "am";
  }
}

export function getOrganizationName(organization: {
  short_name?: string;
  name: string;
}) {
  return organization.short_name ?? organization.name;
}
