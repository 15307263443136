import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, ClickAwayListener, OutlinedInput, Paper } from "@mui/material";
import Iconify from "../Iconify";
import { Board_Contact_Board_Column_Insert_Input } from "../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { COLUMN_NAME_PLACEHOLDER } from "./ContactBoardColumnToolBar";

const NEW_SECTION_PLACEHOLDER = "Spalte hinzufügen";

export default function AddContactColumn(props: {
  boardId: string;
  newColumnPosition: number;
  handleAddColumn: (newColumn: Board_Contact_Board_Column_Insert_Input) => void;
}) {
  const { boardId, newColumnPosition } = props;

  const nameRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      if (nameRef.current) {
        nameRef.current.focus();
      }
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCreateColumn = async () => {
    if (name === "") {
      handleClose();
      return;
    }

    const newColumn: Board_Contact_Board_Column_Insert_Input = {
      id: uuidv4(),
      board_id: boardId,
      name: name,
      position: newColumnPosition,
    };

    setName("");
    handleClose();
    await props.handleAddColumn(newColumn);
  };

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      await handleCreateColumn();
    }
  };

  return (
    <Paper
      sx={{
        bgcolor: "grey.50016",
        minWidth: 280,
        width: 280,
        p: 3,
        pt: 2,
        borderRadius: 2,
        alignSelf: "flex-start",
      }}
    >
      {!open && (
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          startIcon={<Iconify icon={"eva:plus-fill"} width={20} height={20} />}
          onClick={handleOpen}
          sx={{ textTransform: "none", borderRadius: 2 }}
        >
          {NEW_SECTION_PLACEHOLDER}
        </Button>
      )}

      {open && (
        <ClickAwayListener onClickAway={handleCreateColumn}>
          <OutlinedInput
            fullWidth
            placeholder={COLUMN_NAME_PLACEHOLDER}
            inputRef={nameRef}
            value={name}
            onChange={handleChangeName}
            onKeyUp={handleKeyUp}
            sx={{ typography: "h6" }}
          />
        </ClickAwayListener>
      )}
    </Paper>
  );
}
