import { ReactElement } from "react";
import { UserRole } from "../contexts/UserContext";
import { useUser } from "../hooks/useUser";
import Toggle from "./Toggle";

export function RoleToggle(props: {
  allowedRoles: UserRole[];
  allowed: ReactElement;
  else: ReactElement;
  load?: ReactElement;
}) {
  const { user } = useUser();
  if (user == null && props.load != null) {
    return props.load;
  }
  return (
    <Toggle
      showOn={
        user != null &&
        props.allowedRoles.find((role) => user.roles.includes(role)) != null
      }
      on={props.allowed}
      off={props.else}
    />
  );
}
