export const INSIGHTS_BLUE = "#009EF7";
export const TRANSPARENT_INSIGHTS_BLUE = "rgb(0, 158, 247, 0.4)";
export const INSIGHTS_ORANGE = "#FCBF54";
export const INSIGHTS_YELLOW = "#F5D67A";
export const INSIGHTS_GREEN = "#C1E5AE";
export const INSIGHTS_GREEN_DARK = "#A3D987";
export const INSIGHTS_RED = "#EA6251";

export const INSIGHT_COLORS = [
  INSIGHTS_BLUE,
  INSIGHTS_ORANGE,
  INSIGHTS_YELLOW,
  INSIGHTS_GREEN_DARK,
  INSIGHTS_RED,
];
