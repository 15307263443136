import { ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useLogto } from "@logto/react";
import { useContext, useMemo } from "react";
import { getApolloClient } from "../apollo";
import type { ImpersonationOverrides } from "./ImpersonationContext";
import ImpersonationContext from "./ImpersonationContext";
import type { User, UserRole } from "./UserContext";
import { USER_ROLE_PRIORITY, UserContext } from "./UserContext";

interface ImpersonationHeaders {
  "Organization-Override"?: string;
  "Region-Override"?: string[];
  "Location-Override"?: string[];
}

const getImpersonationHeaders = (
  impersonation: ImpersonationOverrides,
): ImpersonationHeaders => {
  return {
    "Organization-Override": impersonation.organization ?? undefined,
    "Region-Override":
      impersonation.region != null ? [impersonation.region] : undefined,
    "Location-Override":
      impersonation.location != null ? [impersonation.location] : undefined,
  };
};

export function getExpectedRole(availableRoles: UserRole[]): UserRole {
  const sortedRoles = sortUserRolesByPriority(availableRoles);
  const expectedRole = sortedRoles[0];
  return expectedRole as UserRole;
}

function getExpectedRoleHeader(user: User | null): {
  "Expected-Role": UserRole;
} {
  const expectedRole = user == null ? "customer" : getExpectedRole(user.roles);
  return { "Expected-Role": expectedRole };
}

function sortUserRolesByPriority(roles: string[]) {
  return roles
    .filter((value) => USER_ROLE_PRIORITY.includes(value))
    .sort(
      (a, b) => USER_ROLE_PRIORITY.indexOf(a) - USER_ROLE_PRIORITY.indexOf(b),
    );
}

export default function LogtoApolloProvider(props: any) {
  const { getAccessToken } = useLogto();

  const impersonationOverrides = useContext(ImpersonationContext);
  const { user } = useContext(UserContext);

  // Ensure that the client is only created once.
  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const hasuraHeaders = {
        ...headers,
        ...(await getHeaders()),
      };

      return {
        headers: hasuraHeaders,
      };
    });

    return getApolloClient(authLink, getHeaders);
  }, [getAccessToken, impersonationOverrides, user]);

  async function getHeaders() {
    const token = await getAccessToken("https://api.gowoop.de");

    const impersonationHeaders = getImpersonationHeaders(
      impersonationOverrides,
    );
    const strippedImpersonationHeaders = Object.fromEntries(
      Object.entries(impersonationHeaders).filter(
        ([_, value]) => value !== undefined,
      ),
    );

    return {
      ...getExpectedRoleHeader(user),
      ...strippedImpersonationHeaders,
      Authorization: `Bearer ${token ?? ""}`,
    };
  }

  return <ApolloProvider client={client} {...props} />;
}
