import { Draggable } from "react-beautiful-dnd";
import type { ContactBoardEntryFragment } from "../../generated/graphql";
import { ContactBoardCard } from "./ContactBoardCard";
import { SharedUser } from "./contactBoardUtils";
import { CustomDragProps } from "./BoardColumn";

export default function ContactBoardEntryDraggable({
  contactBoardEntry,
  index,
  isDeclined,
}: {
  readonly contactBoardEntry: ContactBoardEntryFragment;
  readonly isDeclined: boolean;
  readonly index: number;
}) {
  return (
    <Draggable
      draggableId={contactBoardEntry.id}
      index={index}
      isDragDisabled={isDeclined}
    >
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {contactBoardEntry ? (
            <ContactBoardCard
              contactBoardEntry={contactBoardEntry}
              isDeclined={isDeclined}
              key={`contact-board-card-${contactBoardEntry.id}`}
            />
          ) : null}
        </div>
      )}
    </Draggable>
  );
}
