import React from "react";
import Box from "@mui/material/Box";
import { composeBackendUrl } from "../../../../../../hooks/useBackendRequest";
import { Card, IconButton, Stack, Typography } from "@mui/material";
import { formatDate } from "../../../../shared/utils";
import Iconify from "../../../../../Iconify";
import { AttachmentOptionsMenu } from "./AttachmentOptionsMenu";

function getIconFromFileExtension(extension: string) {
  const size = 25;
  switch (extension) {
    case "pdf":
      return <Iconify icon={"uiw:file-pdf"} color={"red"} fontSize={size} />;
    case "png" || "jpg" || "jpeg":
      return (
        <Iconify icon={"uiw:file-image"} color={"green"} fontSize={size} />
      );
    default:
      return <Iconify icon={"uiw:file"} color={"blue"} fontSize={size} />;
  }
}

export function AttachmentCard({
  attachment,
}: {
  attachment: {
    id: string;
    value?: string | null;
    question?: { title: string; id: string } | null;
    createdAt: string;
  };
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const fileName = "Lebenslauf.pdf";
  const attachmentUrl = attachment.value;
  if (attachmentUrl == null) return <Box key={attachment.id} />;
  const attachmentAction = async ({ type }: { type: "download" | "open" }) => {
    const perspectiveUrl = attachmentUrl;
    const response = await fetch(
      composeBackendUrl(
        `/application-relay/attachment?perspectiveUrl=${perspectiveUrl}&responseType=url`,
      ),
    );
    const downloadUrl = await response.text();
    const previewUrl = downloadUrl.replace("content=t:attachment", "preview");
    const fileUrl = type == "download" ? downloadUrl : previewUrl;
    return window.open(fileUrl, "_blank");
  };

  const isOpen = Boolean(anchorEl);
  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const close = () => {
    setAnchorEl(null);
  };
  return (
    <Card
      key={attachment.id}
      sx={{
        padding: "1rem",
        display: "flex",
        direction: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "50%",
        "&:hover": {
          cursor: "pointer",
          boxShadow: 2,
        },
      }}
      onClick={() => {
        if (isOpen) return;
        attachmentAction({ type: "open" });
      }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Stack direction="row" flexGrow={1} alignItems={"center"} gap={1}>
          {getIconFromFileExtension(fileName.split(".").pop() ?? "")}
          <Stack>
            <Typography
              variant={"body2"}
              textOverflow={"ellipsis"}
              fontWeight={"bolder"}
            >
              {fileName}
            </Typography>
            <Typography variant={"caption"} color={"text.secondary"}>
              {formatDate(attachment.createdAt)}
            </Typography>
          </Stack>
        </Stack>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            open(e);
          }}
        >
          <Iconify icon={"heroicons:ellipsis-vertical"}></Iconify>
        </IconButton>
        <AttachmentOptionsMenu
          isOpen={isOpen}
          onClose={close}
          anchorEl={anchorEl}
          downloadAttachment={() => {
            attachmentAction({ type: "download" });
          }}
          openAttachment={() => {
            attachmentAction({ type: "open" });
          }}
        />
      </Stack>
    </Card>
  );
}
