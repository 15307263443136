import { Card, Stack } from "@mui/material";
import type { KPI } from "hooks/campaign/getCampaignKPIs";
import InsightsStat from "../InsightsStat";

type Props = {
  readonly kpis: readonly KPI[];
};

export function ExternalHukKpiCard({ kpis }: Props) {
  return (
    <Card sx={{ width: "100%", height: "100%" }}>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={3}
        justifyContent="space-around"
        p={3}
        rowGap={2}
      >
        <InsightsStat kpi={kpis[0]} />
        <InsightsStat kpi={kpis[1]} />
        <InsightsStat kpi={kpis[2]} />
        <InsightsStat kpi={kpis[3]} />
        <InsightsStat kpi={kpis[4]} />
      </Stack>
    </Card>
  );
}

// NOTE: For internal KPIs you can take a look at KpiCards.tsx
