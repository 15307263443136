import { Stack } from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import { AdPlatformAvatar } from "components/campaigns/campaign_actions/AdPlatformAvatar";
import type { Ad_Platform_Enum } from "generated/graphql";

export function AdPlatformBar({
  platforms,
}: {
  readonly platforms: Ad_Platform_Enum[];
}) {
  return (
    <Stack direction="row" justifyContent="end" mt={1} width="100%">
      <AvatarGroup>
        {platforms.map((platform) => (
          <AdPlatformAvatar key={platform} platform={platform} size={32} />
        ))}
      </AvatarGroup>
    </Stack>
  );
}
