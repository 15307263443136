import { Box, IconButton, Stack, Typography } from "@mui/material";
import type { ContactBoardEntryFragment } from "generated/graphql";
import {
  useDeleteApplicantMutation,
  useHardDeleteApplicantMutation,
} from "generated/graphql";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import Iconify from "../../Iconify";
import { LightTooltip } from "../../LigthTooltip";
import { RequiresPermission } from "../../RequiresPermission";
import { RequiresRole } from "../../RequiresRole";
import type { CustomDragProps } from "../BoardColumn";
import { LeadingIcon } from "../LeadingIcon";
import { CB_GREEN } from "../colors";
import { composeName, formatDate } from "../shared/utils";
import { UserProfileImage } from "../shared/UserProfileImage";
import { DeclineContactButton } from "./DeclineContactButton";
import { OriginCampaignLink } from "../shared/OriginCampaignLink";

function HardDeleteApplicantButton({
  applicantId,
  onDeleteSuccess,
}: {
  readonly applicantId: string;
  readonly onDeleteSuccess: () => void;
}) {
  const [deleteApplicant] = useHardDeleteApplicantMutation();

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  async function handleClick() {
    const shouldDelete = await confirm({
      title: "Bewerber unwiderruflich löschen?",
      description:
        "Der Bewerber wird aus der Datenbank entfernt und kann nicht wiederhergestellt werden.",
      confirmationText: "Unwiderruflich löschen",
      cancellationText: "Abbrechen",
    })
      .then(() => true)
      .catch(() => false);
    if (!shouldDelete) {
      return;
    }
    deleteApplicant({ variables: { applicant_id: applicantId } })
      .then(() => {
        enqueueSnackbar("Bewerber erfolgreich gelöscht", {
          variant: "success",
        });
        onDeleteSuccess();
      })
      .catch((e) => {
        console.error(`Error deleting applicant: ${e}`);
        enqueueSnackbar("Fehler beim Löschen des Bewerbers", {
          variant: "error",
        });
      });
  }

  return (
    <IconButton onClick={handleClick}>
      <LightTooltip title="Bewerber unwiderruflich löschen">
        <Iconify icon="heroicons:archive-box-x-mark" />
      </LightTooltip>
    </IconButton>
  );
}

function SoftDeleteApplicantButton({
  applicantId,
  onDeleteSuccess,
}: {
  readonly applicantId: string;
  readonly onDeleteSuccess: () => void;
}) {
  const [deleteApplicant] = useDeleteApplicantMutation();

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  async function handleClick() {
    const shouldDelete = await confirm({
      title: "Bewerber löschen?",
      description: "Sind Sie sicher, dass Sie diesen Bewerber löschen möchten?",
      confirmationText: "Löschen",
      cancellationText: "Abbrechen",
    })
      .then(() => true)
      .catch(() => false);
    if (!shouldDelete) {
      return;
    }
    deleteApplicant({ variables: { applicant_id: applicantId } })
      .then(() => {
        enqueueSnackbar("Bewerber erfolgreich gelöscht", {
          variant: "success",
        });
        onDeleteSuccess();
      })
      .catch((e) => {
        console.error(`Error deleting applicant: ${e}`);
        enqueueSnackbar("Fehler beim Löschen des Bewerbers", {
          variant: "error",
        });
      });
  }

  return (
    <IconButton onClick={handleClick}>
      <LightTooltip title="Bewerber löschen">
        <Iconify icon="heroicons:trash" />
      </LightTooltip>
    </IconButton>
  );
}

export function ContactPopupHeader({
  contactEntry,
  isDeclined,
  customDragProps,
  columnName,
  onClose,
  showOriginCampaign = false,
}: {
  readonly contactEntry: ContactBoardEntryFragment;
  readonly isDeclined: boolean;
  readonly customDragProps: CustomDragProps;
  readonly columnName: string;
  readonly onClose: () => void;
  readonly showOriginCampaign: boolean;
}) {
  const name = composeName(
    contactEntry.contact!.first_name,
    contactEntry.contact!.last_name,
  );

  const originCampaign = contactEntry.contact?.origin_campaign;
  return (
    <Stack direction="row" mb={3} width="100%">
      <Stack gap={2} width="100%">
        <Stack
          alignItems="start"
          direction="row"
          gap={2}
          justifyContent="end"
          sx={{ alignItems: "center" }}
          width="100%"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LeadingIcon
              icon={
                <UserProfileImage
                  firstName={contactEntry.contact!.first_name}
                  lastName={contactEntry.contact!.last_name}
                  size={40}
                />
              }
            />
            <Stack alignItems="start" direction="row">
              <Typography variant="h5">{name}</Typography>
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack alignItems="center" direction="row" gap={1}>
            <DeclineContactButton
              contactEntry={contactEntry}
              customDragProps={customDragProps}
              isDeclined={isDeclined}
            />
            <RequiresPermission permission="delete:applicant">
              <SoftDeleteApplicantButton
                applicantId={contactEntry.contact!.id}
                onDeleteSuccess={onClose}
              />
            </RequiresPermission>
            <RequiresRole allowedRoles={["woop_admin"]}>
              <HardDeleteApplicantButton
                applicantId={contactEntry.contact!.id}
                onDeleteSuccess={onClose}
              />
            </RequiresRole>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          px={1}
          width="100%"
        >
          <LightTooltip title="Datum, an dem die Bewerbung eingangen ist">
            <LeadingIcon
              icon={
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    backgroundColor: CB_GREEN,
                  }}
                >
                  <Typography sx={{ color: "white" }} variant="body2">
                    !
                  </Typography>
                </Stack>
              }
            >
              <Typography color="text.secondary" variant="body2">
                {formatDate(contactEntry.contact!.converted_at ?? undefined)}
              </Typography>
            </LeadingIcon>
          </LightTooltip>
          {columnName ? (
            <LightTooltip title={`Spalte zu der ${name} zugeordnet ist`}>
              <Stack alignItems="center" direction="row" gap={1}>
                <Iconify height={22} icon="heroicons:view-columns" width={22} />
                <Typography
                  color="text.secondary"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                  variant="body2"
                >
                  {columnName}
                </Typography>
              </Stack>
            </LightTooltip>
          ) : null}
        </Stack>
        {showOriginCampaign && (
          <Typography variant="body2">
            Beworben über{" "}
            <OriginCampaignLink originCampaign={originCampaign ?? null} />
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
