import { Permission, UserRole } from "../contexts/UserContext";
import { PermissionToggle } from "../components/PermissionToggle";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { RoleToggle } from "../components/RoleToggle";

export function RoleGuard({
  allowedRoles,
  children,
  hideUnauthorizedMessage,
}: {
  allowedRoles: UserRole[];
  children?: React.ReactNode;
  hideUnauthorizedMessage?: boolean;
}) {
  return (
    <RoleToggle
      allowedRoles={allowedRoles}
      allowed={<>{children ?? <Outlet />}</>}
      else={hideUnauthorizedMessage ? <></> : <UnauthorizedDisplay />}
      load={<Outlet />}
    />
  );
}

export function PermissionGuard({
  requiredPermission,
  children,
}: {
  requiredPermission: Permission;
  children?: React.ReactNode;
}) {
  return (
    <PermissionToggle
      permission={requiredPermission}
      allowed={children ?? <Outlet />}
      else={<UnauthorizedDisplay />}
      load={<Outlet />}
    />
  );
}

export function UnauthorizedDisplay(props: { details?: string }) {
  const navigate = useNavigate();
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
      gap={1}
    >
      <Typography variant={"h4"}>
        Es tut uns leid, aber Sie haben keine Berechtigung, diese Seite zu
        sehen.
      </Typography>
      {props.details && (
        <Typography variant={"body1"}>{props.details}</Typography>
      )}
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={() => navigate("/")}
        sx={{
          textTransform: "none",
        }}
      >
        Zurück zur Startseite
      </Button>
    </Stack>
  );
}
