import type { Sort, SortKey } from "./sort-configuration";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import React from "react";

export function SortItem(props: {
  readonly onClick: () => void;
  readonly activeSort: SortKey | null;
  readonly sort: Sort;
}) {
  const theme = useTheme();
  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        props.onClick();
      }}
      sx={{
        backgroundColor:
          props.activeSort === props.sort.key
            ? theme.palette.grey[500_24]
            : "background",
        mt: 1,
        p: 1,
        mx: 1,
        borderRadius: 1,
        ":hover": {
          cursor: "pointer",
          backgroundColor: theme.palette.grey[500_12],
        },
      }}
    >
      <Typography fontSize="0.85rem">{props.sort.displayText}</Typography>
    </Box>
  );
}
