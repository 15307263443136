import type { ChangeEvent, KeyboardEvent } from "react";
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "../Iconify";
import MenuPopover from "../MenuPopover";
import { theme } from "../../theme/theme";
import { Board_Column_Type_Enum } from "../../generated/graphql";
import Toggle from "../Toggle";
import { LightTooltip } from "../LigthTooltip";
import type { ContactBoardColumnWithEntriesFragment } from "./ContactBoard";

const NORMAL_COLUMN_COLOR = theme.palette.primary.main;
//@ts-ignore the color 'cbRed' does exist
const CONTACT_BOARD_RED = theme.palette.cbRed.main;
const LINEBREAK_REGEX = /(\r\n|\n|\r)/gm;

export const COLUMN_NAME_PLACEHOLDER = "Name der Spalte";

export default function ContactBoardColumnToolBar(props: {
  readonly column: ContactBoardColumnWithEntriesFragment;
  readonly isDeclinedColumn: boolean;
  readonly onDelete: VoidFunction;
  readonly updateColumnName: (
    columnToBeUpdated: ContactBoardColumnWithEntriesFragment,
    updatedName: string,
  ) => void;
  readonly amountOfDisplayedApplicants: number;
}) {
  const {
    column,
    isDeclinedColumn,
    onDelete,
    updateColumnName,
    amountOfDisplayedApplicants,
  } = props;

  const anchorRef = useRef(null);
  const renameRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [lastSavedName, setLastSavedName] = useState(column.name);
  const [columnName, setColumnName] = useState(column.name);

  const isDeletableColumn =
    column.type !== Board_Column_Type_Enum.NewApplicant &&
    column.type !== Board_Column_Type_Enum.Declined;

  useEffect(
    function applyColumnNameChangesFromOtherUsers() {
      setColumnName(column.name);
    },
    [column.name],
  );

  useEffect(() => {
    if (open) {
      if (renameRef.current) {
        renameRef.current.focus();
        renameRef.current.setSelectionRange(
          columnName.length,
          columnName.length,
        );
      }
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickRename = () => {
    handleClose();
  };

  const handleChangeColumnName = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedColumnName = event.target.value;
    const nameWithoutLinebreaks = updatedColumnName.replace(
      LINEBREAK_REGEX,
      "",
    );
    setColumnName(nameWithoutLinebreaks);
  };

  const handleUpdateColumnNameByEnterKeystroke = (
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (!renameRef.current) {
      return;
    }

    if (event.key === "Escape") {
      renameRef.current?.blur();
      setColumnName(lastSavedName);
    }
    if (event.key === "Enter") {
      renameRef.current.blur();
      handleUpdateColumnName();
    }
  };

  const handleUpdateColumnName = () => {
    const nameWasUpdated = columnName !== column.name;
    if (nameWasUpdated) {
      updateColumnName(column, columnName);
      setLastSavedName(columnName);
    }
  };

  const handleDeleteColumn = () => {
    onDelete();
    handleClose();
  };

  const DeleteMenuItem = (
    <MenuItem
      disabled={!isDeletableColumn}
      onClick={handleDeleteColumn}
      sx={{
        py: 0.75,
        px: 1.5,
        borderRadius: 1,
        color: CONTACT_BOARD_RED,
      }}
    >
      <Iconify
        icon="eva:trash-2-outline"
        sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
      />
      <Typography variant="body2">Spalte löschen</Typography>
    </MenuItem>
  );

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <OutlinedInput
          endAdornment={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider
                orientation="vertical"
                sx={{
                  background: "white",
                  mr: 1,
                  height: "1.5rem",
                }}
              />
              {amountOfDisplayedApplicants}
            </Box>
          }
          inputProps={{
            sx: {
              "&::placeholder": {
                color: "#ffffff",
                opacity: 0.5,
              },
            },
          }}
          inputRef={renameRef}
          multiline
          onBlur={handleUpdateColumnName}
          onChange={handleChangeColumnName}
          onKeyUp={handleUpdateColumnNameByEnterKeystroke}
          placeholder={COLUMN_NAME_PLACEHOLDER}
          size="small"
          sx={{
            borderRadius: 2,
            typography: "subtitle1",
            backgroundColor: isDeclinedColumn
              ? CONTACT_BOARD_RED
              : NORMAL_COLUMN_COLOR,
            color: "#ffffff",
          }}
          value={columnName}
        />
        <IconButton
          color={open ? "inherit" : "default"}
          onClick={handleOpen}
          ref={anchorRef}
          size="small"
        >
          <Iconify height={25} icon="heroicons:ellipsis-vertical" width={25} />
        </IconButton>
      </Stack>

      <MenuPopover
        anchorEl={anchorRef.current}
        onClose={handleClose}
        open={open}
        sx={{ p: 1, width: "auto" }}
      >
        <MenuItem
          onClick={handleClickRename}
          sx={{ py: 0.75, px: 1.5, borderRadius: 1 }}
        >
          <Iconify
            icon="eva:edit-fill"
            sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
          />
          <Typography variant="body2">Spalte umbenennen</Typography>
        </MenuItem>
        <Toggle
          off={DeleteMenuItem}
          on={
            <LightTooltip
              title={getColumnTypeTooltip(column.type ?? undefined)}
            >
              <span>{DeleteMenuItem}</span>
            </LightTooltip>
          }
          showOn={!isDeletableColumn}
        />
      </MenuPopover>
    </>
  );
}

function getColumnTypeTooltip(columnType?: Board_Column_Type_Enum): string {
  switch (columnType) {
    case Board_Column_Type_Enum.NewApplicant:
      return "Diese Spalte kann nicht gelöscht werden, da hier neue Bewerber hinzugefügt werden.";
    case Board_Column_Type_Enum.Declined:
      return "Diese Spalte kann nicht gelöscht werden, da hier abgelehnte Bewerber hinzugefügt werden.";
    default:
      return "";
  }
}
