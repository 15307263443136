import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { BaseOptionChart } from "./chart";
import palette from "../theme/palette";

const CHART_SIZE = { width: 95, height: 95 };

export function cloneObject(objectToClone: object) {
  return JSON.parse(JSON.stringify(objectToClone));
}

export type PercentageDefinition = {
  good?: number;
  medium: number;
  bad?: number;
  highPercentageGood?: boolean;
};

const GOOD_COLOR = palette.light.success.light;
const MEDIUM_COLOR = palette.light.warning.light;
const BAD_COLOR = palette.light.error.light;

function getPercentageColor(
  currentPercentage: number,
  percentageDefinition: PercentageDefinition,
) {
  if (percentageDefinition.highPercentageGood) {
    return getColorHighPercentageGood(currentPercentage, percentageDefinition);
  }
  return getColorHighPercentageBad(currentPercentage, percentageDefinition);
}

function getColorHighPercentageBad(
  currentPercentage: number,
  percentageDefinition: PercentageDefinition,
) {
  if (
    percentageDefinition.bad &&
    currentPercentage >= percentageDefinition.bad
  ) {
    return BAD_COLOR;
  }
  if (currentPercentage >= percentageDefinition.medium) {
    return MEDIUM_COLOR;
  }
  return GOOD_COLOR;
}

function getColorHighPercentageGood(
  currentPercentage: number,
  percentageDefinition: PercentageDefinition,
) {
  if (
    percentageDefinition.good &&
    currentPercentage >= percentageDefinition.good
  ) {
    return GOOD_COLOR;
  }
  if (currentPercentage >= percentageDefinition.medium) {
    return MEDIUM_COLOR;
  }
  return BAD_COLOR;
}

export default function ProgressCircle(props: {
  progressPercentage: number;
  description: string;
  valueInCircle: string;
  percentageDefinition?: PercentageDefinition;
}) {
  const theme = useTheme();

  let chartOptionsDaysLeft = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize,
          },
          total: {
            formatter: () => props.valueInCircle,
          },
        },
      },
    },
  });

  if (props.percentageDefinition !== undefined) {
    chartOptionsDaysLeft = {
      ...chartOptionsDaysLeft,
      colors: [
        getPercentageColor(
          props.progressPercentage,
          props.percentageDefinition,
        ),
      ],
    };
  }

  let progressPercentage = props.progressPercentage;
  if (!isFinite(props.progressPercentage)) {
    progressPercentage = 100;
  }
  if (isNaN(progressPercentage)) {
    progressPercentage = 0;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ReactApexChart
        type="radialBar"
        series={[progressPercentage]}
        options={chartOptionsDaysLeft}
        {...CHART_SIZE}
      />
      <div>
        <Typography>{props.description}</Typography>
      </div>
    </Box>
  );
}
