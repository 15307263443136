import { CircularProgress, SxProps } from "@mui/material";
import { CB_GREEN, CB_ORANGE, CB_RED } from "../colors";

export function getContactScoreColor(status: number) {
  if (status > 0.75) {
    return CB_GREEN;
  }
  if (status > 0.5) {
    return CB_ORANGE;
  }
  return CB_RED;
}

export function ContactScoreCircle(props: { status: number; sx?: SxProps }) {
  const { status, sx } = props;
  const color = getContactScoreColor(status);
  return (
    <CircularProgress
      variant="determinate"
      value={status}
      sx={sx}
      style={{
        color: color,
        width: 20,
        height: 20,
      }}
    />
  );
}
