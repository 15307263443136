import {
  INSIGHTS_GREEN,
  INSIGHTS_GREEN_DARK,
  INSIGHTS_RED,
  INSIGHTS_YELLOW,
} from "components/campaign_insights/colors";
import type { CampaignFragment } from "generated/graphql";
import { Ad_Platform_Enum } from "generated/graphql";
import { theme } from "theme/theme";
import { fCurrency, fDaysDuration } from "utils/formatNumber";
import { calculateInternalCpl } from "utils/kpi_calculation/calcuateInternalCpl";
import { calculateBudgetLeftPercentage } from "utils/kpi_calculation/calculateBudgetLeftPercentage";
import { calculateContribution } from "utils/kpi_calculation/calculateContribution";
import type { PlatformKPIs } from "utils/kpi_calculation/calculateCreativesByPlatform";
import { calculateKpisForPlatform } from "utils/kpi_calculation/calculateCreativesByPlatform";
import { calculateExternalCpl } from "utils/kpi_calculation/calculateExternalCpl";
import {
  calculateMediaBudgetBonus,
  calculateRemainingMediaBudget,
} from "utils/kpi_calculation/calculateRemainingMediaBudget";
import {
  getCampaignBudget,
  getTotalBudget,
} from "utils/kpi_calculation/getCampaignBudget";
import { getMediaBudget } from "utils/kpi_calculation/getMediaBudget";
import { retrieveRemainingBudget } from "utils/kpi_calculation/retrieveRemainingBudget";
import getCampaignTimeMetrics from "../../utils/getCampaignTimeMetrics";

export type PLATFORM = Ad_Platform_Enum | "GESAMT" | "UNBEKANNT";

export interface UseCampaignKPIProps {
  campaign: CampaignFragment;
  includeInternalInsights: boolean;
  selectedPlatform: PLATFORM;
}

export const EURO_ICON = "material-symbols:euro-symbol-rounded";

export type KPI_Progress = {
  value: number;
  color: string;
  backgroundColor?: string;
  icon?: string;
};

export interface KPI {
  value?: number;
  displayValue: string;
  displayValueColor?: string;
  name: string;
  showDash?: boolean;
  progress?: KPI_Progress;
  caption?: KpiCaption;
}

export interface KpiCaption {
  displayValue: string;
  displayValueColor?: string;
}

export type DisplayedKPI = KPI & {
  displayIf: boolean;
};

const getCampaignKPIs = ({
  campaign,
  includeInternalInsights,
  selectedPlatform,
}: UseCampaignKPIProps): {
  kpis: DisplayedKPI[];
  platformKPIs: KPI[];
  daysElapsedPercentage: number;
  qualifiedLeads: number;
  totalLeadsMap: Map<PLATFORM, number>;
  qualifiedLeadsMap: Map<PLATFORM, number>;
  campaignHasDefinedEnd: boolean;
  internalKpis: KPI[];
  externalKpis: KPI[];
  nonZeroKPIPlatforms: (keyof typeof Ad_Platform_Enum | "GESAMT")[];
} => {
  const isMultiLocationHUKCampaign =
    campaign.organization_id === "huk" &&
    campaign.routing_info?.location_id == null;

  const totalLeads =
    (isMultiLocationHUKCampaign
      ? campaign.total_origin_lead_count?.aggregate?.count
      : campaign.total_lead_count?.aggregate?.count) ?? 0;
  const qualifiedLeads =
    (isMultiLocationHUKCampaign
      ? campaign.qualified_origin_lead_count?.aggregate?.count
      : campaign.qualified_lead_count.aggregate?.count) ?? 0;

  const totalLeadsMap: Map<PLATFORM, number> = new Map();
  const qualifiedLeadsMap: Map<PLATFORM, number> = new Map();
  totalLeadsMap.set("GESAMT", totalLeads);
  qualifiedLeadsMap.set("GESAMT", qualifiedLeads);

  campaign.total_leads?.forEach((lead) => {
    totalLeadsMap.set(
      lead.utm_source!,
      (totalLeadsMap.get(lead.utm_source!) ?? 0) + 1,
    );
    qualifiedLeadsMap.set(lead.utm_source!, 0);
  });
  campaign.qualified_leads?.forEach((lead) => {
    qualifiedLeadsMap.set(
      lead.utm_source!,
      (qualifiedLeadsMap.get(lead.utm_source!) ?? 0) + 1,
    );
  });
  totalLeadsMap.set(
    "UNBEKANNT",
    totalLeads - campaign.total_leads?.length ?? 0,
  );
  qualifiedLeadsMap.set(
    "UNBEKANNT",
    qualifiedLeads - campaign.qualified_leads?.length ?? 0,
  );

  const budgetLeft = retrieveRemainingBudget(campaign);
  const campaignBudget = getCampaignBudget(campaign) ?? 0;
  const totalBudget = getTotalBudget(campaign);
  const {
    daysElapsedPercentage,
    campaignHasNotStarted,
    isCampaignOver,
    campaignHasDefinedEnd,
    duration,
  } = getCampaignTimeMetrics(campaign);
  const contribution = calculateContribution(campaign);
  const cplInternal = calculateInternalCpl(campaign, qualifiedLeads);
  const cplExternal = calculateExternalCpl(campaign, qualifiedLeads);

  const ctr: number | undefined = campaign.insights?.click_through_rate;

  const kpisByPlatform: {
    [key in PLATFORM]?: PlatformKPIs;
  } = {};

  kpisByPlatform["GESAMT"] = calculateKpisForPlatform(campaign, null);
  Object.values(Ad_Platform_Enum).forEach((platform) => {
    kpisByPlatform[platform] = calculateKpisForPlatform(campaign, platform);
  });

  const nonZeroKPIPlatforms: (keyof typeof Ad_Platform_Enum | "GESAMT")[] = [];
  Object.entries(kpisByPlatform).forEach(([platform, kpis]) => {
    if (!kpis) return;
    const { impressions, reach, clicks, spent } = kpis;
    if (
      impressions !== 0 ||
      reach !== 0 ||
      clicks !== 0 ||
      spent !== 0 ||
      platform === "GESAMT"
    ) {
      nonZeroKPIPlatforms.push(
        platform as keyof typeof Ad_Platform_Enum | "GESAMT",
      );
    }
  });

  const hasFunnel = campaign.perspective_campaign != null;
  const totalLeadsKpi: DisplayedKPI = {
    name: "Gesamtbewerber",
    value: totalLeads,
    displayValue: totalLeads.toLocaleString("de"),
    displayIf: !includeInternalInsights,
  };
  const displayDauer =
    campaignHasNotStarted || isCampaignOver || !campaignHasDefinedEnd;
  const durationKpi: DisplayedKPI = {
    name: displayDauer ? "Dauer" : "Restdauer",
    value: duration,
    displayValue: fDaysDuration(duration),
    showDash: !Number.isFinite(duration),
    displayIf: includeInternalInsights || !campaign.is_test,
    progress: !displayDauer
      ? {
          value: daysElapsedPercentage / 100,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
          icon: "mdi:clock-time-three",
        }
      : undefined,
  };
  const budgetLeftPercentage = calculateBudgetLeftPercentage(
    totalBudget,
    budgetLeft,
  );
  const budgetLeftKpi: DisplayedKPI = {
    name: "Restbudget",
    value: budgetLeft,
    displayValue: fCurrency(budgetLeft),
    displayIf: includeInternalInsights,
    showDash: !Number.isFinite(budgetLeft),
    progress:
      budgetLeftPercentage !== undefined
        ? {
            value: budgetLeftPercentage,
            color: getPercentageColor(budgetLeftPercentage),
            icon: EURO_ICON,
          }
        : undefined,
  };
  const cplInternalKpi = {
    name: "CPL",
    value: cplInternal,
    displayValue: fCurrency(cplInternal),
    showDash: !Number.isFinite(cplInternal),
    displayIf: includeInternalInsights || !campaign.is_test,
  };

  const impressions = kpisByPlatform[selectedPlatform]?.impressions;
  const impressionsKpi = {
    name: "Impressionen",
    value: kpisByPlatform[selectedPlatform]?.impressions,
    displayValue:
      impressions?.toLocaleString("de", {
        maximumFractionDigits: 0,
        useGrouping: true,
      }) ?? "",
    showDash: !(impressions !== undefined && Number.isFinite(impressions)),
  };

  const reach = kpisByPlatform[selectedPlatform]?.reach;
  const reachKpi = {
    name: "Reichweite",
    showDash: !(reach !== undefined && Number.isFinite(reach)),
    value: reach,
    displayValue:
      reach?.toLocaleString("de", {
        maximumFractionDigits: 0,
        useGrouping: true,
      }) ?? "",
  };

  const ctrKpi = {
    name: "CTR",
    showDash: ctr == null || qualifiedLeads === 0,
    value: ctr,
    displayValue:
      ctr?.toLocaleString("de", { style: "unit", unit: "percent" }) ?? "",
  };

  const clicks = kpisByPlatform["GESAMT"]?.clicks ?? 1;
  const spent = kpisByPlatform["GESAMT"]?.spent ?? 0;
  const internalCPC = spent / clicks;

  const internalCPCKpi = {
    name: "CPC",
    value: internalCPC,
    displayValue: fCurrency(internalCPC),
    showDash: !Number.isFinite(internalCPC),
  };

  const clicksByPlatform = kpisByPlatform[selectedPlatform]?.clicks ?? 1;
  const externalCPC = budgetLeft
    ? (totalBudget - budgetLeft) / clicksByPlatform
    : 0;

  const externalCPCKpi = {
    name: "CPC",
    value: externalCPC,
    displayValue: fCurrency(externalCPC),
    showDash: !Number.isFinite(externalCPC),
  };

  const platformKPIs = [
    impressionsKpi,
    reachKpi,
    hasFunnel
      ? ctrKpi
      : includeInternalInsights
        ? internalCPCKpi
        : externalCPCKpi,
  ];
  const budgetBonus = campaign.customer_budget_bonus ?? 0;
  const additionalBudget =
    campaign.customer_budget_total - campaignBudget - budgetBonus;
  const displayedAdditionalBudget = budgetBonus + additionalBudget;

  const totalBudgetKpi: KPI = {
    name: "Kampagnenbudget",
    value: campaignBudget,
    displayValue: fCurrency(campaignBudget),
    caption:
      displayedAdditionalBudget > 0
        ? {
            displayValue: `+${fCurrency(displayedAdditionalBudget)}`,
            displayValueColor: INSIGHTS_GREEN_DARK,
          }
        : undefined,
    showDash: !Number.isFinite(campaignBudget),
  };
  const mediaBudget = getMediaBudget(campaign) ?? 0;

  const leftMediaBudget = calculateRemainingMediaBudget(campaign);
  const mediaBudgetPercentage = leftMediaBudget
    ? leftMediaBudget / mediaBudget
    : undefined;

  const mediaBudgetLeftKpi: DisplayedKPI = {
    name: "Rest Media Budget",
    value: leftMediaBudget,
    displayValue: fCurrency(leftMediaBudget),
    showDash: !Number.isFinite(leftMediaBudget),
    progress: mediaBudgetPercentage
      ? {
          value: mediaBudgetPercentage,
          color: getPercentageColor(mediaBudgetPercentage),
          icon: EURO_ICON,
        }
      : undefined,
    displayIf: includeInternalInsights,
  };

  const additionalMediaBudget = calculateMediaBudgetBonus(campaign);
  const mediaBudgetKpi: KPI = {
    name: "Media Budget",
    value: mediaBudget,
    displayValue: fCurrency(mediaBudget - additionalMediaBudget),
    caption:
      additionalMediaBudget > 0
        ? {
            displayValue: `+${fCurrency(additionalMediaBudget)}`,
            displayValueColor: INSIGHTS_GREEN_DARK,
          }
        : undefined,
    showDash: !Number.isFinite(mediaBudget),
  };
  const contributionMarginKpi: KPI = {
    name: "Deckungsbeitrag",
    value: contribution,
    displayValue: fCurrency(contribution),
    displayValueColor:
      contribution && contribution < 0 ? INSIGHTS_RED : INSIGHTS_GREEN_DARK,
    showDash: !Number.isFinite(contribution),
  };
  const cplExternalKpi: DisplayedKPI = {
    name: "CPL",
    value: cplExternal,
    displayValue: fCurrency(cplExternal),
    showDash: !Number.isFinite(cplExternal),
    displayIf: !campaign.is_test,
  };

  const kpis: DisplayedKPI[] = [
    totalLeadsKpi,
    durationKpi,
    mediaBudgetLeftKpi,
    includeInternalInsights ? cplInternalKpi : cplExternalKpi,
  ];
  const internalKPIs: KPI[] = [
    totalBudgetKpi,
    mediaBudgetKpi,
    mediaBudgetLeftKpi,
    cplInternalKpi,
    contributionMarginKpi,
    internalCPCKpi,
  ];

  const externalKPIs: KPI[] = [
    totalBudgetKpi,
    budgetLeftKpi,
    durationKpi,
    impressionsKpi,
    reachKpi,
    cplExternalKpi,
    externalCPCKpi,
  ];

  return {
    kpis,
    platformKPIs,
    daysElapsedPercentage,
    campaignHasDefinedEnd,
    qualifiedLeads,
    totalLeadsMap,
    qualifiedLeadsMap,
    internalKpis: internalKPIs,
    externalKpis: externalKPIs,
    nonZeroKPIPlatforms,
  };
};

function getPercentageColor(percentage: number) {
  if (percentage > 0.66) {
    return INSIGHTS_GREEN;
  }
  if (percentage > 0.33) {
    return INSIGHTS_YELLOW;
  }
  return INSIGHTS_RED;
}

export default getCampaignKPIs;
