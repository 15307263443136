import { Button, Typography } from "@mui/material";
import Iconify from "../../../../Iconify";
import { useFilter } from "../../FilterContext";

export function FilterDrawerFooter() {
  const { reset } = useFilter();
  return (
    <Button
      onClick={() => {
        reset();
      }}
      startIcon={<Iconify icon="heroicons:trash" />}
      sx={{ mx: 2 }}
      variant="outlined"
    >
      <Typography>Zurücksetzen</Typography>
    </Button>
  );
}
