import type { CampaignFilterKey } from "./components/filter/filter-configuration";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "hooks/useUser";

export interface FilterContextType<Key extends string> {
  activeFilters: Set<Key>;
  toggleFilter: (filter: Key) => void;
  setAllFilters: (filters: Set<Key>) => void;
  setCustomFilterValues: (filter: Key, values: any) => void;
  customFilterValues: Map<Key, any[]>;
  reset: () => void;
}

const FilterContext = createContext<FilterContextType<any>>({
  activeFilters: new Set(),
  toggleFilter: () => {},
  setAllFilters: () => {},
  setCustomFilterValues: () => {},
  customFilterValues: new Map(),
  reset: () => {},
});

export function FilterContextProvider<Key extends string>({
  children,
  defaultFilters,
}: PropsWithChildren<{
  readonly defaultFilters: Key[];
}>) {
  const [activeFilters, setActiveFilters] = useState<Set<Key>>(
    new Set(defaultFilters),
  );
  const { permissions } = useUser();
  const toggleFilter = (filter: Key) => {
    if (activeFilters.has(filter)) {
      activeFilters.delete(filter);
    } else {
      activeFilters.add(filter);
    }
    setActiveFilters(new Set(activeFilters));
  };

  const [customFilterValues, setCustomFilterValues] = useState<
    Map<CampaignFilterKey, any[]>
  >(new Map());

  function reset() {
    setActiveFilters(new Set(defaultFilters));
    setCustomFilterValues(new Map());
  }

  useEffect(() => {
    reset();
  }, [permissions]);

  return (
    <FilterContext.Provider
      value={{
        activeFilters,
        toggleFilter,
        setAllFilters: (filters) => {
          setActiveFilters(filters);
        },
        setCustomFilterValues: (filter, values) => {
          customFilterValues.set(filter, values);
          setCustomFilterValues(new Map(customFilterValues));
        },
        customFilterValues,
        reset,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useFilter<Key extends string>() {
  return useContext<FilterContextType<Key>>(FilterContext);
}
