import { CampaignFragment } from "../../generated/graphql";
import { DeepPartial } from "../../types";
import {
  calculateCampaignTimeMetrics,
  CampaignTimeMetrics,
} from "../getCampaignTimeMetrics";
import { getCampaignBudget } from "./getCampaignBudget";

export function retrieveRemainingBudget(
  campaign: DeepPartial<CampaignFragment>,
): number | undefined {
  if (campaign.remaining_customer_budget != null) {
    const wouldDisplayNegativeRemainingBudgetToCustomer =
      campaign.remaining_customer_budget < 0;
    if (wouldDisplayNegativeRemainingBudgetToCustomer) {
      return 0;
    }
    return campaign.remaining_customer_budget;
  }

  const budget = getCampaignBudget(campaign);
  const campaignTimeMetrics: CampaignTimeMetrics = calculateCampaignTimeMetrics(
    campaign.start,
    campaign.end,
  );
  if (budget != undefined && campaignTimeMetrics.campaignHasNotStarted) {
    return budget;
  }

  return undefined;
}
