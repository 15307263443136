import { useTheme } from "@mui/material";
import { merge } from "lodash";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { BaseOptionChart } from "../../analytics/chart";

interface ContactsPerDayChartProps {
  readonly chartData: any[];
  readonly animate?: boolean;
}

function ContactsPerDayChart({
  chartData,
  animate = true,
}: ContactsPerDayChartProps) {
  const theme = useTheme();

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.primary.main],
    chart: { animations: { enabled: animate }, sparkline: { enabled: true } },
    stroke: { width: 2 },
    xaxis: { type: "datetime" },
    tooltip: {
      x: {
        show: true,
        format: "dd.MM.yyyy",
      },
      y: {
        formatter: (value: string) => value,
        title: {
          formatter: () => "Neue Bewerber",
        },
      },
      marker: { show: false },
    },
  });

  return (
    <ReactApexChart
      height={60}
      options={chartOptions}
      series={[{ data: chartData }]}
      type="area"
      width="100%"
    />
  );
}

export default ContactsPerDayChart;
