import { LocationDropdown } from "components/LocationDropdown";
import type { LocationFragment } from "generated/graphql";
import { useGetLocationsQuery } from "generated/graphql";

function findLocationsFromNames(
  names: string[],
  locations: LocationFragment[],
): LocationFragment[] {
  return locations.filter((location) => names.includes(location.name));
}

export function LocationFilterDropdown({
  setValues,
  values,
}: {
  readonly setValues: (values: string[]) => void;
  readonly values: string[];
}) {
  const { data } = useGetLocationsQuery();

  if (
    data?.organization_location == null ||
    data?.organization_location.length === 0
  )
    return <div />;
  const locations = findLocationsFromNames(values, data.organization_location);
  return (
    <LocationDropdown
      label="Standorte auswählen"
      multiple
      onChange={(value) => {
        if (Array.isArray(value)) {
          setValues(value.map((v) => v.name));
          return;
        }
        setValues(value?.name == null ? [] : [value.name]);
      }}
      options={data.organization_location}
      value={locations == null ? null : locations}
    />
  );
}
