import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useFeatureFlag, { FeatureFlag } from "../hooks/useFeatureFlag";
import Toggle from "./Toggle";
import NotFound from "../pages/NotFound";
import { Button, Stack, Typography } from "@mui/material";

export function FeatureFlagGuard({
  flag,
  children,
}: {
  flag: FeatureFlag;
  children?: React.ReactNode;
}) {
  const { enabled } = useFeatureFlag(flag);
  const navigate = useNavigate();
  return (
    <Toggle
      showOn={enabled}
      on={<>{children ?? <Outlet />}</>}
      off={
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          gap={1}
        >
          <Typography variant={"h4"}>
            Es tut uns leid, aber dieses Feature ist nicht verfügbar.
          </Typography>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => navigate("/")}
            sx={{
              textTransform: "none",
            }}
          >
            Zurück zur Startseite
          </Button>
        </Stack>
      }
    />
  );
}
