import { alpha } from "@mui/material/styles";

export const CB_BLUE = "#3B6BAA";

export const CB_BLUE_10 = alpha(CB_BLUE, 0.1);
export const CB_BLUE_20 = alpha(CB_BLUE, 0.2);

export const CB_ORANGE = "#FCBF54";

export const CB_YELLOW = "#F9D040";
export const CB_GREEN = "#78C29A";
export const CB_RED = "#F1416C";
export const CB_DARK_GREEN = "#00AB55";
export const CB_DARK_GREEN_40 = alpha(CB_DARK_GREEN, 0.4);
export const CB_PURPLE = alpha("#533DA6", 0.6);
export const CB_LIGHT_PURPLE = alpha("#86348C", 0.6);
export const TURQUOISE = "#8BD2D1";
export const CB_TURQUOISE = alpha(TURQUOISE, 0.6);

export const CB_LIGHT_BLUE = alpha("#48A6CB", 0.6);

export const CB_GRAY = "#F4F6F8";

export const CB_ICONS_GREY = "#637381";
