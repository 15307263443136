import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import type { Editor } from "@tiptap/react";
import Iconify from "components/Iconify";
import type { EmailTemplate } from "components/mails/EmailTemplatesEditor";
import { useState } from "react";

interface EditorActionProps {
  readonly editor: Editor;
  readonly placeholders?: EmailTemplate["placeholders"];
  readonly enabled: boolean;
}

export function EditorActions({
  editor,
  placeholders = {},
  enabled,
}: EditorActionProps) {
  const [isLinkDialogOpen, setLinkDialogOpen] = useState(false);

  const handleLinkAdding = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editor) return;

    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const url = formJson.url;
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();

    setLinkDialogOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "grey.300",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Dialog
        PaperProps={{
          component: "form",
          onSubmit: handleLinkAdding,
        }}
        onClose={() => setLinkDialogOpen(false)}
        open={isLinkDialogOpen}
      >
        <DialogTitle>Verlinkung hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>Link einfügen</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            label="url"
            name="url"
            required
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setLinkDialogOpen(false)}>
            Abbrechen
          </Button>
          <Button type="submit">Speichern</Button>
        </DialogActions>
      </Dialog>

      <Box>
        <IconButton
          color={
            editor.isActive("heading", { level: 1 }) ? "primary" : "default"
          }
          disabled={!enabled}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          <Iconify icon="material-symbols:format-h1-rounded" />
        </IconButton>
        <IconButton
          color={
            editor.isActive("heading", { level: 2 }) ? "primary" : "default"
          }
          disabled={!enabled}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          <Iconify icon="material-symbols:format-h2-rounded" />
        </IconButton>
        <IconButton
          color={
            editor.isActive("heading", { level: 3 }) ? "primary" : "default"
          }
          disabled={!enabled}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          <Iconify icon="material-symbols:format-h3-rounded" />
        </IconButton>
        <IconButton
          color={editor.isActive("bold") ? "primary" : "default"}
          disabled={!enabled}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <Iconify icon="material-symbols:format-bold-rounded" />
        </IconButton>
        <IconButton
          color={editor.isActive("italic") ? "primary" : "default"}
          disabled={!enabled}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <Iconify icon="material-symbols:format-italic-rounded" />
        </IconButton>
        <IconButton
          disabled={!enabled}
          onClick={() => {
            editor.isActive("link")
              ? editor.chain().focus().unsetLink().run()
              : setLinkDialogOpen(true);
          }}
        >
          {editor.isActive("link") ? (
            <Iconify icon="material-symbols:link-off-rounded" />
          ) : (
            <Iconify icon="material-symbols:add-link-rounded" />
          )}
        </IconButton>
        <IconButton
          disabled={!enabled}
          onClick={() => {
            editor.chain().insertContentAt(0, "<hr/>").run();
          }}
        >
          <Iconify icon="material-symbols:horizontal-rule" />
        </IconButton>
      </Box>
      {Object.entries(placeholders)?.length ? (
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography variant="body2">Platzhalter:</Typography>
          {Object.entries(placeholders).map(([placeholder, displayName]) => (
            <Button
              disabled={!enabled}
              key={`insert-${placeholder}`}
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .insertContent(`{{ ${placeholder} }}`)
                  .run()
              }
            >
              {displayName}
            </Button>
          ))}
        </Stack>
      ) : null}
    </Box>
  );
}
