import { NoMessages } from "./NoMessages";
import { Stack } from "@mui/material";
import type { ChatMessage } from "./ChatMessageTile";
import { ChatMessageTile } from "./ChatMessageTile";
import useListScroll from "../../../../../../hooks/useListScroll";

export function ChatTabContent({
  messages,
}: {
  readonly messages?: ChatMessage[];
}) {
  if (messages === undefined || messages.length == 0) {
    return <NoMessages />;
  }
  const listEndRef = useListScroll({ items: messages });

  return (
    <Stack height="100%" overflow="auto" spacing={2}>
      {messages?.map((message, index) => {
        return (
          <ChatMessageTile
            key={message.id}
            message={message}
            previousMessage={messages?.at(index - 1)}
          />
        );
      })}
      <div ref={listEndRef} />
    </Stack>
  );
}
