import { LightTooltip } from "../../LigthTooltip";
import { Box } from "@mui/material";
import ExternalToolLink from "../campaign_card/ExternalToolLink";
import MeistertaskLogo from "assets/logos/meistertask.png";
import type { CampaignFragment } from "generated/graphql";
import { RequiresPermission } from "../../RequiresPermission";

export function ToApplicantBoardAction({
  campaign,
}: {
  readonly campaign: CampaignFragment;
}) {
  if (campaign.meistertask_url == null) {
    return <Box />;
  }

  const adminView = (
    <ExternalToolLink url={campaign.meistertask_url}>
      <img alt="Zu Meistertask" className="h-full" src={MeistertaskLogo} />
    </ExternalToolLink>
  );

  return (
    <RequiresPermission permission="edit:campaign">
      <LightTooltip title="Zum Bewerber-Board">{adminView}</LightTooltip>
    </RequiresPermission>
  );
}
