import type { SxProps } from "@mui/material";
import { Box, Stack, Typography } from "@mui/material";
import Label from "../../Label";
import Iconify from "../../Iconify";
import type { CampaignInfo } from "../../../utils/CampaignDetailsUtil";
import { merge } from "lodash";
import { ellipsis } from "../../../utils/ellipsis";
import { LightTooltip } from "../../LigthTooltip";

export function CampaignInfoLabels({
  campaignInfo,
  sx,
  truncateLabel,
}: {
  readonly campaignInfo: CampaignInfo[];
  readonly sx?: SxProps;
  readonly truncateLabel?: boolean;
}) {
  const sxProps = merge(
    {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sx,
  );

  const labelContent = (item: CampaignInfo, index: number) => (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Iconify icon={item.icon} sx={{ width: "16px", height: "16px" }} />
      <Typography noWrap variant="caption">
        {truncateLabel ? ellipsis(item.value, 45) : item.value}
      </Typography>
    </Stack>
  );

  return (
    <Box sx={sxProps}>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={1}
        justifyContent="flex-start"
        sx={{
          color: "text.disabled",
          width: "100%",
        }}
      >
        {campaignInfo.map((item, index) => (
          <Label color={item.color} key={index} size="medium" variant="ghost">
            {item.tooltip ? (
              <LightTooltip title={item.tooltip}>
                {labelContent(item, index)}
              </LightTooltip>
            ) : (
              labelContent(item, index)
            )}
          </Label>
        ))}
      </Stack>
    </Box>
  );
}
