import {
  Box,
  Card,
  CardContent,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {
  getGsCampaignCardChartData,
  hasChartData,
} from "components/campaign_insights/answer_insights/applicationInsightHelpers";
import ContactsPerDayChart from "components/campaigns/campaign_card/ContactsPerDayChart";
import { KPIDisplay } from "components/campaigns/campaign_card/KPIDisplay";
import type { HukGsFragment } from "generated/graphql";
import { getHukGsKPIs } from "hooks/campaign/getHukGsKPIs";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import type { KPI } from "../../hooks/campaign/getCampaignKPIs";
import { LightTooltip } from "../LigthTooltip";

const ChartContainer = styled(Box)(`
  svg {
    overflow: visible;
  }
`);

export function HukGsCard({ gs }: { readonly gs: HukGsFragment }) {
  const { kpis, qualifiedLeadCount } = getHukGsKPIs(gs, "GESAMT");

  const chartData = useMemo(
    () =>
      getGsCampaignCardChartData(
        gs.gs_campaigns
          .flatMap((c) => c.campaign?.contacts_per_day ?? [])
          .concat(gs.contacts_per_day ?? []),
      ),
    [gs],
  );

  const showChart = hasChartData(chartData);

  const activeCampaigns = gs.gs_campaigns.filter(
    (c) => c.campaign?.status === "ACTIVE",
  ).length;
  const endedCampaigns = gs.gs_campaigns.filter(
    (c) => c.campaign?.status === "FINISHED",
  ).length;
  const campaignStats: KPI[] = [
    {
      name: "Kampagnen",
      value: gs.gs_campaigns.length,
      displayValue: gs.gs_campaigns.length.toFixed(0),
    },
    {
      name: "Aktiv",
      value: activeCampaigns,
      displayValue: activeCampaigns.toFixed(0),
    },
    {
      name: "Beendet",
      value: endedCampaigns,
      displayValue: endedCampaigns.toFixed(0),
    },
  ];

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Stack>
          <LightTooltip placement="bottom-start" title="Zu den Insights">
            <Box>
              <Link
                color="text.primary"
                component={RouterLink}
                to={`/huk/gs/${gs?.id}?tab=insights`}
                variant="h6"
              >
                {gs?.name}
              </Link>
            </Box>
          </LightTooltip>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          mb={3}
          mt={3}
          spacing={3}
        >
          <Stack alignItems="center" sx={{ flexGrow: 1, mb: 4 }}>
            <Typography variant={showChart ? "h4" : "h3"}>
              {qualifiedLeadCount}
            </Typography>
            <Typography color="text.disabled" variant="subtitle2">
              Qualifiziert
            </Typography>
          </Stack>
          {showChart ? (
            <ChartContainer sx={{ py: 2, overflow: "visible", flexGrow: 1 }}>
              <ContactsPerDayChart animate={false} chartData={chartData} />
            </ChartContainer>
          ) : null}
        </Stack>
        <KPIDisplay kpis={[kpis[0], kpis[1], kpis[2]]} />
        <KPIDisplay kpis={[kpis[3], kpis[4], kpis[5]]} />
        <KPIDisplay kpis={campaignStats} />
      </CardContent>
    </Card>
  );
}
